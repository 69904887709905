// Layout
export const HEADER_HEIGHT = 32
export const HEADER_MARGIN_BOTTOM = 6
export const DISPLAY_OPTIONS_HEIGHT = 74
export const BLOCK_FONT_SIZE = 11
export const COLUMN_WIDTH = 100

export const DEFAULT_BLOCK_FONT_SIZE_SET = 11
export const DEFAULT_BLOCK_FONT_SIZE_NO_SET = 12
export const DEFAULT_COLUMN_WIDTH_SET_MOBILE = 96
export const DEFAULT_COLUMN_WIDTH_SET = 160
export const DEFAULT_COLUMN_WIDTH_NO_SET = 80
export const DEFAULT_NUM_BLOCKS_MOBILE = 4

// Timeline details
export const START_HOUR_1 = 17
export const START_HOUR_2 = 19
export const END_HOUR = 5

// Stage info
export const DEFAULT_ORDER = [
    "Kinetic Field", 
    "Cosmic Meadow", 
    "Circuit Grounds", 
    "Neon Garden", 
    "Basspod", 
    "Wasteland", 
    "Quantum Valley", 
    "Stereo Bloom", 
    "Bionic Jungle", 
    "Rynobus", 
    "House of Dunkin'", 
    "Beatbox Boombox Art Car", 
    "Meta Phoenix", 
    "YeeDC!", 
    "Blacklight Bar"
]
export const MAIN_STAGES = [
    "Kinetic Field", 
    "Cosmic Meadow", 
    "Circuit Grounds", 
    "Neon Garden", 
    "Basspod", 
    "Wasteland", 
    "Quantum Valley", 
    "Stereo Bloom", 
    "Bionic Jungle"
];
export const STAGE_COLORS = [
    "#83FF80",
    "#5EEAFD",
    "#DC8BFF",
    "#FF70F1",
    "#FF6B6B",
    "#FFB62C",
    "#FFEB35",
    "#FF9783",
    "#83FF80",
    "#5EEAFD",
    "#DC8BFF", 
    "#FF70F1", 
    "#FF6B6B", 
    "#FFB62C", 
    "#FFEB35", 
]
export const ELEMENTS24_STAGE_COLORS = [
    "#FFEB35",
    "#69FF58",
    "#FF6B6B",
    "#5EEAFD",
]