import { auth, database, storage } from './'
import { onValue, ref, off, get, set, push, update, increment, remove } from 'firebase/database'
import { ref as storageRef, uploadString, getDownloadURL, deleteObject } from "firebase/storage"
import { resizeImage } from '../../utils/helpers'

// ------------------------ General requests ------------------------
// Type: GET
// Details: Get festival IDs
// Return: Array of festival IDs
export const fetchAllFestivalIds = async () => {
    const festivalsRef = ref(database, 'festivals')
    try {
        const snapshot = await get(festivalsRef)
        if (snapshot.exists()) {
            return Object.keys(snapshot.val())
        } else {
            return []
        }
    } catch (error) {
        console.error('Failed to fetch festival IDs:', error)
        throw error
    }
}

// Type: GET
// Details: Get festival data excluding schedules[] and stages[]
// Return: festivalData {}
export const fetchAllFestivalsGeneralData = async () => {
    const festivalsRef = ref(database, 'festivals');
    try {
        const snapshot = await get(festivalsRef);
        if (snapshot.exists()) {
            const festivals = snapshot.val();
            let festivalData = {};

            Object.keys(festivals).forEach(festivalId => {
                const { name, location, start, end, daysAndTimes, genres, hasLineup, hasDay, hasSetTimes, imageUrls, numTiers, passed, hidden } = festivals[festivalId];
                festivalData[festivalId] = {
                    id: festivalId,
                    name,
                    location,
                    start,
                    end,
                    daysAndTimes,
                    genres,
                    hasLineup,
                    hasDay,
                    hasSetTimes,
                    imageUrls,
                    numTiers,
                    passed,
                    hidden
                };
            });

            return festivalData;
        } else {
            console.log('No festivals found.');
            return {};
        }
    } catch (error) {
        console.error('Failed to fetch festivals:', error);
        throw error;
    }
};

// Type: GET / POST
// Details: Get the user's scheduleID for a festival if it exists, otherwise create one.
// Return: scheduleID ""
export const getOrCreateScheduleForFestival = async (userId, festivalId) => {
    if (!userId || !festivalId) throw new Error("Missing user or festival ID")

    const userSchedulesRef = ref(database, `users/${userId}/schedules`)
    try {
        const snapshot = await get(userSchedulesRef)
        if (snapshot.exists()) {
            const schedules = snapshot.val()
            if (schedules && schedules[festivalId]) {
                return schedules[festivalId] // Return existing schedule ID
            }
        }
        return await createNewSchedule(userId, festivalId)
    } catch (error) {
        console.error('Error fetching or creating schedule:', error)
        throw error
    }
}

// Type: POST
export const createNewSchedule = async (userId, festivalId) => {
    const newScheduleRef = push(ref(database, 'schedules'))
    const newSchedule = {
        creator: userId,
        createdAt: Date.now(),
        festival: festivalId,
        selections: {}
    }

    await set(newScheduleRef, newSchedule)

    const newScheduleId = newScheduleRef.key

    // Link the new schedule to the user under their schedules
    const userSchedulesUpdateRef = ref(database, `users/${userId}/schedules/${festivalId}`)
    await set(userSchedulesUpdateRef, newScheduleId)

    // Add the new schedule ID under the corresponding festival
    const festivalSchedulesRef = ref(database, `festivals/${festivalId}/schedules/${newScheduleId}`)
    await set(festivalSchedulesRef, true)

    return newScheduleId
}

// Type: POST / DELETE
// Details: Add or remove the festival from the user's favorites
// Return: void
export const setFavoriteFestival = async (userId, festivalId, isFavorite) => {
    if (!userId || !festivalId) throw new Error("Missing user or festival ID")

    const favoritesRef = ref(database, `users/${userId}/favorites/${festivalId}`)
    try {
        if (isFavorite) {
            await set(favoritesRef, true)
            await getOrCreateScheduleForFestival(userId, festivalId) // Create a schedule if favorited
        } else {
            await set(favoritesRef, null)
        }
    } catch (error) {
        console.error('Error setting favorite festival:', error)
        throw error
    }
}

// Type: GET
// Details: Fetch the user's favorited festivals
// Return: Object of favorited festivals
export const fetchUserFavoritedFestivals = async (userId) => {
    const favoritesRef = ref(database, `users/${userId}/favorites`)
    try {
        const snapshot = await get(favoritesRef)
        return snapshot.exists() ? snapshot.val() : {}
    } catch (error) {
        console.error('Error fetching user favorited festivals:', error)
        throw error
    }
}

export const fetchUserDataByDisplayName = async (displayName) => {
    const usersRef = ref(database, 'users')
    const snapshot = await get(usersRef)
    const users = snapshot.val()

    for (const userId in users) {
        if (users[userId].displayName === displayName) {
            return { ...users[userId], uid: userId }
        }
    }
    return null
}

export const fetchUserDataById = async (userId) => {
    const userRef = ref(database, `users/${userId}`)
    const snapshot = await get(userRef)
    return snapshot.exists() ? { ...snapshot.val(), uid: userId } : null
}

export const fetchUserDisplayDataById = async (userId) => {
    const userRef = ref(database, `users/${userId}`)
    const snapshot = await get(userRef)

    if (snapshot.exists()) {
        const data = snapshot.val()
        return {
            displayName: data.displayName,
            picture: data.picture?.lowRes || '',
            uid: userId,
        }
    } else {
        return null
    }
}

// Type: SET
// Details: Update a user profile
// Return: none
export const updateUserProfile = async (userId, updates) => {
    if (!userId || !updates) throw new Error("Missing user ID or updates")

    const userRef = ref(database, `users/${userId}`)
    try {
        await update(userRef, updates)
        console.log('User profile updated successfully')
    } catch (error) {
        console.error('Failed to update user profile:', error)
        throw error
    }
}

// Type: GET
// Details: Fetch the badge order from the database
// Return: Array of badge names in order
export const fetchBadgeOrder = async () => {
    const badgeOrderRef = ref(database, 'config/badgeOrder')
    try {
        const snapshot = await get(badgeOrderRef)
        if (snapshot.exists()) {
            const orderObject = snapshot.val()
            return Object.keys(orderObject).map(key => orderObject[key])
        } else {
            return []
        }
    } catch (error) {
        console.error('Failed to fetch badge order:', error)
        throw error
    }
}


export const fetchFestivalGeneralData = async (festivalId) => {

}

export const fetchScheduleData = async (scheduleId) => {

}

export const fetchScheduleCreatorName = async (scheduleId) => {
    if (!scheduleId) throw new Error("Missing schedule ID")

    try {
        // Step 1: Get the schedule data
        const scheduleRef = ref(database, `schedules/${scheduleId}`)
        const scheduleSnapshot = await get(scheduleRef)
        
        if (!scheduleSnapshot.exists()) {
            throw new Error("Schedule not found")
        }

        const scheduleData = scheduleSnapshot.val()
        const creatorId = scheduleData.creator

        // Step 2: Get the creator's display name
        const userRef = ref(database, `users/${creatorId}/displayName`)
        const userSnapshot = await get(userRef)
        
        if (!userSnapshot.exists()) {
            throw new Error("User not found")
        }

        return userSnapshot.val()
    } catch (error) {
        console.error('Error fetching schedule creator name:', error)
        throw error
    }
}
export const clearScheduleSelections = async (scheduleId, userId, festivalId) => {
    if (!scheduleId || !userId || !festivalId) throw new Error("Missing schedule ID, user ID, or festival ID");

    const scheduleSelectionsRef = ref(database, `schedules/${scheduleId}/selections`);

    try {
        // Fetch the selections before clearing them
        const selectionsSnapshot = await get(scheduleSelectionsRef);
        const selections = selectionsSnapshot.val();

        if (!selections) {
            console.log('No selections to clear.');
            return;
        }

        // Clear the selections
        await set(scheduleSelectionsRef, null);
        console.log('Selections cleared successfully');

        // Fetch the groups the user is part of for this festival
        const userGroupsRef = ref(database, `/users/${userId}/groups/${festivalId}`);
        const userGroupsSnapshot = await get(userGroupsRef);
        const userGroups = userGroupsSnapshot.val();

        if (userGroups) {
            // For each group, update the heatmap data based on the cleared selections
            for (const groupId of Object.keys(userGroups)) {
                const groupHeatmapRef = ref(database, `/groups/${groupId}/heatmap`);
                await updateGroupHeatmapAfterClearing(groupHeatmapRef, selections, userId);
            }
        }

    } catch (error) {
        console.error('Error clearing selections and updating heatmaps:', error);
        throw error;
    }
};

// Function to update group heatmap after selections are cleared
async function updateGroupHeatmapAfterClearing(heatmapRef, selections, userId) {
    // Fetch the entire heatmap data in one go
    const heatmapSnapshot = await get(heatmapRef);
    const heatmapData = heatmapSnapshot.val() || {};

    const updates = {};

    // Iterate over each cleared selection and adjust the heatmap accordingly
    for (const artistKey of Object.keys(selections)) {
        const artistData = heatmapData[artistKey] || {};
        const currentTotalVotes = artistData.totalVotes || 0;
        const currentTotalMustSee = artistData.totalMustSee || 0;
        const oldValue = selections[artistKey];

        if (oldValue === 1) {
            updates[`/${artistKey}/totalVotes`] = currentTotalVotes - 1;
            updates[`/${artistKey}/members/${userId}`] = null;
        } else if (oldValue === 2) {
            updates[`/${artistKey}/totalVotes`] = currentTotalVotes - 1;
            updates[`/${artistKey}/totalMustSee`] = currentTotalMustSee - 1;
            updates[`/${artistKey}/members/${userId}`] = null;
        }
    }

    // Apply the updates to the heatmap data for this group
    await update(heatmapRef, updates);

    // Recalculate the maxTotalVotes and maxTotalMustSee across the group
    const updatedHeatmapSnapshot = await get(heatmapRef);
    const updatedHeatmapData = updatedHeatmapSnapshot.val() || {};

    const maxTotalVotes = Math.max(
        ...Object.values(updatedHeatmapData).map(data => (data.totalVotes !== undefined ? data.totalVotes : 0))
    );

    const maxTotalMustSee = Math.max(
        ...Object.values(updatedHeatmapData).map(data => (data.totalMustSee !== undefined ? data.totalMustSee : 0))
    );

    const globalUpdates = {
        maxTotalVotes,
        maxTotalMustSee,
    };

    // Apply the global updates in a single write operation
    await update(heatmapRef, globalUpdates);
}

// ---------------- Requests for a specific festival ----------------
export const fetchGeneralFestivalInfo = async (festivalId) => {
    if (!festivalId) throw new Error("Missing festival ID")

    try {
      const festivalRef = ref(database, `festivals/${festivalId}`)
      const snapshot = await get(festivalRef)
  
      if (!snapshot.exists()) {
        throw new Error("Festival not found")
      }
  
      const { name, location, start, end, daysAndTimes, hasLineup, hasDay, hasSetTimes, imageUrls } = snapshot.val()
      const festivalData = {
        id: festivalId,
        name,
        location,
        start,
        end,
        daysAndTimes,
        hasLineup,
        hasDay,
        hasSetTimes,
        imageUrls
      }
  
      return festivalData
    } catch (error) {
      console.error('Error fetching festival info:', error)
      throw error
    }
}

export const fetchUserScheduleForFestival = async (userId, festivalId) => {
    const scheduleRef = ref(database, `users/${userId}/schedules/${festivalId}`)
    const snapshot = await get(scheduleRef)
    return snapshot.exists() ? snapshot.val() : null
}

// Type: GET
// Details: Fetch heatmap data for a specific festival
// Return: Heatmap data
export const fetchHeatmapDataForFestival = async (festivalId) => {
    if (!festivalId) throw new Error("Missing festival ID")

    try {
        const heatmapRef = ref(database, `festivals/${festivalId}/heatmap`)
        const snapshot = await get(heatmapRef)

        if (!snapshot.exists()) {
            throw new Error("Heatmap data not found")
        }

        return snapshot.val()
    } catch (error) {
        console.error('Error fetching heatmap data:', error)
        throw error
    }
}

export const fetchUserGroupDataForFestival = async (userId, festivalId) => {

}

export const fetchUserRecentAndFavoritesForFestival = async (userId, festivalId) => {

}

// Type: POST
// Details: Create a new group and store the image in Firebase Storage
export const createGroup = async (userId, festivalId, groupName, image) => {
    if (!userId || !festivalId || !groupName || !image) throw new Error("Missing required fields");

    // Step 1: Resize image before uploading
    const resizedImage = await resizeImage(image, 400, 400); // Max width and height

    // Step 2: Upload image to Firebase Storage
    const imageRef = storageRef(storage, `groups/${userId}/${Date.now()}.jpg`);
    await uploadString(imageRef, resizedImage, 'data_url');
    const imageUrl = await getDownloadURL(imageRef);

    // Step 3: Create group in Firebase Database
    const newGroupRef = push(ref(database, 'groups'));
    const newGroup = {
        createdAt: Date.now(),
        creator: userId,
        name: groupName,
        picture: imageUrl,
        festival: festivalId,
        members: {
            [userId]: true,
        },
        admins: {
            [userId]: true,
        },
        memberCount: 1,
    };

    await set(newGroupRef, newGroup);
    const newGroupId = newGroupRef.key;

    // Step 4: Update user's groups in Firebase Database
    const userGroupsRef = ref(database, `users/${userId}/groups/${festivalId}/${newGroupId}`);
    await set(userGroupsRef, true);

    // Step 5: Add the creator's schedule to the group's schedules list
    const userSnapshot = await get(ref(database, `users/${userId}`));
    const userData = userSnapshot.val();
    const userScheduleId = userData.schedules[festivalId];

    if (userScheduleId) {
        await set(ref(database, `groups/${newGroupId}/schedules/${userScheduleId}`), true);
        
        // Step 6: Initialize the group's heatmap with the user's current selections
        const scheduleSelectionsRef = ref(database, `schedules/${userScheduleId}/selections`);
        const selectionsSnapshot = await get(scheduleSelectionsRef);
        const selections = selectionsSnapshot.val() || {};

        const groupHeatmapRef = ref(database, `groups/${newGroupId}/heatmap`);
        
        // Iterate over the selections and update the group's heatmap
        const updates = {};
        for (const artistKey of Object.keys(selections)) {
            const newValue = selections[artistKey];
            if (newValue === 1) {
                updates[`/${artistKey}/totalVotes`] = increment(1);
                updates[`/${artistKey}/members/${userId}`] = 1;
            } else if (newValue === 2) {
                updates[`/${artistKey}/totalVotes`] = increment(1);
                updates[`/${artistKey}/totalMustSee`] = increment(1);
                updates[`/${artistKey}/members/${userId}`] = 2;
            }
        }

        await update(groupHeatmapRef, updates);

        // Calculate and update maxTotalVotes and maxTotalMustSee
        const updatedHeatmapSnapshot = await get(groupHeatmapRef);
        const updatedHeatmapData = updatedHeatmapSnapshot.val() || {};

        const maxTotalVotes = Math.max(
            ...Object.values(updatedHeatmapData).map(data => (data.totalVotes !== undefined ? data.totalVotes : 0)),
            0 // Ensures no -Infinity value
        );

        const maxTotalMustSee = Math.max(
            ...Object.values(updatedHeatmapData).map(data => (data.totalMustSee !== undefined ? data.totalMustSee : 0)),
            0 // Ensures no -Infinity value
        );

        const globalUpdates = {
            maxTotalVotes,
            maxTotalMustSee,
        };

        await update(groupHeatmapRef, globalUpdates);
    }

    return newGroupId;
};

// Function to upload group picture and return the URL
export const uploadGroupPicture = async (imgDataURL, groupId) => {
    if (!imgDataURL || !groupId) throw new Error("Missing image data or group ID")

    try {
        // Step 1: Resize image before uploading (assuming 400x400 max dimensions)
        const resizedImage = await resizeImage(imgDataURL, 400, 400)

        // Step 2: Create a storage reference for the group picture
        const imageRef = storageRef(storage, `groups/${groupId}/${Date.now()}.jpg`)

        // Step 3: Upload the resized image to Firebase Storage
        await uploadString(imageRef, resizedImage, 'data_url')

        // Step 4: Get the download URL of the uploaded image
        const imageUrl = await getDownloadURL(imageRef)

        return imageUrl
    } catch (error) {
        console.error('Error uploading group picture:', error)
        throw error
    }
}

// Function to update group data in Firebase
export const updateGroupData = async (groupId, updates) => {
    if (!groupId || !updates) throw new Error("Missing group ID or updates");

    try {
        // Create a reference to the group's data in the database
        const groupRef = ref(database, `groups/${groupId}`);

        // Update the group's data with the provided updates
        await update(groupRef, updates);

        console.log('Group data updated successfully');
    } catch (error) {
        console.error('Error updating group data:', error);
        throw error;
    }
}

// Type: DELETE
// Details: Delete a group and its associated data
export const deleteGroup = async (userId, festivalId, groupId) => {
    if (!userId || !festivalId || !groupId) throw new Error("Missing required fields");

    const groupRef = ref(database, `groups/${groupId}`);
    const userGroupsRef = ref(database, `users/${userId}/groups/${festivalId}/${groupId}`);
    const groupSnapshot = await get(groupRef);
    const groupData = groupSnapshot.val();

    if (groupData.admins && groupData.admins[userId]) {
        try {
            // Step 1: Set deleting flag
            await update(groupRef, { deleting: true });

            // Step 2: Get the group data
            const groupSnapshot = await get(groupRef);
            if (!groupSnapshot.exists()) {
                throw new Error("Group not found");
            }

            const groupData = groupSnapshot.val();

            // Step 3: Remove group picture from Firebase Storage if it exists
            if (groupData.picture) {
                const imageRef = storageRef(storage, groupData.picture);
                await deleteObject(imageRef);
            }

            // Step 4: Remove group from each member's groups list
            const members = groupData.members;
            const memberUpdates = {};
            Object.keys(members).forEach(memberId => {
                memberUpdates[`users/${memberId}/groups/${festivalId}/${groupId}`] = null;
            });
            await update(ref(database), memberUpdates);

            // Step 5: Remove the group's schedules
            if (groupData.schedules) {
                const scheduleUpdates = {};
                Object.keys(groupData.schedules).forEach(scheduleId => {
                    scheduleUpdates[`groups/${groupId}/schedules/${scheduleId}`] = null;
                });
                await update(ref(database), scheduleUpdates);
            }

            // Step 6: Remove the group data from the database
            await remove(groupRef);

            console.log("Group deleted successfully");
        } catch (error) {
            console.error('Error deleting group:', error);
            throw error;
        }
    }
}

// Type: GET
// Details: Fetch user's groups
export const fetchUserGroupsForFestival = async (userId, festivalId) => {
    const userGroupsRef = ref(database, `users/${userId}/groups/${festivalId}`)
    try {
        const snapshot = await get(userGroupsRef)
        const groupIds = snapshot.exists() ? Object.keys(snapshot.val()) : []

        const groupPromises = groupIds.map(async (groupId) => {
            const groupRef = ref(database, `groups/${groupId}`)
            const groupSnapshot = await get(groupRef)
            return { id: groupId, ...groupSnapshot.val() }
        })

        return await Promise.all(groupPromises)
    } catch (error) {
        console.error('Error fetching user groups:', error)
        throw error
    }
}

// Type: GET
// Details: Fetch group data by group ID
// Return: Group data
export const fetchGroupDataById = async (groupId) => {
    if (!groupId) throw new Error("Missing group ID")

    const groupRef = ref(database, `groups/${groupId}`)
    try {
        const snapshot = await get(groupRef)
        if (snapshot.exists()) {
            return snapshot.val()
        } else {
            throw new Error("Group not found")
        }
    } catch (error) {
        console.error('Error fetching group data:', error)
        throw error
    }
}

export const addMemberToGroup = async (groupId, userId, festivalId) => {
    if (!groupId || !userId || !festivalId) throw new Error("Missing group ID, user ID, or festival ID");

    const groupRef = ref(database, `groups/${groupId}`);
    const memberRef = ref(database, `groups/${groupId}/members/${userId}`);
    const userGroupsRef = ref(database, `users/${userId}/groups/${festivalId}/${groupId}`);

    try {
        // Check if the member already exists
        const memberSnapshot = await get(memberRef);
        if (memberSnapshot.exists()) {
            throw new Error("User is already a member of this group");
        }

        // Add the member to the group
        await set(memberRef, true);

        // Increment the member count
        await update(groupRef, {
            memberCount: increment(1),
        });

        // Add the group to the user's groups
        await set(userGroupsRef, true);

        // Ensure the user's schedule is added to the group's schedules
        const userSnapshot = await get(ref(database, `users/${userId}`));
        const userData = userSnapshot.val();
        const userScheduleId = userData.schedules[festivalId];

        if (userScheduleId) {
            await set(ref(database, `groups/${groupId}/schedules/${userScheduleId}`), true);

            // Fetch the user's current selections from their schedule
            const scheduleSelectionsRef = ref(database, `schedules/${userScheduleId}/selections`);
            const selectionsSnapshot = await get(scheduleSelectionsRef);
            const selections = selectionsSnapshot.val() || {};

            // Update the group's heatmap with the user's selections
            const groupHeatmapRef = ref(database, `groups/${groupId}/heatmap`);
            const updates = {};
            for (const artistKey of Object.keys(selections)) {
                const newValue = selections[artistKey];
                if (newValue === 1) {
                    updates[`/${artistKey}/totalVotes`] = increment(1);
                    updates[`/${artistKey}/members/${userId}`] = 1;
                } else if (newValue === 2) {
                    updates[`/${artistKey}/totalVotes`] = increment(1);
                    updates[`/${artistKey}/totalMustSee`] = increment(1);
                    updates[`/${artistKey}/members/${userId}`] = 2;
                }
            }

            await update(groupHeatmapRef, updates);

            // Calculate and update maxTotalVotes and maxTotalMustSee
            const updatedHeatmapSnapshot = await get(groupHeatmapRef);
            const updatedHeatmapData = updatedHeatmapSnapshot.val() || {};

            const maxTotalVotes = Math.max(
                ...Object.values(updatedHeatmapData).map(data => (data.totalVotes !== undefined ? data.totalVotes : 0)),
                0 // Ensures no -Infinity value
            );

            const maxTotalMustSee = Math.max(
                ...Object.values(updatedHeatmapData).map(data => (data.totalMustSee !== undefined ? data.totalMustSee : 0)),
                0 // Ensures no -Infinity value
            );

            const globalUpdates = {
                maxTotalVotes,
                maxTotalMustSee,
            };

            await update(groupHeatmapRef, globalUpdates);
        }
    } catch (error) {
        console.error('Error adding member to group:', error);
        throw error;
    }
};

export const removeMemberFromGroup = async (groupId, userId) => {
    if (!groupId || !userId) throw new Error("Missing group ID or user ID")

    const groupRef = ref(database, `groups/${groupId}`)
    const memberRef = ref(database, `groups/${groupId}/members/${userId}`)

    try {
        // Check if the member exists
        const memberSnapshot = await get(memberRef)
        if (!memberSnapshot.exists()) {
            throw new Error("User is not a member of this group")
        }

        // Remove the member from the group
        await set(memberRef, null)

        // Decrement the member count
        await update(groupRef, {
            memberCount: increment(-1)
        })

        // Ensure the user's schedule is removed from the group's schedules
        const userSnapshot = await get(ref(database, `users/${userId}`))
        const userData = userSnapshot.val()
        const userScheduleId = userData.schedules[groupRef.festival]
        
        if (userScheduleId) {
            await set(ref(database, `groups/${groupId}/schedules/${userScheduleId}`), null)
        }

    } catch (error) {
        console.error('Error removing member from group:', error)
        throw error
    }
}

// Fetch schedule creator's displayName and lowRes picture, and add the schedule to the user's recent views
export const fetchScheduleCreatorInfoAndAddToRecents = async (currentUserId, scheduleId, festivalId) => {
    if (!currentUserId || !scheduleId || !festivalId) throw new Error("Missing required parameters")

    try {
        // Step 1: Get the schedule data
        const scheduleRef = ref(database, `schedules/${scheduleId}`)
        const scheduleSnapshot = await get(scheduleRef)
        
        if (!scheduleSnapshot.exists()) {
            throw new Error("Schedule not found")
        }

        const scheduleData = scheduleSnapshot.val()
        const creatorId = scheduleData.creator

        // Step 2: Get the creator's displayName and lowRes picture
        const userRef = ref(database, `users/${creatorId}`)
        const userSnapshot = await get(userRef)
        
        if (!userSnapshot.exists()) {
            throw new Error("User not found")
        }

        const userData = userSnapshot.val()
        const creatorInfo = {
            displayName: userData.displayName,
            picture: userData.picture.highRes,
            scheduleId: scheduleId,
            timestamp: Date.now()
        }

        // Step 3: Add or update the schedule in the user's recent views
        const recentRef = ref(database, `users/${currentUserId}/recents/${festivalId}/${creatorId}`)
        await update(recentRef, creatorInfo)

        return creatorInfo
    } catch (error) {
        console.error('Error fetching schedule creator info or updating recents:', error)
        throw error
    }
}

// Fetch schedule creator's displayName and lowRes picture, and add the schedule to the user's recent views
export const fetchScheduleCreatorInfo = async (scheduleId, festivalId) => {
    if (!scheduleId || !festivalId) throw new Error("Missing required parameters")

    try {
        // Step 1: Get the schedule data
        const scheduleRef = ref(database, `schedules/${scheduleId}`)
        const scheduleSnapshot = await get(scheduleRef)
        
        if (!scheduleSnapshot.exists()) {
            throw new Error("Schedule not found")
        }

        const scheduleData = scheduleSnapshot.val()
        const creatorId = scheduleData.creator

        // Step 2: Get the creator's displayName and lowRes picture
        const userRef = ref(database, `users/${creatorId}`)
        const userSnapshot = await get(userRef)
        
        if (!userSnapshot.exists()) {
            throw new Error("User not found")
        }

        const userData = userSnapshot.val()
        const creatorInfo = {
            displayName: userData.displayName,
            picture: userData.picture.lowRes,
            scheduleId: scheduleId,
            timestamp: Date.now()
        }

        return creatorInfo
    } catch (error) {
        console.error('Error fetching schedule creator info or updating recents:', error)
        throw error
    }
}

export const logGenerateImagesEvent = (user, festival, colorStyle, isShuffleActive, isRandomActive, seed) => {
    const eventRef = push(ref(database, '/data'))

    let colorStyleName = colorStyle.name
    if (isShuffleActive) colorStyleName = "Shuffle"
    if (isRandomActive) colorStyleName = "Random"

    const eventData = {
        userId: user.uid || 'anonymous',
        timestamp: Date.now(),
        colorStyleName: colorStyleName,
        festivalId: festival.id,
        seed: seed
    }
    set(eventRef, eventData)
}