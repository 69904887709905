import React, { useState, useRef, useEffect } from 'react'
import Modal from 'react-modal'
import html2canvas from 'html2canvas'
import { IconX } from '@tabler/icons-react'
import './Modal.css'
import './ImageModal.css'
import ExportColorSet from './ExportColorSet'
import { prepareScheduleData, splitLineupDataByDay, generateRandomColorSet } from './imageModalHelpers'
import { defaultColorStyle } from './ImageConstants'
import SetTimeImage from './SetTimeImage'
import { Checkbox, Typography } from '@mui/joy'
import { colorSpecs } from './ImageConstants'
import { useLocalStorageState } from '../../hooks/useLocalStorageState'
import { hexToRgbA } from '../../utils/helpers'
import { set } from 'firebase/database'
import { logGenerateImagesEvent } from '../../services/firebase/databaseService'

Modal.setAppElement('#root')

const ImageModal = ({ isOpen, onRequestClose, lineupData, currentFestival, blockSelections, currentUser }) => {
    const windowWidth = window.innerWidth
    const scheduleRefs = useRef([])
    const maxStagesToSelect = 7

    const initialSelectedStages = lineupData.slice(0, maxStagesToSelect).map(([stageName]) => stageName)
    const [selectedStages, setSelectedStages] = useState(initialSelectedStages)

    const [shouldLoadP5, setShouldLoadP5] = useState(false)
    const [p5Ready, setP5Ready] = useState(false)
    const [showDelayedMessage, setShowDelayedMessage] = useState(false)
    const [showSecondDelayedMessage, setShowSecondDelayedMessage] = useState(false)

    const [images, setImages] = useState([])
    const [showCustomization, setShowCustomization] = useState(true)
    const [customizationOptions, setCustomizationOptions] = useLocalStorageState("customizationOptions", {
        dimensions: {
            width: windowWidth < 1000 ? window.screen.width : 393, 
            height: windowWidth < 1000 ? window.screen.height : 852
        }, 
        selectionType: 'selected', 
        contactName: '', 
        contactInfo: '',
        visualizationSeed: '',
        showSeedPhrase: true,
        userHasWidgets: true,
        useSolidBackground: false
    })
    const [colorStyle, setColorStyle] = useState(defaultColorStyle)
    const [isShuffleActive, setIsShuffleActive] = useState(true)
    const [isRandomActive, setIsRandomActive] = useState(false)

    const scheduleImageData = prepareScheduleData(lineupData, blockSelections, customizationOptions)

    const handleCustomizationChange = (event) => {
        const { name, value, type, checked } = event.target
        setCustomizationOptions({
            ...customizationOptions,
            [name]: type === 'checkbox' ? checked : value
        })
    }
    const handleGenerateImages = () => {
        setShowCustomization(false)
        if (isShuffleActive) {
            const presetColorSpecs = colorSpecs.filter(colorSpec => colorSpec.name !== 'random')
            const randomIndex = Math.floor(Math.random() * presetColorSpecs.length)
            setColorStyle(presetColorSpecs[randomIndex])
        } else if (isRandomActive) {
            setColorStyle(generateRandomColorSet())
        }

        logGenerateImagesEvent(currentUser, currentFestival, colorStyle, isShuffleActive, isRandomActive, customizationOptions.visualizationSeed)
        
        setTimeout(() => {
            setShouldLoadP5(true)
            setShowDelayedMessage(false)
            setShowSecondDelayedMessage(false)
            setTimeout(() => {
                setShowDelayedMessage(true)
            }, 4500)
        }, 0)
    }
    const handleBackToCustomization = () => {
        setShowCustomization(true)
        setImages([])
        setShowDelayedMessage(false)
        setShowSecondDelayedMessage(false)
    }

    useEffect(() => {
        if (shouldLoadP5 && p5Ready) {
            generateImages(scheduleImageData)
        }
    }, [shouldLoadP5, p5Ready])

    useEffect(() => {
        const rootElement = document.getElementById('root')
        const bodyElement = document.body
        const htmlElement = document.documentElement
        
        if (isOpen) {
            rootElement.classList.add('no-scroll')
            bodyElement.classList.add('no-scroll')
            htmlElement.classList.add('no-scroll')
        } else {
            rootElement.classList.remove('no-scroll')
            bodyElement.classList.remove('no-scroll')
            htmlElement.classList.remove('no-scroll')
        }
        
        return () => {
            rootElement.classList.remove('no-scroll')
            bodyElement.classList.remove('no-scroll')
            htmlElement.classList.remove('no-scroll')
        }
    }, [isOpen])

    const generateImages = async (scheduleImageData) => {
        try {
            const generatedImages = await Promise.all(
                scheduleRefs.current.map(async (ref, index) => {
                    const canvas = await html2canvas(ref, { 
                        scale: 1, 
                        allowTaint: true,
                        useCORS: true,
                        logging: true
                    })
                    const dataUrl = canvas.toDataURL('image/png')
                    return { day: scheduleImageData[index].day, dataUrl }
                })
            )

            const validImages = generatedImages.filter(img => img !== null)
            setImages(validImages)
            setShouldLoadP5(false)
            setP5Ready(false)
        } catch (error) {
            console.error("Error generating images:", error)
        }
    }

    const handleStageClick = (stageName, isDisabled) => {
        if (isDisabled) return;
    
        const updatedStages = selectedStages.includes(stageName)
            ? selectedStages.filter(stage => stage !== stageName)
            : [...selectedStages, stageName];
    
        setSelectedStages(updatedStages);
    };

    const splitLineupData = splitLineupDataByDay(lineupData, currentFestival)

    let chosenColorStyleName = `"${colorStyle.name}"`
    if (isShuffleActive) chosenColorStyleName = `Shuffle palettes`
    if (isRandomActive) chosenColorStyleName = `Generate random palette`
    
    let dialogBody = <></>
    if (showCustomization) {
        dialogBody = (
            <div className="export-modal-content">
                <div className="x-select-stage">
                    <div className="input-label">
                        {
                            lineupData.length <= maxStagesToSelect 
                                ? 'Stages' 
                                : `Select up to ${maxStagesToSelect} stages`
                        }
                        <span className="input-label-reg">— <i>{selectedStages.length} selected</i></span>
                    </div>
                    <div className="x-stage-selection-wrapper">
                        {lineupData.map(([stageName]) => {
                            const isSelected = selectedStages.includes(stageName);
                            const isDisabled = selectedStages.length >= maxStagesToSelect && !isSelected;

                            return (
                                <div
                                    key={stageName}
                                    className={`x-selectable-stage ${isSelected ? 'x-stage-selected' : ''} ${isDisabled ? 'x-stage-disabled' : ''}`}
                                    onClick={() => handleStageClick(stageName, isDisabled)}
                                    data-tooltip-id="tt"
                                    data-tooltip-content={`${isDisabled ? 'You have already selected the max # of stages' : ''}`}
                                >
                                    {stageName}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="export-colors-wrapper">
                    <div className="input-label">
                        Color palette
                        <span className="input-label-reg">— <i>{chosenColorStyleName}</i></span>
                    </div>
                    <ExportColorSet 
                        selectedColorStyle={colorStyle} 
                        setColorStyle={setColorStyle} 
                        isShuffleActive={isShuffleActive}
                        setIsShuffleActive={setIsShuffleActive}
                        isRandomActive={isRandomActive}
                        setIsRandomActive={setIsRandomActive}
                    />
                </div>
                <div className="x-display-options-wrapper">
                    <label className="input-label">Display options</label>
                    <div className="checkbox-offset-2">
                        <Checkbox 
                            size="sm"
                            label={<Typography sx={{ color: 'white', fontFamily: 'Lato', fontSize: '14px', opacity: 0.8 }}>I have widgets on my lock screen</Typography>} 
                            variant="solid"
                            checked={customizationOptions.userHasWidgets} 
                            onChange={(event) => setCustomizationOptions({
                                ...customizationOptions,
                                userHasWidgets: event.target.checked
                            })}
                        />
                    </div>
                    <div className="checkbox-offset-2">
                        <Checkbox 
                            size="sm"
                            label={<Typography sx={{ color: 'white', fontFamily: 'Lato', fontSize: '14px', opacity: 0.8 }}>Just give me a solid background</Typography>} 
                            variant="solid"
                            checked={customizationOptions.useSolidBackground} 
                            onChange={(event) => setCustomizationOptions({
                                ...customizationOptions,
                                useSolidBackground: event.target.checked
                            })}
                        />
                    </div>
                </div>
                <div className="x-user-inputs">
                    {!customizationOptions.useSolidBackground && (
                        <div className="x-seed-phrase-wrapper">
                            <div className="x-user-input-wrapper">
                                <label className="input-label">Seed phrase</label>
                                <p className='label-subtext'>Make it truly yours! Every seed affects the image differently. No two images are the same.</p>
                                <input
                                    type="text"
                                    name="visualizationSeed"
                                    className="input-default"
                                    value={customizationOptions.visualizationSeed}
                                    onChange={handleCustomizationChange}
                                    placeholder="e.g. 'FestiPlannr'"
                                    maxLength={50}
                                />
                            </div>
                            {customizationOptions.visualizationSeed && (
                                <div className="checkbox-offset">
                                    <Checkbox 
                                        size="sm"
                                        label={<Typography sx={{ color: 'white', fontFamily: 'Lato', fontSize: '14px', opacity: 0.8 }}>Show seed phrase in image</Typography>} 
                                        variant="solid"
                                        checked={customizationOptions.showSeedPhrase} 
                                        onChange={(event) => setCustomizationOptions({
                                            ...customizationOptions,
                                            showSeedPhrase: event.target.checked
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="x-user-input-wrapper ">
                        <label className="input-label">Lost & found contact info</label>
                        <p className="label-subtext">In case you lose your phone!</p>
                        <input
                            type="text"
                            name="contactInfo"
                            className="input-default"
                            value={customizationOptions.contactInfo}
                            onChange={handleCustomizationChange}
                            placeholder="e.g. a friend's phone #"
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        let exportedText = "Your downloadable phone wallpaper images are ready!"
        if (windowWidth <= 1000) exportedText = "Your images are ready! Tap and hold to save high-quality versions of these images to your camera roll."

        dialogBody = (
            <div className="export-modal-content-no-padding">
                {images.length === 0 ? (
                    <div className="export-loading-wrapper">
                        <div className="x-spinner" />
                        <p>Generating images...</p>
                        {showDelayedMessage && (
                            <p className="x-hang-tight opacity50">Hang tight! Some images are more complex and can take longer to generate than others.</p>
                        )}
                        {showSecondDelayedMessage && (
                            <p className="x-hang-tight opacity50">If the images are not appearing, you may need to refresh the page.</p>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="export-imgs-text-wrapper">
                            <p className="opacity80">{exportedText}</p>
                        </div>
                        <div className="export-all-imgs-wrapper">
                            {images.map((img, index) => (
                                <div key={index} className="export-img-wrapper">
                                    {/* <div 
                                        className="export-day-header"
                                        style={{
                                            color: colorStyle.main
                                        }}
                                    >{img.day}</div> */}
                                    <img 
                                        className="export-img" 
                                        src={img.dataUrl} 
                                        alt={`${img.day} schedule`}
                                        style={{
                                            boxShadow: `0 0 10px -1px rgba(0,0,0,0.75)`,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal-wrapper"
            overlayClassName="modal-overlay"
        >
            <div className="modal-image">
                <div className="export-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Export to wallpaper</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={onRequestClose} />
                </div>
                {dialogBody}
                {showCustomization && (
                    <div className="export-actions">
                        <button 
                            className="btn-primary" 
                            onClick={handleGenerateImages} 
                            disabled={selectedStages.length === 0}
                            data-tooltip-id="tt"
                            data-tooltip-content={`${selectedStages.length === 0 ? 'Select one or more stages to continue' : ''}`}
                        >Generate images</button>
                    </div>
                )}
                {!showCustomization && images.length !== 0 && (
                    <div className="export-actions">
                        <button className="btn-primary" onClick={handleBackToCustomization}>Back to customization</button>
                    </div>
                )}
            </div>
            {shouldLoadP5 && splitLineupData?.map((dayData, index) => (
                <SetTimeImage 
                    key={index}
                    index={index}
                    dayData={dayData}
                    customizationOptions={customizationOptions}
                    colorStyle={colorStyle}
                    currentFestival={currentFestival}
                    scheduleRefs={scheduleRefs}
                    shouldLoadP5={shouldLoadP5}
                    setP5Ready={setP5Ready}
                    blockSelections={blockSelections}
                    selectedStages={selectedStages}
                />
            ))}
        </Modal>
    )
}

export default ImageModal