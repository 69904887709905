import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import SetTimeSchedule from '../Schedule/SetTimeSchedule'
import BlockSchedule from '../Schedule/BlockSchedule'
import ScheduleActions from '../FestivalHeaders/ScheduleActions'
import Members from '../Groups/Members'
import BottomContainer from '../Schedule/BottomContainer'
import NotFoundPage from '../General/404'

import { 
    fetchHeatmapDataForFestival, 
    fetchGroupDataById, 
    fetchScheduleCreatorInfoAndAddToRecents,
    fetchScheduleCreatorInfo,
    clearScheduleSelections
} from '../../services/firebase/databaseService'
import { database } from '../../config/firebase-config'
import { ref, onValue, off, get } from 'firebase/database'
import { useLocalStorageState } from '../../hooks/useLocalStorageState'

const FestivalSchedule = ({ 
    scheduleType,
    currentUser, 
    currentFestival,
    lineupData,
    headerComponent: HeaderComponent,
    isLoadingSchedule,
    setIsNavHidden
 }) => {
    // ----------------------------------- GENERAL -----------------------------------
    const { scheduleId, groupId } = useParams()
    const navigate = useNavigate()
    const [selectedDayIndex, setSelectedDayIndex] = useState(
        currentFestival?.id === "ll24" ? 2 : 0
    )
    const [saveStatus, setSaveStatus] = useState("Saved")
    const [isLoading, setIsLoading] = useState(true)
    const [isRecsPanelOpen, setIsRecsPanelOpen] = useState(false)
    const [recs, setRecs] = useState([])

    // ------------------------------ USER SCHEDULE DATA ------------------------------
    const [blockSelections, setBlockSelections] = useState({})
    const [localBlockSelections, setLocalBlockSelections] = useLocalStorageState(`localBlockSelections_${currentFestival.id}`, {})
    const [isCreator, setIsCreator] = useState(true)
    const [creatorInfo, setCreatorInfo] = useState({})
    const [isScheduleNotFound, setIsScheduleNotFound] = useState(false)
    useEffect(() => {
        if (currentFestival && scheduleType === "userSchedule" && scheduleId) {
            const scheduleRef = ref(database, `schedules/${scheduleId}`)
            onValue(scheduleRef, async (snapshot) => {
                const data = snapshot.val()
                if (data) {
                    setBlockSelections(data.selections || {})
    
                    if (currentUser) {
                        if (data.creator !== currentUser.uid) {
                            setIsCreator(false)
                            try {
                                const info = await fetchScheduleCreatorInfoAndAddToRecents(currentUser.uid, scheduleId, currentFestival.id)
                                setCreatorInfo(info)
                            } catch (error) {
                                if (error.message === "Schedule not found") {
                                    setIsScheduleNotFound(true)
                                }
                            }
                        } else {
                            setIsCreator(true)
                            setCreatorInfo({})
                        }
                    } else {
                        setIsCreator(false)

                        try {
                            const info = await fetchScheduleCreatorInfo(scheduleId, currentFestival.id)
                            setCreatorInfo(info)
                        } catch (error) {
                            if (scheduleId !== "new" && error.message === "Schedule not found") {
                                setIsScheduleNotFound(true)
                            }
                        }
                    }
                    setIsLoading(false)
                } else {
                    if (scheduleId !== "new") {
                        setIsScheduleNotFound(true)
                        setIsLoading(false)
                    } else {
                        setIsScheduleNotFound(false)
                        setIsLoading(false)
                    }
                }
            })
            return () => off(scheduleRef)
        }
    }, [scheduleId, currentFestival, currentUser, scheduleType])

    const clearBlockSelections = async () => {
        setSaveStatus("Saving...")
        if (currentUser) {
            try {
                await clearScheduleSelections(scheduleId, currentUser.uid, currentFestival.id)
                setBlockSelections({})
            } catch (error) {
                console.error('Error clearing selections:', error)
            }
        } else {
            setLocalBlockSelections({})
        }
        setTimeout(() => {
            setSaveStatus("Saved")
        }, 400)
    }

    useEffect(() => {
        if (scheduleType !== "userSchedule" || !isCreator) {
            setIsRecsPanelOpen(false)
        }
    }, [scheduleType, isCreator])
    
    // ----------------------------- FESTIVAL HEATMAP DATA -----------------------------
    const [festivalHeatmapData, setFestivalHeatmapData] = useState(null)
    useEffect(() => {
        if (currentUser && currentFestival && scheduleType === "festivalHeatmap") {
            const fetchData = async () => {
                try {
                    const data = await fetchHeatmapDataForFestival(currentFestival.id)
                    setFestivalHeatmapData(data)
                } catch (error) { console.error("Failed to fetch heatmap data:", error) }
            }
            setIsLoading(false)
            fetchData()
        } else { setFestivalHeatmapData(null) }
    }, [currentFestival, scheduleType, currentUser])

    // ---------------------------------- GROUP DATA ----------------------------------
    const [groupData, setGroupData] = useState(null);
    const [membersData, setMembersData] = useState({});
    const [selectedTab, setSelectedTab] = useState(0); // 0: Heatmap, 1: Members
    const [isGroupNotFound, setIsGroupNotFound] = useState(false);
    const [groupSelections, setGroupSelections] = useState({});

    useEffect(() => {
        if (currentFestival && scheduleType === "group" && groupId) {
            const groupRef = ref(database, `groups/${groupId}`);

            const loadGroupData = async () => {
                try {
                    // Set up a listener for group data
                    onValue(groupRef, async (snapshot) => {
                        const data = snapshot.val();
                        if (!data) {
                            setIsGroupNotFound(true);
                            setIsLoading(false);
                            return;
                        }

                        setGroupData(data);

                        if (currentUser) {
                            // Handle redirection for unauthenticated users
                            if (!data.members[currentUser.uid] && currentUser.displayName !== "famousjenga") {
                                navigate(`/${currentFestival.id}/g/${groupId}/join`);
                                return;
                            }
                        } else {
                            // Redirect unauthenticated users to join page
                            navigate(`/${currentFestival.id}/g/${groupId}/join`);
                            return;
                        }

                        // Set group selections if available
                        if (data.selections) {
                            setGroupSelections(data.selections);
                        }

                        // Fetch and set member details
                        const members = data.members || {};
                        const memberDetails = await Promise.all(
                            Object.keys(members).map(async (memberId) => {
                                const memberRef = ref(database, `/users/${memberId}`);
                                const memberSnapshot = await get(memberRef);
                                const memberData = memberSnapshot.val();
                                const scheduleId = memberData.schedules[currentFestival.id] || null;

                                return {
                                    memberId,
                                    displayName: memberData.displayName,
                                    picture: memberData.picture,
                                    scheduleId,
                                };
                            })
                        );

                        const membersData = memberDetails.reduce((acc, member) => {
                            acc[member.memberId] = member;
                            return acc;
                        }, {});

                        setMembersData(membersData);
                        setIsLoading(false);
                    }, (error) => {
                        console.error('Error fetching group data:', error);
                        setIsLoading(false);
                        setIsGroupNotFound(true);
                    });
                } catch (error) {
                    console.error('Error loading group data:', error);
                    setIsLoading(false);
                    setIsGroupNotFound(true);
                }
            };

            setIsGroupNotFound(false);
            loadGroupData();

            // Cleanup listener on component unmount or when dependencies change
            return () => {
                off(groupRef);
            };
        } else {
            setGroupData(null);
            setIsLoading(false);
        }
    }, [groupId, currentFestival, currentUser, scheduleType, navigate]);

    // ---------------------------------- MISC VARS ----------------------------------
    const [heatmapType, setHeatmapType] = useState(0)
    let heatmapColor = (scheduleType === "festivalHeatmap") ? "#00BE1E" : "#00AEBE"
    const [bottomContainerData, setBottomContainerData] = useState(null)

    const incrementSelectedDayIndex = () => {
        setSelectedDayIndex(prevIndex => (prevIndex + 1) % currentFestival.daysAndTimes.length)
    }

    // ---------------------- CHOOSE SET TIME OR BLOCK SCHEDULE ----------------------
    let schedule = <React.Fragment></React.Fragment>
    if (!isLoading && currentFestival) {
        if (currentFestival.hasSetTimes) {
            schedule = (
                <SetTimeSchedule 
                    // General props
                    scheduleType={scheduleType}
                    currentUser={currentUser} 
                    festival={currentFestival}
                    lineupData={lineupData}
                    selectedDayIndex={selectedDayIndex}
                    incrementSelectedDayIndex={incrementSelectedDayIndex}
                    setSaveStatus={setSaveStatus}
                    // User schedule props
                    scheduleId={scheduleId}
                    blockSelections={blockSelections}
                    setBlockSelections={setBlockSelections}
                    localBlockSelections={localBlockSelections}
                    setLocalBlockSelections={setLocalBlockSelections}
                    isCreator={isCreator}
                    recs={recs}
                    // Festival heatmap props
                    heatmapType={heatmapType}
                    festivalHeatmapData={festivalHeatmapData}
                    heatmapColor={heatmapColor}
                    // Group props
                    groupData={groupData}
                    membersData={membersData}
                    groupId={groupId}
                    groupSelections={groupSelections}
                    setGroupSelections={setGroupSelections}
                />
            )
        } else {
            schedule = (
                <div className="block-schedule-wrapper">
                    <BlockSchedule 
                        // General props
                        scheduleType={scheduleType}
                        currentUser={currentUser} 
                        festival={currentFestival} 
                        lineupData={lineupData}
                        selectedDayIndex={selectedDayIndex} 
                        setSaveStatus={setSaveStatus}
                        // User schedule props
                        scheduleId={scheduleId} 
                        blockSelections={blockSelections}
                        setBlockSelections={setBlockSelections}
                        localBlockSelections={localBlockSelections}
                        setLocalBlockSelections={setLocalBlockSelections}
                        isCreator={isCreator}
                        recs={recs}
                        // Festival heatmap props
                        heatmapType={heatmapType}
                        festivalHeatmapData={festivalHeatmapData}
                        heatmapColor={heatmapColor}
                        // Group props
                        groupData={groupData}
                        membersData={membersData}
                        groupId={groupId}
                        groupSelections={groupSelections}
                        setGroupSelections={setGroupSelections}
                    />
                </div>
            )
        
        }
    }

    let isGroup = scheduleType === "group"
    let isGroupMembersPage = isGroup && selectedTab === 1
    // if (isLoading || !currentFestival) return <div></div>
    if (isLoadingSchedule) return (
        <div className="main-wrapper">
            <div className="loading-full-percent">
                <div className="logo-circle" />
                <div className="loading-text">Loading schedule...</div>
            </div>
        </div>
    )
    if (isScheduleNotFound) return <NotFoundPage subtext={"This schedule does not exist."} currentUser={currentUser} />
    if (isGroupNotFound) return <NotFoundPage subtext={"This group does not exist."} currentUser={currentUser} />

    return (
        <div 
            id="schedule-wrapper" 
            className={`
                fade-in-content-no-scale 
                ${currentFestival && currentFestival.hasSetTimes ? 'main-wrapper-set' : 'main-wrapper'}
                ${isRecsPanelOpen ? 'main-wrapper-recs' : ''}
            `}
        >
            <HeaderComponent 
                currentUser={currentUser}
                scheduleType={scheduleType}
                currentFestival={currentFestival}
                creatorInfo={creatorInfo}
                isCreator={isCreator}
                saveStatus={saveStatus}
                groupData={groupData}
                membersData={membersData}
                groupId={groupId}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                festivalHeatmapLastUpdate={festivalHeatmapData?.lastUpdated}
                lineupData={lineupData}
                blockSelections={blockSelections}
                localBlockSelections={localBlockSelections}
            />
            {isGroupMembersPage 
                ? (
                    <Members
                        currentUser={currentUser}
                        currentFestival={currentFestival}
                        groupData={groupData}
                        membersData={membersData}
                        groupId={groupId}
                    />
                )
                : (
                    <>
                        <ScheduleActions
                            currentUser={currentUser}
                            scheduleType={scheduleType}
                            currentFestival={currentFestival}
                            selectedDayIndex={selectedDayIndex}
                            setSelectedDayIndex={setSelectedDayIndex}
                            isCreator={isCreator}
                            heatmapType={heatmapType}
                            setHeatmapType={setHeatmapType}
                            blockSelectionsEmpty={Object.keys(blockSelections).length === 0}
                            localBlockSelectionsEmpty={Object.keys(localBlockSelections).length === 0}
                            clearBlockSelections={clearBlockSelections}
                            lineupData={lineupData}
                            blockSelections={blockSelections}
                            isRecsPanelOpen={isRecsPanelOpen}
                            setIsRecsPanelOpen={setIsRecsPanelOpen}
                            recs={recs}
                            setRecs={setRecs}
                        />
                        {schedule}
                    </>
                )
            }
            {bottomContainerData && (
                <BottomContainer
                    artist={bottomContainerData.artist}
                    members={bottomContainerData.members}
                    onClose={() => setBottomContainerData(null)}
                    heatmapType={heatmapType}
                />
            )}
        </div>
    )

}

export default FestivalSchedule