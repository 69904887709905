import React, { useState } from 'react'
import './Festivals.css'
import { useFavoriteFestivals } from '../../context/FavoriteFestivalContext'

// Components
import FestivalCard from './FestivalCard'
import RequestModal from '../General/RequestModal'

function Festivals({ currentUser, festivalData }) {
    const { favorites } = useFavoriteFestivals()

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    const festivalArray = Object.values(festivalData)
        .filter(festival => !festival.passed)
        .sort((a, b) => new Date(a.start) - new Date(b.start))

    const groupFestivalsByMonth = (festivals) => {
        const grouped = {}
    
        festivals.forEach(festival => {
            const month = new Date(festival.start).toLocaleString('default', { month: 'long', year: 'numeric' })
            if (!grouped[month]) {
                grouped[month] = []
            }
            if (!festival.hidden) grouped[month].push(festival)
        })
    
        return grouped
    }
    
    const groupedFestivals = groupFestivalsByMonth(festivalArray)

    return (
        <React.Fragment>
            <div className="festivals-wrapper">
                <div className="festivals-intro-text">
                    <p className="page-context-bar opacity50">Festivals</p>
                    <h1>🎉 Your next <span className="text-gradient">festival</span> awaits!</h1>
                    <div className="upcoming-text">
                        <h3>Upcoming festivals</h3>
                        <p className="upcoming-subtext">Festivals will appear below once the official lineup is released. Artists by day and set times are added as they become available.</p>
                        <div className="festivals-lmk">
                            <p className="upcoming-subtext">Don't see a festival here?</p><span className="btn-text-link" onClick={openRequestModal}>Let me know!</span>
                        </div>
                    </div>
                </div>
                <div className="upcoming-cards">
                    {Object.keys(groupedFestivals).map(month => (
                        <div key={month} className="festival-month-group">
                            <div className="festival-month-border" />
                            <div className="festival-month-header">{month}</div>
                            <div className="festival-month-cards">
                                {groupedFestivals[month].map(festival => (
                                    <FestivalCard
                                        key={festival.id}
                                        {...festival}
                                        isFavorite={favorites.includes(festival.id)}
                                        currentUser={currentUser}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <RequestModal 
                isOpen={isRequestModalOpen} 
                onRequestClose={closeRequestModal} 
                requestType={"Festival request"}
                currentUser={currentUser}
                origin={"Festivals"}
            />
        </React.Fragment>
    )
}

export default Festivals