import { encodeKey } from "../../utils/scheduleHelpers"
import { hexToRgbA } from "../../utils/helpers"

export const prepareScheduleData = (lineupData, blockSelections, customizationOptions) => {
    const daysMap = {}

    lineupData.forEach(([stageName, artists]) => {
        artists.forEach(artist => {
            const blockKey = `${stageName.toUpperCase()}-${artist.Start.toUpperCase()}-${artist.Day.toUpperCase()}-${artist.Artist.toUpperCase()}`
            const clickCount = blockSelections[encodeKey(blockKey)] || 0

            const day = artist.Day
            if (!daysMap[day]) {
                daysMap[day] = { day, artists: [] }
            }

            daysMap[day].artists.push({
                time: artist.Start,
                end: artist.End,
                artist: artist.Artist,
                stage: stageName,
                priority: clickCount,
            })
        })
    })

    // Sort each day's artists by start time
    Object.keys(daysMap).forEach(day => {
        daysMap[day].artists.sort((a, b) => new Date(`1970/01/01 ${a.time}`) - new Date(`1970/01/01 ${b.time}`))
    })

    return Object.values(daysMap)
}

export const splitLineupDataByDay = (lineupData, festival) => {
    const days = festival.daysAndTimes.map(dayInfo => dayInfo.day.toUpperCase())

    return days.map(day => {
        const dayData = lineupData.map(([stageName, artists]) => {
            const artistsForDay = artists.filter(artist => artist.Day.toUpperCase() === day)
            return artistsForDay.length > 0 ? { stageName, artists: artistsForDay } : null
        }).filter(stageData => stageData !== null)

        return { day, lineup: dayData }
    })
}

// Convert text to seed
export const textToSeed = (text, festivalName) => {
    const enrichedText = `${festivalName}${text}yo`
    let hash = 0
    for (let i = 0; i < enrichedText.length; i++) {
        const char = enrichedText.charCodeAt(i)
        hash = (hash << 3) - hash + char
        hash |= 0
    }
    return Math.abs(hash)
}

// Generate random color palette
const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
}

const generateRandomLightColor = () => {
    const r = Math.floor(Math.random() * 156) + 100
    const g = Math.floor(Math.random() * 156) + 100
    const b = Math.floor(Math.random() * 156) + 100
    return { r, g, b, hex: rgbToHex(r, g, b) }
}
const generateContrastingDarkColor = (lightColor) => {
    const generateDarkColor = () => {
        const r = Math.floor(Math.random() * 100)
        const g = Math.floor(Math.random() * 100)
        const b = Math.floor(Math.random() * 100)
        return { r, g, b, hex: rgbToHex(r, g, b) }
    }

    let darkColor = generateDarkColor()

    const adjustContrast = (color) => {
        const lum1 = getLuminance(lightColor)
        const lum2 = getLuminance(color)
        const contrastRatio = lum1 > lum2 ? (lum1 + 0.05) / (lum2 + 0.05) : (lum2 + 0.05) / (lum1 + 0.05)

        if (contrastRatio < 7.5) {
            color.r = Math.floor(color.r * 0.8)
            color.g = Math.floor(color.g * 0.8)
            color.b = Math.floor(color.b * 0.8)
            color.hex = rgbToHex(color.r, color.g, color.b)
        }

        return color
    }

    darkColor = adjustContrast(darkColor)

    return darkColor
}

const getLuminance = ({ r, g, b }) => {
    const a = [r, g, b].map(v => {
        v /= 255
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    })
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2]
}

export const generateRandomColorSet = () => {
    let mainColor = generateRandomLightColor()
    let bgColor = generateContrastingDarkColor(mainColor)

    return {
        name: "random",
        type: "duo",
        main: mainColor.hex,
        accent: mainColor.hex,
        favs: mainColor.hex,
        bg: bgColor.hex,
        bgGrad: `linear-gradient(180deg, ${hexToRgbA(bgColor.hex, 1)} 50%, ${hexToRgbA(bgColor.hex, 1)} 100%)`,
        textMain: mainColor.hex,
        textAccent: mainColor.hex,
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA(mainColor.hex, 0)} 0%, ${hexToRgbA(mainColor.hex, 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA(mainColor.hex, 0)} 0%, ${hexToRgbA(mainColor.hex, 1)} 100%)`,
        bgLogo: mainColor.hex
    }
}