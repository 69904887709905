import React, { useState, useEffect } from 'react'
import { useFestival } from '../../context/FestivalContext'
import './Nav.css'
import { ref, onValue, get, query, orderByChild, limitToLast, set } from 'firebase/database'

// Firebase
import { database } from '../../config/firebase-config'

// Components
import NavRow from './NavRow'
import NavTitle from './NavTitle'
import NavShortcuts from './NavShortcuts'
import NavAuth from './NavAuth'
import FestivalNav from './FestivalNav'

const Nav = React.memo(({ currentUser, festivalData, isNavOpen, setIsNavOpen }) => {
    const { currentFestival, userScheduleId } = useFestival()
    const [userGroups, setUserGroups] = useState([])
    const [userRecents, setUserRecents] = useState([])

    // -------------------- Fetch user's favorite festivals OR user groups --------------------
    useEffect(() => {
        if (currentUser && currentFestival) {
            setUserGroups([])
            setUserRecents([])
            
            const groupsRef = ref(database, `users/${currentUser.uid}/groups/${currentFestival.id}`)

            const unsubscribeGroups = onValue(groupsRef, (snapshot) => {
                const groupsData = snapshot.val() || {}
                const groupIds = Object.keys(groupsData)

                const groupListeners = groupIds.map(groupId => {
                    const groupRef = ref(database, `groups/${groupId}`)
                    return onValue(groupRef, (groupSnapshot) => {
                        setUserGroups(prevGroups => {
                            // Filter out the old version of the group
                            const updatedGroups = prevGroups.filter(group => group.id !== groupId)

                            const newGroups = [...updatedGroups, { id: groupId, ...groupSnapshot.val() }]
                            return newGroups.sort((a, b) => a.name.localeCompare(b.name))
                        })
                    })
                })

                return () => {
                    groupListeners.forEach(unsubscribe => unsubscribe())
                }
            })

            const recentsRef = ref(database, `users/${currentUser.uid}/recents/${currentFestival.id}`)
            const unsubscribeRecents = onValue(recentsRef, (snapshot) => {
                const recentsData = snapshot.val() || {}
                const recentEntries = Object.keys(recentsData).map(creatorId => ({
                    creatorId,
                    ...recentsData[creatorId]
                }))
                recentEntries.sort((a, b) => b.timestamp - a.timestamp)
                setUserRecents(recentEntries)
            })

            return () => {
                unsubscribeGroups()
                unsubscribeRecents()
            }
        }
    }, [currentUser, currentFestival])

    const handleNavClick = (shouldCloseNav) => {
        if (shouldCloseNav) {
            setIsNavOpen(false)
        }
    }

    // -------------- Check if Blasts page should have "new" indicator --------------
    const [hasNewAnnouncements, setHasNewAnnouncements] = useState(false)

    useEffect(() => {
        const checkNewAnnouncements = async () => {
            if (currentUser) {
                const lastVisitRef = ref(database, `users/${currentUser.uid}/lastAnnouncementVisit`)
                
                const unsubscribeLastVisit = onValue(lastVisitRef, (snapshot) => {
                    const lastVisit = snapshot.val()
                    
                    // Create a query for the announcements ordered by 'timestamp' and limited to the last 1
                    const announcementsQuery = query(ref(database, 'announcements'), orderByChild('timestamp'), limitToLast(1))
                    get(announcementsQuery).then((latestAnnouncementSnapshot) => {
                        const latestAnnouncement = latestAnnouncementSnapshot.val()
            
                        if (latestAnnouncement) {
                            const latestTimestamp = Object.values(latestAnnouncement)[0].timestamp
                            if (latestTimestamp > lastVisit) {
                                setHasNewAnnouncements(true)
                            } else {
                                setHasNewAnnouncements(false)
                            }
                        }
                    })
                })
    
                return () => {
                    unsubscribeLastVisit()
                }
            }
        }
    
        if (!currentFestival) checkNewAnnouncements()
    }, [currentUser])    

    // -------------- If currentFestival exists, return festival Nav --------------
    if (currentFestival) {
        return (
            <FestivalNav 
                currentUser={currentUser}
                currentFestival={currentFestival}
                userScheduleId={userScheduleId}
                userGroups={userGroups}
                userRecents={userRecents}
                isNavOpen={isNavOpen}
                handleNavClick={handleNavClick}
            />
        )
    }

    return (
        <div className={`nav-wrapper ${isNavOpen ? 'nav-m-open' : ''}`}>
            <div className="nav-top">
                <NavTitle currentUser={currentUser} handleNavClick={handleNavClick} />
                <NavRow 
                    rowType={"default"}
                    pageTitle={"Festivals"} 
                    hasIndicator={false}
                    toURL={"/"}
                    onClick={() => handleNavClick(true)}
                />
                <NavRow 
                    rowType={"default"}
                    pageTitle={"Past festivals"} 
                    hasIndicator={false}
                    toURL={"/pastfestivals"}
                    onClick={() => handleNavClick(true)}
                />
                <NavRow 
                    rowType={"default"}
                    pageTitle={"Blasts"} 
                    hasIndicator={hasNewAnnouncements}
                    toURL={"/blasts"}
                    onClick={() => handleNavClick(true)}
                />
                {/* <NavRow 
                    rowType={"default"}
                    pageTitle={"About"} 
                    toURL={"/about"}
                    onClick={() => handleNavClick(true)}
                /> */}
            </div>
            {currentUser ? (
                <NavShortcuts festivalData={festivalData} currentUser={currentUser} />
            ) : (
                <NavAuth currentUser={currentUser} />
            )}
        </div>
    )
})

export default Nav