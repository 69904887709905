import React, { useEffect, useState } from 'react'
import { useParams, Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useFestival } from '../../context/FestivalContext'

// Firebase
import { fetchAndProcessScheduleData } from '../../utils/scheduleHelpers'
import { getOrCreateScheduleForFestival } from '../../services/firebase/databaseService'

import './SingleFestival.css'
import '../Schedule/Schedule2.css'
import '../Schedule/Block2.css'
import '../Heatmap/Heatmap.css'

// Components
import DefaultFestival from './DefaultFestival'
import NewGroup2 from '../Groups/NewGroup2'
import JoinGroup from '../Groups/JoinGroup'
import Group from '../Groups/Group'
import FestivalSchedule from './FestivalSchedule'
import UserScheduleHeader from '../FestivalHeaders/UserScheduleHeader'
import HeatmapHeader from '../FestivalHeaders/HeatmapHeader'
import GroupHeader from '../FestivalHeaders/GroupHeader'

const FestivalRoute = React.memo(({ currentUser, festivalData, setIsNavHidden }) => {
    const { festivalId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { setFestival, currentFestival, userScheduleId, isLoading } = useFestival()

    const [lineupData, setLineupData] = useState([])
    const [isLoadingSchedule, setIsLoadingSchedule] = useState(false)

    // ---------------------------- SET FESTIVAL IN FESTIVALCONTEXT ----------------------------
    useEffect(() => {
        if (!festivalData[festivalId]) {
            navigate("/", { replace: true })
            return
        }

        setFestival(festivalId)
    }, [festivalId, festivalData, navigate, setFestival])

    // --------------------------------- GET FESTIVAL .TSV DATA ---------------------------------
    useEffect(() => {
        if (!currentFestival) return

        let lineupType = currentFestival.hasSetTimes ? "settimes" : "artists"
        const fetchScheduleData = async () => {
            try {
                const data = await fetchAndProcessScheduleData(currentFestival.id, lineupType)
                setLineupData(data)
            } catch (error) {
                console.error('Failed to fetch and process schedule data:', error)
            }
        }
        fetchScheduleData()
    }, [currentFestival])

    // --------------------------------------- REDIRECTS ---------------------------------------
    useEffect(() => {
        const handleNavigation = async () => {
            if (currentUser) {
                if (
                    location.pathname === `/${festivalId}/s/new` ||
                    location.pathname === `/${festivalId}/s/new/` ||
                    location.pathname === `/${festivalId}` ||
                    location.pathname === `/${festivalId}/`
                ) {
                    if (userScheduleId) {
                        navigate(`/${festivalId}/s/${userScheduleId}`, { replace: true })
                        setIsLoadingSchedule(false)
                    } else {
                        setIsLoadingSchedule(true)
                    }
                }
            } else if (!currentUser && !location.pathname.includes(`/s/`)) {
                if (!festivalData[festivalId]) {
                    navigate("/", { replace: true })
                    return
                }
    
                if (location.pathname.includes(`/g/`)) {
                    console.log("sup bro")
                } else {
                    navigate(`/${festivalId}/s/new`, { replace: true })
                }
            }
        }
    
        handleNavigation()
    }, [currentUser, userScheduleId, festivalId, location.pathname, navigate])
    

    // ------------------------------------- LOADING STATE -------------------------------------
    const [isContentVisible, setIsContentVisible] = useState(false)
    const [isLoadingVisible, setIsLoadingVisible] = useState(true)

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setIsLoadingVisible(false)
                setTimeout(() => {
                    setIsContentVisible(true)
                }, 200)
            }, 200) // Initial delay before starting the fade-out animation
        }
    }, [isLoading])
    
    if (isLoading) {
        return (
            <div className="loading-full">
                <div className="logo-circle" />
                <div className="loading-text">Loading festival...</div>
            </div>
        )
    }
    
    return (
        <>
            {isLoadingVisible && (
                <div className="loading-full loading-fade-out">
                    <div className="logo-circle" />
                    <div className="loading-text">Loading festival...</div>
                </div>
            )}
            {isContentVisible && (
                <Routes>
                    <Route 
                        path="s/:scheduleId" 
                        element={
                            <FestivalSchedule 
                                scheduleType={"userSchedule"}
                                currentUser={currentUser}
                                currentFestival={currentFestival}
                                festivalData={festivalData}
                                lineupData={lineupData}
                                headerComponent={UserScheduleHeader}
                                isLoadingSchedule={isLoadingSchedule}
                                setIsNavHidden={setIsNavHidden}
                            />
                        } 
                    />
                    <Route 
                        path="heatmap" 
                        element={
                            <FestivalSchedule 
                                scheduleType={"festivalHeatmap"}
                                currentUser={currentUser} 
                                currentFestival={currentFestival}
                                lineupData={lineupData}
                                headerComponent={HeatmapHeader}
                                setIsNavHidden={setIsNavHidden}
                            />
                        } 
                    />
                    <Route 
                        path="g/new" 
                        element={
                            <NewGroup2 
                                currentUser={currentUser} 
                                currentFestival={currentFestival} 
                            />
                        } 
                    />
                    <Route 
                        path="g/:groupId" 
                        element={
                            <FestivalSchedule 
                                scheduleType={"group"}
                                currentUser={currentUser} 
                                currentFestival={currentFestival} 
                                lineupData={lineupData}
                                headerComponent={GroupHeader}
                                setIsNavHidden={setIsNavHidden}
                            />
                        } 
                    />
                    <Route 
                        path="g/:groupId/join" 
                        element={
                            <JoinGroup 
                                currentUser={currentUser} 
                                currentFestival={currentFestival} 
                            />
                        } 
                    />
                </Routes>
            )}
        </>
    )
})

export default FestivalRoute