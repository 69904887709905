import React, { useState, useEffect } from 'react'
import { useUIContext } from '../../context/UIContext'
import { BLOCK_FONT_SIZE, COLUMN_WIDTH } from '../../utils/constants'
import { IconPlus, IconMinus, IconChevronDown, IconAdjustmentsHorizontal } from '@tabler/icons-react'
import { formatFestivalDates } from '../../utils/helpers'
import { 
    DEFAULT_BLOCK_FONT_SIZE_SET,
    DEFAULT_BLOCK_FONT_SIZE_NO_SET,
    DEFAULT_COLUMN_WIDTH_SET,
    DEFAULT_COLUMN_WIDTH_NO_SET,
    DEFAULT_COLUMN_WIDTH_SET_MOBILE
 } from '../../utils/constants'
 import { useFestival } from '../../context/FestivalContext'

// Firebase
import { ref, remove } from 'firebase/database'
import { database } from '../../config/firebase-config'

// Components
import NavTitle from './NavTitle'
import LineupTag from '../Tag/LineupTag'
import NavRow from './NavRow'
import NavAuth from './NavAuth'
import Nav from './Nav'

const FestivalNav = React.memo(({ 
    currentUser, 
    currentFestival, 
    userScheduleId, 
    userGroups, 
    userRecents, 
    isNavOpen, 
    handleNavClick,
    dismissSchedulePopover,
    isSchedulePopoverDismissed
}) => {
    const {
        blockFontSizeSet,
        blockFontSizeNoSet,
        setBlockFontSizeSet,
        setBlockFontSizeNoSet,
        columnWidthSet,
        columnWidthNoSet,
        setColumnWidthSet,
        setColumnWidthNoSet,
        heightScaleSet,
        heightScaleNoSet,
        setHeightScaleSet,
        setHeightScaleNoSet,
        showDisplayOptions,
        setShowDisplayOptions,
        groupAvatarSize,
        setGroupAvatarSize,
        showGroupAvatars,
        setShowGroupAvatars,
        showEndTime,
        setShowEndTime
    } = useUIContext()

    const { isLoading } = useFestival()

    // ----------------------- RESET DISPLAY OPTIONS -----------------------
    const resetCustomAttributes = (event) => {
        event.preventDefault()
        event.stopPropagation()
        
        if (currentFestival.hasSetTimes) {
            setBlockFontSizeSet(DEFAULT_BLOCK_FONT_SIZE_SET)
            setColumnWidthSet(window.innerWidth <= 1000 ? DEFAULT_COLUMN_WIDTH_SET_MOBILE : DEFAULT_COLUMN_WIDTH_SET)
            setHeightScaleSet(1)
        } else {
            setBlockFontSizeNoSet(DEFAULT_BLOCK_FONT_SIZE_NO_SET)
            setColumnWidthNoSet(DEFAULT_COLUMN_WIDTH_NO_SET)
            setHeightScaleNoSet(1)
        }

        setGroupAvatarSize(18)
    }

    let minSizeReached, maxSizeReached = false

    if (currentFestival.hasSetTimes) {
        minSizeReached = blockFontSizeSet === 5
        maxSizeReached = blockFontSizeSet === 20
    } else {
        minSizeReached = blockFontSizeNoSet === 5
        maxSizeReached = blockFontSizeNoSet === 20
    }
    
    const incrementBlockFontSize = () => {
        if (!maxSizeReached) {
            if (currentFestival.hasSetTimes) {
                setBlockFontSizeSet(blockFontSizeSet + 1)
            } else {
                setBlockFontSizeNoSet(blockFontSizeNoSet + 1)
            }
        }
    }
    const decrementBlockFontSize = () => {
        if (!minSizeReached) {
            if (currentFestival.hasSetTimes) {
                setBlockFontSizeSet(blockFontSizeSet - 1)
            } else {
                setBlockFontSizeNoSet(blockFontSizeNoSet - 1)
            }
        }
    }

    const handleRemoveRecent = async (creatorId) => {
        const recentRef = ref(database, `users/${currentUser.uid}/recents/${currentFestival.id}/${creatorId}`)
        await remove(recentRef)
    }
    const handleClearAllRecents = async () => {
        const recentsRef = ref(database, `users/${currentUser.uid}/recents/${currentFestival.id}`)
        await remove(recentsRef)
    }

    const [isContentVisible, setIsContentVisible] = useState(false)
    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setIsContentVisible(true)
            }, 400)
        }
    }, [isLoading])

    if (!currentUser) {
        return (
            <>
                {isContentVisible && (
                    <div className={`nav-wrapper fade-in-content-no-scale ${isNavOpen ? 'nav-m-open' : ''}`}>
                        <div className="nav-single-unauth">
                            <NavTitle currentUser={currentUser} handleNavClick={handleNavClick} />
                            <div className="nav-festival-info-wrapper">
                                <img className="nav-festival-info-image" src={currentFestival.imageUrls.low} />
                                <div className="nav-festival-info-text">
                                    <h3 className="nav-festival-info-header">{currentFestival.name}</h3>
                                    <div className="nav-f-i-subtext-container">
                                        <div className="nav-f-i-subtext-item">{formatFestivalDates(currentFestival.start, currentFestival.end)}</div>
                                        <div className="nav-f-i-subtext-item">•</div>
                                        <div className="nav-f-i-subtext-item">{currentFestival.location}</div>
                                    </div>
                                    <div className="f-tags" style={{ marginTop: "4px" }}>
                                        <LineupTag text="Lineup" status={currentFestival.hasLineup} complete={currentFestival.hasSetTimes} inNav={true} />
                                        <LineupTag text="By day" status={currentFestival.hasDay} complete={currentFestival.hasSetTimes} inNav={true} />
                                        <LineupTag text="Set times" status={currentFestival.hasSetTimes} complete={currentFestival.hasSetTimes} inNav={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="nav-scroll-wrapper">
                                <div className="nav-back-wrapper">
                                    <NavRow 
                                        rowType={"default"}
                                        pageTitle={"Back to Festivals"} 
                                        toURL={"/"} 
                                    />
                                </div>
                                <div className="nav-festival-sections">
                                    <div className="nav-section">
                                        <h5 className="nav-section-header">General</h5>
                                        <NavRow 
                                            rowType={"default"}
                                            pageTitle={"My schedule"} 
                                            toURL={`/${currentFestival.id}/s/new`} 
                                            festivalId={currentFestival.id} 
                                            onClick={() => handleNavClick(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavAuth />
                    </div>
                )}
            </>
        )
    }

    let displayOptionsHeadingText = "General (No set times)"
    if (currentFestival.hasSetTimes) displayOptionsHeadingText = "General (Set time schedules)"

    let displayOptionsHeight = 236
    if (currentFestival.hasSetTimes) displayOptionsHeight += 36
    if (showGroupAvatars) displayOptionsHeight += 32
    if (!showDisplayOptions) displayOptionsHeight = 52

    return (
        <>
            {isContentVisible && (
                <div className={`nav-wrapper fade-in-content-no-scale ${isNavOpen ? 'nav-m-open' : ''}`}>
                    <div className="nav-single">
                        <NavTitle currentUser={currentUser} handleNavClick={handleNavClick}/>
                        <div className="nav-festival-info-wrapper">
                            <img className="nav-festival-info-image" src={currentFestival.imageUrls.low} />
                            <div className="nav-festival-info-text">
                                <h3 className="nav-festival-info-header">{currentFestival.name}</h3>
                                <div className="nav-f-i-subtext-container">
                                    <div className="nav-f-i-subtext-item">{formatFestivalDates(currentFestival.start, currentFestival.end)}</div>
                                    <div className="nav-f-i-subtext-item">•</div>
                                    <div className="nav-f-i-subtext-item">{currentFestival.location}</div>
                                </div>
                                <div className="f-tags" style={{ marginTop: "4px" }}>
                                    <LineupTag text="Lineup" status={currentFestival.hasLineup} complete={currentFestival.hasSetTimes} inNav={true} />
                                    <LineupTag text="By day" status={currentFestival.hasDay} complete={currentFestival.hasSetTimes} inNav={true} />
                                    <LineupTag text="Set times" status={currentFestival.hasSetTimes} complete={currentFestival.hasSetTimes} inNav={true} />
                                </div>
                            </div>
                        </div>
                        <div className="nav-scroll-wrapper">
                            <div className="nav-back-wrapper">
                                <NavRow 
                                    rowType={"default"}
                                    pageTitle={"Back to Festivals"} 
                                    toURL={"/"} 
                                />
                            </div>
                            <div className="nav-festival-sections">
                                <div className="nav-section">
                                    <h5 className="nav-section-header">General</h5>
                                    <NavRow 
                                        rowType={"default"}
                                        pageTitle={"My schedule"} 
                                        toURL={`/${currentFestival.id}/s/${userScheduleId}`} 
                                        festivalId={currentFestival.id} 
                                        onClick={() => handleNavClick(true)}
                                        showPopover={!isSchedulePopoverDismissed}
                                        dismissPopover={dismissSchedulePopover}
                                    />
                                    <NavRow 
                                        rowType={"default"}
                                        pageTitle={"Festival heatmap"} 
                                        toURL={`/${currentFestival.id}/heatmap`} 
                                        onClick={() => handleNavClick(true)}
                                    />
                                </div>
                                <div className="nav-section">
                                    <h5 className="nav-section-header">Groups</h5>
                                    {userGroups.map(group => (
                                        <NavRow
                                            rowType={"group"}
                                            key={group.id}
                                            memberCount={group.memberCount}
                                            pageTitle={group.name}
                                            toURL={`/${currentFestival.id}/g/${group.id}`}
                                            picture={group.picture}
                                            onClick={() => handleNavClick(true)}
                                        />
                                    ))}
                                    <NavRow 
                                        rowType={"new"}
                                        pageTitle={"+ Create new group"} 
                                        toURL={`/${currentFestival.id}/g/new`} 
                                        onClick={() => handleNavClick(true)}
                                    />
                                </div>
                                <div className="nav-section nav-section-last">
                                    <div className="nav-section-header-with-action">
                                        <h5>Recent schedules</h5>
                                        {userRecents.length !== 0 && (
                                            <div className="btn-with-icon nav-section-header-action" onClick={handleClearAllRecents}>Clear all</div>
                                        )}
                                    </div>
                                    {userRecents.map(recent => {
                                        return (
                                            <NavRow
                                                rowType={"recent"}
                                                key={recent.creatorId}
                                                pageTitle={recent.displayName}
                                                toURL={`/${currentFestival.id}/s/${recent.scheduleId}`}
                                                picture={recent.picture}
                                                onRemove={() => handleRemoveRecent(recent.creatorId)}
                                                onClick={() => handleNavClick(true)}
                                            />
                                        )
                                    })}
                                    {userRecents.length === 0 && (
                                        <div className="nav-section-empty-text"><i>Schedules you've viewed will appear here</i></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        className={`display-options-wrapper`}
                        style={{ height: displayOptionsHeight}}
                    >
                        <div className="display-options-header" onClick={() => setShowDisplayOptions(!showDisplayOptions)}>
                            <div className="display-options-header-wrapper">
                                <IconAdjustmentsHorizontal size={20} strokeWidth={1.5} />
                                <div className="display-options-header-text">Schedule display</div>
                            </div>
                            <div className="display-options-header-actions">
                                <p className="btn-with-icon btn-nav-reset" onClick={(event) => resetCustomAttributes(event)} title="Reset to default">Reset</p>
                                <IconChevronDown className={`icon-display-options-chevron ${showDisplayOptions ? '' : 'rotated'}`} size={20} strokeWidth={1.5}/>
                            </div>
                        </div>
                        <div className="nav-all-settings-wrapper">
                            <h5>{displayOptionsHeadingText}</h5>
                            <div className="nav-setting-with-reset"> 
                                <div className="nav-setting-wrapper nav-setting-stepper">
                                    <div className="nav-settings-label" htmlFor="font-size">Font size</div>
                                    <IconMinus 
                                        onClick={decrementBlockFontSize}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${minSizeReached ? "disabled" : ""}`}
                                        title="Decrease font size"
                                    />
                                    <p className="nav-font-size-container">{currentFestival.hasSetTimes ? blockFontSizeSet : blockFontSizeNoSet}</p>
                                    <IconPlus 
                                        onClick={incrementBlockFontSize}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${maxSizeReached ? "disabled" : ""}`}
                                        title="Increase font size"
                                    />
                                </div>
                            </div>
                            <div className="nav-setting-wrapper">
                                <div className="nav-settings-label">Block width</div>
                                <input
                                    id="width-slider"
                                    type="range"
                                    value={currentFestival.hasSetTimes ? columnWidthSet : columnWidthNoSet}
                                    min="64"
                                    max="360"
                                    step="1"
                                    onChange={e => {
                                        if (currentFestival.hasSetTimes) {
                                            setColumnWidthSet(Number(e.target.value))
                                        } else {
                                            setColumnWidthNoSet(Number(e.target.value))
                                        }
                                    }}
                                    style={{ flex: 1 }}
                                />
                            </div>
                            {/* <div className="nav-setting-wrapper">
                                <div className="nav-settings-label">Block height</div>
                                <input
                                    id="height-slider"
                                    type="range"
                                    value={currentFestival.hasSetTimes ? heightScaleSet : heightScaleNoSet}
                                    min="0.1"
                                    max="2.5"
                                    step="0.01"
                                    onChange={e => {
                                        if (currentFestival.hasSetTimes) {
                                            setHeightScaleSet(Number(e.target.value))
                                        } else {
                                            setHeightScaleNoSet(Number(e.target.value))
                                        }
                                    }}
                                    style={{ flex: 1 }}
                                />
                            </div> */}
                            {currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper nav-toggle-margin">
                                    <div 
                                        className={`nav-toggle-wrapper ${!currentFestival.hasSetTimes ? 'toggle-disabled' : ''}`} 
                                        onClick={() => { if (currentFestival.hasSetTimes) setShowEndTime(prev => !prev) }}
                                    >
                                        <label className="switch">
                                            <input 
                                                type="checkbox" 
                                                checked={showEndTime} 
                                                onChange={() => setShowEndTime(prev => !prev)} 
                                                disabled={!currentFestival.hasSetTimes}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <div className={`nav-settings-toggle-label`}>Show end times</div>
                                    </div>
                                </div>
                            )}
                            <h5 className="nav-setting-section">Groups</h5>
                            <div className="nav-setting-wrapper">
                                <div className="nav-toggle-wrapper" onClick={() => setShowGroupAvatars(prev => !prev)}>
                                    <label className="switch">
                                        <input 
                                            type="checkbox" 
                                            checked={showGroupAvatars} 
                                            onChange={() => setShowGroupAvatars(prev => !prev)} 
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="nav-settings-toggle-label">Show avatars in group heatmap</div>
                                </div>
                            </div>
                            {showGroupAvatars && (
                                <div className="nav-setting-wrapper">
                                    <div className="nav-settings-label">Avatar size</div>
                                    <input
                                        id="height-slider"
                                        type="range"
                                        value={groupAvatarSize}
                                        min="12"
                                        max="32"
                                        step="1"
                                        onChange={e => setGroupAvatarSize(Number(e.target.value))}
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

export default FestivalNav