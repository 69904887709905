import React from 'react'
import ColorSetOption from './ColorSetOption'
import './ExportColorSet.css'
import { hexToRgbA } from '../../utils/helpers'
import { colorSpecs } from './ImageConstants'

const ExportColorSet = ({ selectedColorStyle, setColorStyle, isShuffleActive, setIsShuffleActive, isRandomActive, setIsRandomActive }) => {
    return (
        <div className="color-set-options-wrapper">
            <ColorSetOption 
                key={"shuffle"}
                isSelected={isShuffleActive}
                colorSpec={{ name: "shuffle" }}
                setColorStyle={() => {
                    setIsShuffleActive(true);
                    setIsRandomActive(false);
                }}
            />
            {colorSpecs.map(colorSpec => (
                <ColorSetOption 
                    key={colorSpec.name}
                    isSelected={selectedColorStyle.name === colorSpec.name && !isShuffleActive && !isRandomActive}
                    colorSpec={colorSpec}
                    setColorStyle={() => {
                        setColorStyle(colorSpec);
                        setIsShuffleActive(false);
                        setIsRandomActive(false);
                    }}
                />
            ))}
            <ColorSetOption 
                key={"random"}
                isSelected={isRandomActive}
                colorSpec={{ name: "random" }}
                setColorStyle={() => {
                    setIsRandomActive(true);
                    setIsShuffleActive(false);
                }}
            />
        </div>
    )
}


export default ExportColorSet