import React from 'react'
import { useUIContext } from '../../context/UIContext'
import { hexToRgbA } from '../../utils/helpers'

const Timeline = ({ timelineHeight, start, end, numStages, isExport, exportWidth, colorStyle }) => {
    const { blockFontSizeSet, columnWidthSet } = useUIContext()

    let dayStart = start

    const times = []
    let currentHour = dayStart
    let slotCount = 0

    // Calculate the number of time slots
    do {
        const period = currentHour < 12 || currentHour === 24 ? 'AM' : 'PM'
        const formattedHour = (currentHour % 12 === 0) ? 12 : currentHour % 12
        times.push({
            time: `${formattedHour}`,
            period: period
        })
        
        currentHour = (currentHour + 1) % 24
        slotCount++
    } while (currentHour !== (end) % 24)

    const slotHeight = timelineHeight / (slotCount)

    return (
        <div 
            className={`${isExport ? 'x-timeline' : 'timeline'}`}
            style={{ 
                height: `${timelineHeight}px`, 
                marginTop: `0`,
                minWidth: 17,
                maxWidth: 17
            }}
        >
            {times.map((time, index) => {
                let timeColor = "#FFFFFF"
                let dividerColor = "rgba(255,255,255,0.5)"

                if (time.period === "AM") {
                    timeColor = "#FF9E16"
                    dividerColor = "rgba(255,158,22,0.5)"
                    if (isExport) {
                        timeColor = colorStyle.textMain
                        dividerColor = "rgba(255,255,255,0.5)"
                    }
                }

                let timeFontSize = blockFontSizeSet
                let timeDividerWidth = numStages * columnWidthSet
                if (isExport) {
                    timeFontSize = 24
                    timeDividerWidth = exportWidth - 17
                }

                return (
                    <div key={index}>
                        <div className="time-slot" style={{ 
                            height: `${slotHeight}px`,
                            boxShadow: `${isExport ? '' : `inset 0 1px 0 ${dividerColor}`}`,
                            borderTop: `${isExport ? `1px solid ${dividerColor}` : ''}`,
                            boxSizing: `${isExport ? `border-box` : ''}`,
                        }}>
                            <div 
                                className='time-label'
                                style={{
                                    fontSize: timeFontSize,
                                    color: timeColor
                                }}
                            >{time.time}</div>
                        </div>
                        <div 
                            className={`${isExport ? 'x-time-divider' : 'time-divider'}`}
                            style={{ 
                                height: 1,
                                boxShadow: `${isExport ? '' : `inset 0 1px 0 ${dividerColor}`}`,
                                background: `${isExport ? hexToRgbA(colorStyle.main, 0.2) : ''}`,
                                width: timeDividerWidth
                            }}
                        />
                    </div>
                )
            })}
        </div>
    )
}


export default Timeline
