import { auth, database, storage } from './'
import { onValue, ref, off, get, set, push, update, increment, remove } from 'firebase/database'
import { ref as storageRef, uploadString, getDownloadURL, deleteObject } from "firebase/storage"

// Type: GET
// Details: Fetch all users' data, filter out users without createdAt, and sort by createdAt
// Return: Array of user data
export const fetchAllUsers = async () => {
    const usersRef = ref(database, 'users');
    try {
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
            const users = snapshot.val();

            const groupsSnapshot = await get(ref(database, 'groups'));
            const groups = groupsSnapshot.exists() ? groupsSnapshot.val() : {};

            const schedulesSnapshot = await get(ref(database, 'schedules'));
            const schedules = schedulesSnapshot.exists() ? schedulesSnapshot.val() : {};

            let unknownUserCount = 0;

            // Filter out users without createdAt and test accounts
            const filteredUsers = Object.keys(users)
                .map(userId => {
                    const user = { ...users[userId], uid: userId };
                    if (!user.displayName) {
                        unknownUserCount += 1;
                    }
                    return user;
                })
                .filter(user => user.createdAt && !/^jt\d+$/.test(user.displayName))
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            // Map user groups and schedules
            for (let user of filteredUsers) {
                const userGroups = user.groups ? Object.keys(user.groups).flatMap(festivalId => 
                    Object.keys(user.groups[festivalId]).map(groupId => ({
                        festivalId,
                        groupId
                    }))
                ) : [];
                user.groupsDetails = userGroups.map(({ festivalId, groupId }) => {
                    const group = groups[groupId];
                    if (!group) return null;

                    const members = group.members ? Object.keys(group.members) : [];
                    const memberNames = members.map(memberId => users[memberId]?.displayName || 'Unknown');

                    return {
                        groupId,
                        festivalId,
                        groupName: group.name,
                        memberNames
                    };
                }).filter(Boolean); // Filter out null values

                // Calculate user schedule selections
                const userSchedules = user.schedules ? Object.keys(user.schedules).map(festivalId => {
                    const scheduleId = user.schedules[festivalId];
                    const schedule = schedules[scheduleId];

                    if (schedule && schedule.selections) {
                        const selectionCounts = { total: 0, count1: 0, count2: 0 };
                        Object.values(schedule.selections).forEach(count => {
                            if (count === 1) selectionCounts.count1 += 1;
                            if (count === 2) selectionCounts.count2 += 1;
                            selectionCounts.total += 1;
                        });

                        return {
                            festivalId,
                            scheduleId,
                            selectionCounts
                        };
                    }

                    return null;
                }).filter(Boolean) : [];

                user.scheduleDetails = userSchedules;

                // Calculate total number of recent schedules viewed
                const userRecents = user.recents ? Object.values(user.recents) : [];
                let totalRecents = 0;

                userRecents.forEach(festivalRecents => {
                    totalRecents += Object.keys(festivalRecents).length;
                });

                user.totalRecents = totalRecents;
            }

            return { users: filteredUsers, unknownUserCount };
        } else {
            return { users: [], unknownUserCount: 0 };
        }
    } catch (error) {
        console.error('Failed to fetch users:', error);
        throw error;
    }
};

export const fetchAdminFestivalData = async () => {
    const festivalsRef = ref(database, 'festivals');
    try {
        const snapshot = await get(festivalsRef);
        if (snapshot.exists()) {
            const festivals = snapshot.val();
            let festivalData = {};

            const schedulesSnapshot = await get(ref(database, 'schedules'));
            const schedules = schedulesSnapshot.exists() ? schedulesSnapshot.val() : {};

            const groupsSnapshot = await get(ref(database, 'groups'));
            const groups = groupsSnapshot.exists() ? groupsSnapshot.val() : {};

            const usersSnapshot = await get(ref(database, 'users'));
            const users = usersSnapshot.exists() ? usersSnapshot.val() : {};

            Object.keys(festivals).forEach(festivalId => {
                const { name, location, start, end, imageUrls } = festivals[festivalId];

                const festivalSchedules = Object.keys(schedules).filter(scheduleId => schedules[scheduleId].festival === festivalId);
                const schedulesWithSelections = festivalSchedules.filter(scheduleId => schedules[scheduleId].selections && Object.keys(schedules[scheduleId].selections).length > 0);
                
                const festivalGroups = Object.keys(groups).filter(groupId => groups[groupId].festival === festivalId);
                
                const festivalFavorites = Object.keys(users).reduce((count, userId) => {
                    const userFavorites = users[userId].favorites || {};
                    return count + (userFavorites[festivalId] ? 1 : 0);
                }, 0);

                festivalData[festivalId] = {
                    id: festivalId,
                    name,
                    location,
                    start,
                    end,
                    imageUrls,
                    numSchedules: festivalSchedules.length,
                    numSchedulesWithSelections: schedulesWithSelections.length,
                    numGroups: festivalGroups.length,
                    numFavorites: festivalFavorites
                };
            });

            return Object.values(festivalData).sort((a, b) => new Date(a.start) - new Date(b.start));
        } else {
            console.log('No festivals found.');
            return {};
        }
    } catch (error) {
        console.error('Failed to fetch festivals:', error);
        throw error;
    }
};

