import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider } from "firebase/auth"
import { getDatabase } from "firebase/database"
import { getStorage } from "firebase/storage"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAS1Zc8q3FRu8HK35PwGwSRWmegOFueEF0",
  authDomain: "festiplannr.firebaseapp.com",
  projectId: "festiplannr",
  storageBucket: "festiplannr.appspot.com",
  messagingSenderId: "476620399785",
  appId: "1:476620399785:web:cf36a152b97a35081b75b8",
  measurementId: "G-YTR0B4XCN7",
  databaseURL: "https://festiplannr-default-rtdb.firebaseio.com/"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const database = getDatabase(app)
const storage = getStorage(app)
const provider = new GoogleAuthProvider()

export { 
    analytics,
    auth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateProfile,
    database,
    storage,
    provider
}