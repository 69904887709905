import { hexToRgbA } from "../../utils/helpers"

// General
export const IOS_TIME_HEIGHT = 284
export const IMG_PADDING = 32
export const IOS_WIDGET_HEIGHT = 70

// Timeline
export const TIMELINE_WIDTH = 20

// Seed phrase row
export const SEED_WRAPPER_PADDING_BOTTOM = 12
export const SEED_PHRASE_FONT_SIZE = 40
export const SEED_WRAPPER_HEIGHT = SEED_WRAPPER_PADDING_BOTTOM + SEED_PHRASE_FONT_SIZE

// Header row
export const HEADER_PADDING_BOTTOM = 16
export const HEADER_MARGIN_BOTTOM = 16
export const HEADER_FONT_SIZE = 30
export const HEADER_LINE_HEIGHT = 36
export const HEADER_HEIGHT = HEADER_PADDING_BOTTOM + HEADER_MARGIN_BOTTOM + HEADER_LINE_HEIGHT

// Decorators
export const DECORATOR_SIZE = 32
export const DECORATOR_TOP_DISTANCE = 172
export const DECORATOR_BOTTOM_DISTANCE = 80
export const DECORATOR_LR_MARGIN = 16
export const DECORATOR_GAP = 36
export const DECORATOR_WIDTH = `calc(100% - ${DECORATOR_LR_MARGIN * 2}px)`

// Color palettes
export const defaultColorStyle = {
    name: "FestiPlannr",
    type: "duo",
    main: "#FFFFFF",
    accent: "#0285FF",
    favs: "#FF007A",
    bg: "#111421",
    textMain: "#FFFFFF",
    textAccent: "#FFFFFF",
    bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#FFA471", 0)} 0%, ${hexToRgbA("#FF52AB", 0.5)} 50%, ${hexToRgbA("#00A3FF", 1)} 100%)`,
    bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#FFA471", 0)} 0%, ${hexToRgbA("#FF52AB", 0.5)} 50%, ${hexToRgbA("#00A3FF", 1)} 100%)`,
    bgLogo: "linear-gradient(135deg, #FFA471, #FFA471 14.64%, #FF52AB 50%, #00A3FF 85.36%, #00A3FF)"
}

export const colorSpecs = [
    {
        name: "FestiPlannr",
        type: "duo",
        main: "#FFFFFF",
        accent: "#0285FF",
        favs: "#FF007A",
        bg: "#111421",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#111421", 1)} 50%, ${hexToRgbA("#080a11", 1)} 100%)`,
        textMain: "#FFFFFF",
        textAccent: "#FFFFFF",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#FFA471", 0)} 0%, ${hexToRgbA("#FF52AB", 0.5)} 50%, ${hexToRgbA("#00A3FF", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#FFA471", 0)} 0%, ${hexToRgbA("#FF52AB", 0.5)} 50%, ${hexToRgbA("#00A3FF", 1)} 100%)`,
        bgLogo: "linear-gradient(135deg, #FFA471, #FFA471 14.64%, #FF52AB 50%, #00A3FF 85.36%, #00A3FF)"
    },
    {
        name: "Peachy keen",
        type: "duo",
        main: "#ffbabe",
        bg: "#364488",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#364488", 1)} 50%, ${hexToRgbA("#222f66", 1)} 100%)`,
        accent: "#ffbabe",
        favs: "#ff5070",
        textMain: "#ffbabe",
        textAccent: "#ffbabe",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#ffbabe", 0)} 0%, ${hexToRgbA("#ffbabe", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#ffbabe", 0)} 0%, ${hexToRgbA("#ffbabe", 1)} 100%)`,
        bgLogo: "#fd999e"
    },
    {
        name: "Beachy sheen",
        type: "duo",
        main: "#FFC061",
        bg: "#144368",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#144368", 1)} 50%, ${hexToRgbA("#0d2e45", 1)} 100%)`,
        accent: "#990011",
        favs: "#ff9901",
        textMain: "#FFC061",
        textAccent: "#ff9901",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#FFC061", 0)} 0%, ${hexToRgbA("#FFC061", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#FFC061", 0)} 0%, ${hexToRgbA("#FFC061", 1)} 100%)`,
        bgLogo: "#ff9901"
    },
    {
        name: "Xeachy xeen",
        type: "duo",
        main: "#ffa4eb",
        bg: "#240D62",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#2b1667", 1)} 50%, ${hexToRgbA("#170940", 1)} 100%)`,
        accent: "#990011",
        favs: "#ff52d9",
        textMain: "#ffa4eb",
        textAccent: "#ffa4eb",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#ffa4eb", 0)} 0%, ${hexToRgbA("#ffa4eb", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#ffa4eb", 0)} 0%, ${hexToRgbA("#ffa4eb", 1)} 100%)`,
        bgLogo: "#ff52d9"
    },
    {
        name: "lavender orgy",
        type: "duo",
        main: "#B98DE8",
        bg: "#160329",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#160329", 1)} 50%, ${hexToRgbA("#0d0218", 1)} 100%)`,
        accent: "#990011",
        favs: "#B98DE8",
        textMain: "#B98DE8",
        textAccent: "#B98DE8",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#B98DE8", 0)} 0%, ${hexToRgbA("#B98DE8", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#B98DE8", 0)} 0%, ${hexToRgbA("#B98DE8", 1)} 100%)`,
        bgLogo: "#B98DE8"
    },
    {
        name: "BLOOD ALONE MOVES THE WHEELS OF HISTORY",
        type: "duo",
        main: "#ffbc8d",
        bg: "#52082e",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#52082e", 1)} 50%, ${hexToRgbA("#34051d", 1)} 100%)`,
        accent: "#990011",
        favs: "#f89d58",
        textMain: "#ffbc8d",
        textAccent: "#ffbc8d",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#ffbc8d", 0)} 0%, ${hexToRgbA("#ffbc8d", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#ffbc8d", 0)} 0%, ${hexToRgbA("#ffbc8d", 1)} 100%)`,
        bgLogo: "#f89d58"
    },
    {
        name: "Orange 😎👍",
        type: "duo",
        main: "#F17D7C",
        bg: "#080D3A",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#080D3A", 1)} 50%, ${hexToRgbA("#050a2f", 1)} 100%)`,
        accent: "#F17D7C",
        favs: "#F17D7C",
        textMain: "#F17D7C",
        textAccent: "#F17D7C",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#F17D7C", 0)} 0%, ${hexToRgbA("#F17D7C", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#F17D7C", 0)} 0%, ${hexToRgbA("#F17D7C", 1)} 100%)`,
        bgLogo: "#ee6f6d"
    },
    {
        name: "kgjhsf;l",
        type: "duo",
        main: "#73F4C2",
        bg: "#151219",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#151219", 1)} 50%, ${hexToRgbA("#120f15", 1)} 100%)`,
        accent: "#73F4C2",
        favs: "#73F4C2",
        textMain: "#73F4C2",
        textAccent: "#73F4C2",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#73F4C2", 0)} 0%, ${hexToRgbA("#73F4C2", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#73F4C2", 0)} 0%, ${hexToRgbA("#73F4C2", 1)} 100%)`,
        bgLogo: "#73F4C2"
    },
    {
        name: "imo this looks dece",
        type: "duo",
        main: "#ddaaff",
        bg: "#082931",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#0c2e36", 1)} 50%, ${hexToRgbA("#08242a", 1)} 100%)`,
        accent: "#990011",
        favs: "#c979ff",
        textMain: "#ddaaff",
        textAccent: "#ddaaff",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#ddaaff", 0)} 0%, ${hexToRgbA("#ddaaff", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#ddaaff", 0)} 0%, ${hexToRgbA("#ddaaff", 1)} 100%)`,
        bgLogo: "#c979ff"
    },
    {
        name: "The Khai Special",
        type: "duo",
        main: "#F1E6D6",
        bg: "#6DA34D",
        bgGrad:`linear-gradient(180deg, ${hexToRgbA("#6DA34D", 1)} 50%, ${hexToRgbA("#517d37", 1)} 100%)`,
        accent: "#990011",
        favs: "#F1E6D6",
        textMain: "#F1E6D6",
        textAccent: "#F1E6D6",
        bgDividerL: `linear-gradient(90deg, ${hexToRgbA("#F1E6D6", 0)} 0%, ${hexToRgbA("#F1E6D6", 1)} 100%)`,
        bgDividerR: `linear-gradient(-90deg, ${hexToRgbA("#F1E6D6", 0)} 0%, ${hexToRgbA("#F1E6D6", 1)} 100%)`,
        bgLogo: "#F1E6D6"
    },
]