import React, { useState, useEffect, useRef } from 'react'
import { useUIContext } from '../../context/UIContext'
import { IconHeartFilled } from '@tabler/icons-react'
import { formatTime, getBlockHeightAndPosition, hexToRgbA } from '../../utils/helpers'
import './Block2.css'

import AvatarSet from '../AvatarSet/AvatarSet'

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    
    return width
}

function Block2({ 
    scheduleType,
    festival, 
    isSetTime, 
    stageColor, 
    selectedDayIndex, 
    artist, 
    timelineHeight, 
    clickCount, 
    onMyScheduleBlockClick,
    isCreator,
    heatmapData, 
    maxTotalVotes, 
    maxTotalMustSee, 
    isHeatmap, 
    heatmapType, 
    heatmapColor,
    membersData,
    handleGroupBlockClick,
    groupSelected,
    isExport,
    stageWidth,
    colorStyle,
    exportDayIndex
}) {
    const { 
        blockFontSizeSet, 
        columnWidthSet, 
        heightScaleSet, 
        blockFontSizeNoSet, 
        columnWidthNoSet, 
        heightScaleNoSet, 
        showGroupAvatars, 
        showEndTime,
        numBlocksMobile
    } = useUIContext()
    const [showHeart, setShowHeart] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const blockRef = useRef(null)

    const windowWidth = useWindowWidth()

    // ------------------------ Set timeout for heart animation ------------------------
    useEffect(() => {
        if (clickCount === 2) {
            const timer = setTimeout(() => { setShowHeart(true) }, 150)
            return () => clearTimeout(timer)
        } else { setShowHeart(false) }
    }, [clickCount])

    // ------------------------ Handle mouse enter/leave ------------------------
    const handleMouseEnter = () => { setIsHovered(true) }
    const handleMouseLeave = () => { setIsHovered(false) }

    // -------------------------------- Set Block class --------------------------------
    const getBlockClass = () => {
        const baseClass = isSetTime ? 'block2-set' : 'block2'
        if (clickCount === 0) return baseClass
        if (clickCount === 1) return `${baseClass} clicked-once`
        if (clickCount === 2) return `${baseClass} clicked-twice`
        return baseClass
    }

    // -------------------------------- Set Artist class --------------------------------
    const getArtistClass = () => {
        if (clickCount === 0) return 'block2-artist'
        if (clickCount === 1) return 'block2-artist-once'
        if (clickCount === 2) return 'block2-artist-twice'
    }

    function interpolateColor(color1, color2, factor) {
        const result = color1.slice()
        for (let i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - result[i]))
        }
        return result
    }
    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16)
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255]
    }
    function rgbToHex(rgb) {
        return `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`
    }
    function getInterpolatedColor(startColor, midColor, endColor, intensity) {
        let factor
        let rgbColor1, rgbColor2
    
        if (intensity <= 50) {
            factor = intensity / 50
            rgbColor1 = hexToRgb(startColor)
            rgbColor2 = hexToRgb(midColor)
        } else {
            factor = (intensity - 50) / 50
            rgbColor1 = hexToRgb(midColor)
            rgbColor2 = hexToRgb(endColor)
        }
    
        const interpolatedColor = interpolateColor(rgbColor1, rgbColor2, factor)
        return rgbToHex(interpolatedColor)
    }
    
    const pageBgColor = [20, 23, 37]
    const blendColor = (foreground, alpha) => {
        const foregroundRgb = hexToRgb(foreground)
        const backgroundRgb = pageBgColor
        const r = Math.round((1 - alpha) * backgroundRgb[0] + alpha * foregroundRgb[0])
        const g = Math.round((1 - alpha) * backgroundRgb[1] + alpha * foregroundRgb[1])
        const b = Math.round((1 - alpha) * backgroundRgb[2] + alpha * foregroundRgb[2])
        return rgbToHex([r, g, b])
    }

    let opacity = 1
    let intensity = 0
    let fontWeight = 400

    let textColor, background, borderColor, boxShadow, transform
    if (isHeatmap) {
        if (scheduleType === "festivalHeatmap" && heatmapData) {
            if (heatmapType === 0) intensity = heatmapData["intensityVotes"]
            if (heatmapType === 1) intensity = heatmapData["intensityMustSee"]
            if (heatmapType === 2) intensity = heatmapData["intensityMustSeePercent"]
        } else if (scheduleType === "group") {
            const totalVotes = heatmapData?.totalVotes || 0
            const totalMustSee = heatmapData?.totalMustSee || 0

            if (heatmapType === 0) {
                intensity = Math.round((totalVotes / maxTotalVotes) * 100)
            } else if (heatmapType === 1) {
                intensity = Math.round((totalMustSee / maxTotalMustSee) * 100)
            } else if (heatmapType === 2) {
                intensity = Math.round((totalMustSee / totalVotes) * 100)
            }
        }

        if (intensity >= 80) {
            textColor = "#000000"
            fontWeight = 800
            opacity = 1
        } else if (intensity > 0 && intensity < 80) {
            textColor = "rgba(255,255,255,0.85)"
            fontWeight = 600
            opacity = 1
        } else {
            textColor = "rgba(255,255,255,0.85)"
            fontWeight = 400
            opacity = 0.3
        }

        background = blendColor(heatmapColor, intensity / 100)

        // const startColor = '#FFE48A'
        // const midColor = '#D77A3D'
        // const endColor = '#4D0083'
        // background = getInterpolatedColor(startColor, midColor, endColor, intensity)

        // if (intensity >= 50) {
        //     textColor = "#fff"
        //     fontWeight = 800
        //     opacity = 1
        // } else if (intensity > 0 && intensity < 50) {
        //     textColor = "#000"
        //     fontWeight = 600
        //     opacity = 0.9
        // } else {
        //     textColor = "#fff"
        //     fontWeight = 200
        //     opacity = 0.3
        // }
        // if (intensity === 0) {
        //     background = `rgba(255,255,255,0.1)`
        // }

        borderColor = hexToRgbA("#141725", 1)
        boxShadow = `0px 0px 10px 6px ${hexToRgbA("#000000", 0.05)} inset`
    } else {
        if (isSetTime) {
            if (clickCount === 1) {
                textColor = hexToRgbA("#ffffff", 0.85)
                background = blendColor(stageColor, 0.15)
                borderColor = hexToRgbA(stageColor, 0.9)
                boxShadow = `0px 0px 10px 6px ${hexToRgbA("#000000", 0.05)} inset`
                transform = `rotateY(360deg)`
            } else if (clickCount === 2) {
                textColor = hexToRgbA("#000000", 1)
                background = blendColor(stageColor, 1)
                borderColor = hexToRgbA(stageColor, 1)
                boxShadow = `0px 1px 10px 0.5px ${hexToRgbA("#000000", 0.25)}`
                transform = `rotateY(720deg)`
            } else {
                textColor = hexToRgbA("#ffffff", 0.85)
                background = isHovered ? blendColor("#000", 0.15) : blendColor("#ffffff", 0.01)
                borderColor = hexToRgbA("#ffffff", 0.25)
                boxShadow = `none`
                transform = `rotateY(0deg)`
            }
        } else {
            if (clickCount === 1) {
                textColor = hexToRgbA("#ffffff", 0.85)
                background = blendColor("#0085FF", 0.2)
                borderColor = `rgba(0,133,255,1)`
                boxShadow = `0px 0px 10px 6px rgba(0,0,0,0.15) inset`
                transform = `rotateX(360deg)`
            } else if (clickCount === 2) {
                textColor = hexToRgbA("#ffffff", 0.85)
                background = blendColor("#0085FF", 1)
                borderColor = `rgba(0,133,255,1)`
                boxShadow = `1px 2px 10px rgba(255, 255, 255, 0.15)`
                transform = `rotateX(720deg)`
            } else {
                textColor = hexToRgbA("#ffffff", 0.85)
                background = isHovered ? blendColor("#000", 0.15) : blendColor("#ffffff", 0.01)
                borderColor = hexToRgbA("#ffffff", 0.25)
                boxShadow = `none`
                transform = `rotateX(0deg)`
            }
        }
    }

    let blockFontSize = 11
    let columnWidth = 96
    let heightScale = 1

    if (festival.hasSetTimes) {
        blockFontSize = blockFontSizeSet
        columnWidth = columnWidthSet
        heightScale = heightScaleSet
    } else {
        blockFontSize = blockFontSizeNoSet
        columnWidth = columnWidthNoSet
        heightScale = heightScaleNoSet
    }

    let style = {
        fontSize: blockFontSize,
        lineHeight: `${blockFontSize - 1}px`,
        fontWeight: fontWeight,
        color: textColor,
        background: background,
        border: `1px solid ${borderColor}`,
        opacity: opacity,
        boxShadow: boxShadow,
        transform: transform
    }
 
    if (isSetTime) {
        let dayStart = festival.daysAndTimes[selectedDayIndex].start
        let dayEnd = festival.daysAndTimes[selectedDayIndex].end
        if (isExport) {
            dayStart = festival.daysAndTimes[exportDayIndex].start
            dayEnd = festival.daysAndTimes[exportDayIndex].end
        }
        const blockHeightAndPosition = getBlockHeightAndPosition(dayStart, dayEnd, timelineHeight, artist.Start, artist.End)

        style = {
            position: 'absolute',
            width: '100%',
            height: `${blockHeightAndPosition['height']}px`,
            top: `${blockHeightAndPosition['top']}px`,
            boxSizing: 'border-box',
            ...style
        }
    } else {
        if (windowWidth <= 1000) {
            style = {
                width: (windowWidth - 32 - ((numBlocksMobile - 1) * 8) - (8 * numBlocksMobile)) / numBlocksMobile,
                height: `${64 * heightScale}px`,
                ...style
            }
        } else {
            style = {
                width: columnWidth,
                height: `${64 * heightScale}px`,
                ...style
            }
        }
    }

    // if (scheduleType === "group" && groupSelected) {
    //     style = {
    //         ...style,
    //         border: `1px solid ${hexToRgbA("#FFFFFF", 1)}`,
    //         boxShadow: `0px 0px 10px 4px ${hexToRgbA("#FFFFFF", 0.3)}`,
    //         opacity: 1,
    //         transform: `scale(1.025)`,
    //         zIndex: 1000
    //     }
    // }

    const members = heatmapData?.members || {}
    const filteredMembersData = Object.keys(members).reduce((acc, memberId) => {
        if (members[memberId] > 0 && membersData[memberId]) {
            if (heatmapType === 0) {
                acc[memberId] = { ...membersData[memberId], vote: members[memberId] }
            } else if (heatmapType === 1) {
                if (members[memberId] === 2) {
                    acc[memberId] = { ...membersData[memberId], vote: members[memberId] }
                }
            }
        }
        return acc
    }, {})
    const sortedMembersData = Object.values(filteredMembersData).sort((a, b) => {
        const nameA = a.displayName || ""
        const nameB = b.displayName || ""
        
        if (b.vote === a.vote) {
            return nameA.localeCompare(nameB)
        }
        return b.vote - a.vote
    })

    const handleBlockClick = () => {
        if (scheduleType === "userSchedule" && isCreator) {
            onMyScheduleBlockClick()
        } else if (scheduleType === "festivalHeatmap") {
            
        } else if (scheduleType === "group") {
            // handleGroupBlockClick()
        }
    }

    if (!isCreator || scheduleType === "festivalHeatmap" || scheduleType === "group") {
        style = {
            ...style,
            cursor: 'default'
        }
    } else {
        style = {
            ...style,
            cursor: 'pointer'
        }
    }

    if (isExport) {
        style = {
            ...style,
            width: stageWidth - 16,
            padding: `4px`,
            boxShadow: 'none',
            fontSize: `25px`,
            lineHeight: `25px`,
        }

        if (colorStyle.name === "FestiPlannr") {
            if (clickCount === 0) {
                style = {
                    ...style,
                    background: 'transparent',
                    border: `1px solid rgba(255,255,255,0.5)`,
                    color: `rgba(255,255,255,0.6)`
                }
            }
            if (clickCount === 1) {
                style = {
                    ...style,
                    background: hexToRgbA(stageColor, 0.2),
                }
            }
        } else {
            if (clickCount === 0) {
                style = {
                    ...style,
                    background: hexToRgbA(colorStyle.bg, 0.2),
                    border: `1px solid ${hexToRgbA("#FFFFFF", 0.3)}`,
                    color: `rgba(255,255,255,0.6)`
                }
            }
            if (clickCount === 1) {
                style = {
                    ...style,
                    background: hexToRgbA(colorStyle.main, 0.28),
                    border: `1px solid ${colorStyle.main}`
                }
            }
            if (clickCount === 2) {
                style = {
                    ...style,
                    background: colorStyle.main,
                    border: `1px solid ${colorStyle.bg}`,
                    color: colorStyle.bg
                }
            }
        }
    }

    let artistTimeFontSize = blockFontSize
    if (isExport) artistTimeFontSize = 22
    
    return (
        <>
            <div 
                ref={blockRef} 
                className={getBlockClass()} 
                style={style} 
                onClick={handleBlockClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={getArtistClass()}>
                    {artist.Artist}
                    {festival.hasSetTimes && (
                        <div className={`block2-artist-time`} style={{ fontSize: `${artistTimeFontSize}px`, lineHeight: `${artistTimeFontSize}px` }}>
                            <span>{formatTime(artist.Start)}</span>
                            <div className={`block2-artist-end-time ${showEndTime || isExport ? 'artist-time-visible' : 'artist-time-hidden'}`}>
                                <span className="time-hyphen">-</span>
                                <span>{formatTime(artist.End)}</span>
                            </div>
                        </div>
                    )}
                </div>
                {clickCount === 2 && !isHeatmap && (
                    <IconHeartFilled 
                        className={`heart-icon ${showHeart ? 'visible' : ''}`} 
                        size={Math.min(blockFontSize + 1, 14)} 
                        style={{
                            color: `${isSetTime} ? '#000000' : '#ffffff'`,
                            filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25))',
                        }}
                    />
                )}
                {scheduleType === "group" && membersData && (
                    <div className={`avatar-set-container ${showGroupAvatars ? "fade-in" : "fade-out"}`}>
                        <AvatarSet membersData={sortedMembersData} maxDisplay={4} setType={"block"} festival={festival} />
                    </div>
                )}
            </div>
        </>
    )
}

export default Block2