import React, { useState } from "react"
import ButtonGroup from "../Festivals/ButtonGroup"
import { IconTrash } from "@tabler/icons-react"
import ConfirmationModal from "../General/ConfirmationModal"

const ScheduleActions = ({ 
    currentFestival, 
    selectedDayIndex, 
    setSelectedDayIndex, 
    scheduleType,
    heatmapType,
    setHeatmapType,
    blockSelectionsEmpty,
    localBlockSelectionsEmpty,
    clearBlockSelections,
    isCreator,
    currentUser
}) => {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const openConfirmationModal = () => { setIsConfirmationModalOpen(true) }
    const closeConfirmationModal = () => { setIsConfirmationModalOpen(false) }
    const handleClearSelections = () => { clearBlockSelections() }

    const renderDaySwitcherButtonGroup = () => {
        if (currentFestival.hasDay || currentFestival.hasSetTimes) {
            return (
                <ButtonGroup
                    buttons={currentFestival.daysAndTimes}
                    onButtonSelect={(index) => setSelectedDayIndex(index)}
                    selectedButtonIndex={selectedDayIndex}
                    buttonType="days"
                />
            )
        }
        return null
    }

    let shouldClearBeDisabled = false
    if (currentUser && blockSelectionsEmpty) shouldClearBeDisabled = true
    if (!currentUser && localBlockSelectionsEmpty) shouldClearBeDisabled = true

    if (scheduleType === "festivalHeatmap" && currentFestival) {
        return (
            <div className={`${currentFestival.hasSetTimes ? 'schedule-options-wrapper-set-times' : 'schedule-options-wrapper'}`}>
                {renderDaySwitcherButtonGroup()}
                <ButtonGroup 
                    buttons={["All", "Must see", "Must see %"]}
                    onButtonSelect={(index) => setHeatmapType(index)}
                    selectedButtonIndex={heatmapType}
                    buttonType="heatmap"
                />
            </div>
        )
    }
    if (scheduleType === "group" && currentFestival) {
        return (
            <div className={`${currentFestival.hasSetTimes ? 'schedule-options-wrapper-set-times' : 'schedule-options-wrapper'}`}>
                {renderDaySwitcherButtonGroup()}
                <ButtonGroup 
                    buttons={["All votes", "Must see"]}
                    onButtonSelect={(index) => setHeatmapType(index)}
                    selectedButtonIndex={heatmapType}
                    buttonType="heatmap"
                />
            </div>
        )
    }
    if (scheduleType === "userSchedule" && currentFestival) return (
        <div className={`${currentFestival.hasSetTimes ? 'schedule-options-wrapper-set-times' : 'schedule-options-wrapper'}`}>
            {renderDaySwitcherButtonGroup()}
            {isCreator && (
                <button className="btn-with-icon danger-btn schedule-options-clear" onClick={openConfirmationModal} disabled={shouldClearBeDisabled}>
                    <IconTrash size={20} strokeWidth={1.5} />
                    <p>Clear selections</p>
                </button>
            )}
            <ConfirmationModal 
                isOpen={isConfirmationModalOpen} 
                onRequestClose={closeConfirmationModal} 
                onConfirm={handleClearSelections} 
            />
        </div>
    )
}

export default ScheduleActions