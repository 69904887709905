import React, { useState } from 'react'
import './Tabs.css'

const Tabs = ({ tabs, selectedTab, setSelectedTab }) => {
    const handleTabClick = (tab) => {
        setSelectedTab(tab)
    }

    return (
        <div className="tabs-container">
            <div className="tabs">
                {tabs.map((tab, index) => (
                    <p
                        key={index}
                        className={`tab ${selectedTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab}
                    </p>
                ))}
            </div>
            <div className="active-underline" style={{ left: `${selectedTab * 96}px` }} />
        </div>
    )
}

export default Tabs
