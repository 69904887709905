import React from 'react'
import { IconArrowsShuffle, IconSparkles } from '@tabler/icons-react'

const ColorSetOption = ({ isSelected, colorSpec, setColorStyle }) => {
    if (colorSpec.name === "shuffle") {
        return (
            <div 
                className={`color-set-option color-set-icon ${isSelected ? 'icon-selected' : ''}`}
                onClick={() => {
                    setColorStyle(colorSpec)
                }}
            >
                <IconArrowsShuffle size={20} strokeWidth={1.5} className="icon-color-set-option" />
            </div>
        )
    } else if (colorSpec.name === "random") {
        return (
            <div 
                className={`color-set-option color-set-icon ${isSelected ? 'icon-selected' : ''}`}
                onClick={() => {
                    setColorStyle(colorSpec)
                }}
            >
                <IconSparkles size={20} strokeWidth={1.5} className="icon-color-set-option" />
            </div>
        )
    } else {
        return (
            <div 
                className={`color-set-option ${isSelected ? 'selected' : ''}`}
                onClick={() => {
                    setColorStyle(colorSpec)
                }}
            >
                <div className="color-half" style={{ backgroundColor: colorSpec.main }} />
                <div className="color-half right" style={{ backgroundColor: colorSpec.bg }} />
            </div>
        )
    }
}

export default ColorSetOption
