import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFestival } from '../../context/FestivalContext'
import './Festivals.css'
import { IconCalendarClock, IconMapPin, IconHeart, IconHeartFilled } from '@tabler/icons-react'
import { formatDateRange } from '../../utils/helpers'
import { useFavoriteFestivals } from '../../context/FavoriteFestivalContext'

// Components
import LineupTag from '../Tag/LineupTag'

function FestivalCard({ id, name, start, end, location, hasLineup, hasDay, hasSetTimes, imageUrls, isFavorite, currentUser }) {
    const { setFestival } = useFestival()
    const navigate = useNavigate()
    const { favorites, addFavorite, removeFavorite } = useFavoriteFestivals()
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    const handleCardClick = () => {
        setFestival(id)
        navigate(`/${id}`)
    }
    const handleHeartClick = async (e) => {
        e.stopPropagation()
        const isFavorite = favorites.includes(id)
        if (isFavorite) {
            await removeFavorite(currentUser.uid, id)
        } else {
            await addFavorite(currentUser.uid, id)
        }
    }

    const formattedDateRange = formatDateRange(start, end)

    const handleImageLoad = () => {
        setIsImageLoaded(true)
    }

    return (
        <div className="f-card-container" onClick={handleCardClick} title="Go to festival">
            <div className="f-card-image-wrapper">
                {!isImageLoaded && <div className="image-placeholder">...</div>}
                <img
                    src={imageUrls.med}
                    alt={name}
                    className={`f-image ${isImageLoaded ? 'loaded' : ''}`}
                    onLoad={handleImageLoad}
                />
            </div>
            <div className="f-card-details">
                <div className="f-card-name-row">
                    <h3>{name}</h3>
                    {currentUser && (
                        <div className="icon-favorite-container" onClick={handleHeartClick}>
                            {isFavorite ? (
                                <IconHeartFilled
                                    size={18}
                                    strokeWidth={1.5}
                                    className="icon-f-card-favorite filled"
                                    title="Remove from favorites"
                                />
                            ) : (
                                <IconHeart
                                    size={18}
                                    strokeWidth={1.5}
                                    className="icon-f-card-favorite"
                                    title="Add to favorites"
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className="f-tags">
                    <LineupTag text="Lineup" status={hasLineup} complete={hasSetTimes} inNav={false} />
                    <LineupTag text="By day" status={hasDay} complete={hasSetTimes} inNav={false} />
                    <LineupTag text="Set times" status={hasSetTimes} complete={hasSetTimes} inNav={false} />
                </div>
                <div className="f-card-metadata">
                    <div className="f-metadata-row">
                        <IconCalendarClock size={16} className="icon-f-card-metadata" />
                        {formattedDateRange}
                    </div>
                    <div className="f-metadata-row">
                        <IconMapPin size={16} className="icon-f-card-metadata" />
                        {location}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FestivalCard