import React from 'react'
import '../Festivals/Festivals.css'
import './Past.css'
import { useFavoriteFestivals } from '../../context/FavoriteFestivalContext'
import { IconArchive } from '@tabler/icons-react'

// Components
import FestivalCard from '../Festivals/FestivalCard'

function Past({ currentUser, festivalData }) {
    const { favorites } = useFavoriteFestivals()

    const festivalArray = Object.values(festivalData)
        .filter(festival => festival.passed)
        .sort((a, b) => new Date(b.end) - new Date(a.end));

    const groupPastFestivalsByMonth = (festivals) => {
        const grouped = {}
    
        festivals.forEach(festival => {
            const month = new Date(festival.end).toLocaleString('default', { month: 'long', year: 'numeric' })
            if (!grouped[month]) {
                grouped[month] = []
            }
            grouped[month].push(festival)
        })
    
        return grouped
    }

    const groupedPastFestivals = groupPastFestivalsByMonth(festivalArray)

    return (
        <React.Fragment>
            <div className="festivals-wrapper">
                <div className="festivals-intro-text">
                    <p className="page-context-bar opacity50">Past festivals</p>
                    <div className="past-page-header">
                        <IconArchive size={24} stroke={1.5} />
                        <h2>Past festivals</h2>
                    </div>
                    <div className="upcoming-text">
                        <p className="upcoming-subtext">All previous festivals on FestiPlannr are stored here!</p>
                    </div>
                </div>
                <div className="past-cards">
                    {Object.keys(groupedPastFestivals).map(month => (
                        <div key={month} className="festival-month-group">
                            <div className="festival-month-border" />
                            <div className="festival-month-header">{month}</div>
                            <div className="festival-month-cards">
                                {groupedPastFestivals[month].map(festival => (
                                    <FestivalCard
                                        key={festival.id}
                                        {...festival}
                                        isFavorite={favorites.includes(festival.id)}
                                        currentUser={currentUser}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Past