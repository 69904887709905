import { parseTSVtoObject } from "./helpers"
import { fetchFestivalSchedule } from "../services/firebase/firestoreFunctions"
import { useRef, useCallback } from 'react'
import { debounce as lodashDebounce } from 'lodash'
import { update, ref, get, child } from 'firebase/database'
import { database } from '../config/firebase-config'

export const fetchAndProcessScheduleData = async (festivalId, lineupType) => {
    let filename = `${festivalId}-${lineupType}.tsv`
    
    if (lineupType === "artists" || lineupType === "day") {
        const tsvData       = await fetchFestivalSchedule(filename)
        const scheduleData  = parseTSVtoObject(tsvData)
        return scheduleData
    } else if (lineupType === "settimes") {
        try {
            const tsvData = await fetchFestivalSchedule(filename)
            const scheduleData = parseTSVtoObject(tsvData)
    
            // Group blocks by stage
            const groupedStages = scheduleData.reduce((acc, event) => {
                acc[event['Location']] = acc[event['Location']] || { rank: event['StageRank'], events: [] }
                acc[event['Location']].events.push(event)
                return acc
            }, {})
    
            // Sort stages by rank
            const sortedStages = Object.keys(groupedStages)
                .sort((a, b) => groupedStages[a].rank - groupedStages[b].rank)
                .map(stageName => [
                    stageName,
                    groupedStages[stageName].events
                ])
    
            return sortedStages
        } catch (error) {
            console.error('Failed to fetch and process schedule data:', error)
            throw error
        }
    } else {
        return
    }
}

export const encodeKey = (key) => key.replace(/[.#$/[\]]/g, '').replace(/\//g, '-SLASH-')

export function useImmediateUpdates(scheduleId, festivalId, userId, setSaveStatus) {
    const handleBlockClick = async (key, newCount, oldCount) => {
        setSaveStatus('Saving...');

        const blockPath = `/schedules/${scheduleId}/selections/${encodeKey(key)}`;
        const updates = {
            [blockPath]: newCount > 0 ? newCount : null,
        };

        try {
            await update(ref(database), updates);

            // Fetch the groups the user is part of for this festival
            const userGroupsRef = ref(database, `/users/${userId}/groups/${festivalId}`);
            const userGroupsSnapshot = await get(userGroupsRef);
            const userGroups = userGroupsSnapshot.val();

            if (userGroups) {
                for (const groupId of Object.keys(userGroups)) {
                    const groupHeatmapRef = ref(database, `/groups/${groupId}/heatmap`);
                    const artistKey = encodeKey(key);
                    await updateHeatmapData(groupHeatmapRef, artistKey, newCount, oldCount, userId);
                }
            }

            setSaveStatus('Saved');
        } catch (error) {
            console.error('Failed to update database:', error);
        }
    };

    return { handleBlockClick };
}

export function useDebouncedUpdates(scheduleId, festivalId, userId, setSaveStatus) {
    const pendingUpdates = useRef({});

    const commitChanges = useCallback(async () => {
        if (Object.keys(pendingUpdates.current).length === 0) {
            setSaveStatus('Saved');
            return;
        }

        setSaveStatus('Saving...');
        const updates = {};
        Object.entries(pendingUpdates.current).forEach(([blockId, { newCount }]) => {
            const blockPath = `/schedules/${scheduleId}/selections/${encodeKey(blockId)}`;
            updates[blockPath] = newCount > 0 ? newCount : null;
        });

        try {
            await update(ref(database), updates);

            // Fetch the groups the user is part of for this festival
            const userGroupsRef = ref(database, `/users/${userId}/groups/${festivalId}`);
            const userGroupsSnapshot = await get(userGroupsRef);
            const userGroups = userGroupsSnapshot.val();

            if (userGroups) {
                for (const groupId of Object.keys(userGroups)) {
                    const groupHeatmapRef = ref(database, `/groups/${groupId}/heatmap`);
                    await Promise.all(
                        Object.entries(pendingUpdates.current).map(async ([blockId, { newCount, oldCount }]) => {
                            const artistKey = encodeKey(blockId);
                            // Update the heatmap for the group
                            await updateHeatmapData(groupHeatmapRef, artistKey, newCount, oldCount, userId);
                        })
                    );
                }
            }

            setTimeout(() => {
                setSaveStatus('Saved');
            }, 1);
        } catch (error) {
            console.error('Failed to batch update database:', error);
        }

        pendingUpdates.current = {};
    }, [scheduleId, festivalId, userId, setSaveStatus]);

    const debouncedCommitChanges = useCallback(lodashDebounce(commitChanges, 1500), [commitChanges]);

    const handleBlockClick = (key, newCount, oldCount) => {
        pendingUpdates.current[key] = { newCount, oldCount };
        debouncedCommitChanges();
    };

    return { handleBlockClick };
}

// Updated Heatmap Data Function
async function updateHeatmapData(heatmapRef, artistKey, newValue, oldValue, userId) {
    // Fetch the current data for the specific artistKey
    const artistDataRef = child(heatmapRef, artistKey);
    const artistDataSnapshot = await get(artistDataRef);
    const artistData = artistDataSnapshot.val() || {};

    // Get the current totalVotes and totalMustSee for the specific artistKey
    const currentTotalVotes = artistData.totalVotes || 0;
    const currentTotalMustSee = artistData.totalMustSee || 0;

    // Prepare the updates object
    const updates = {};

    if (newValue !== oldValue) {
        // Update totalVotes and totalMustSee based on the new and old values
        if (oldValue === null || oldValue === 0) {
            if (newValue === 1) {
                updates['totalVotes'] = currentTotalVotes + 1;
                updates[`members/${userId}`] = 1;
            } else if (newValue === 2) {
                updates['totalVotes'] = currentTotalVotes + 1;
                updates['totalMustSee'] = currentTotalMustSee + 1;
                updates[`members/${userId}`] = 2;
            }
        } else if (oldValue === 1) {
            if (newValue === null || newValue === 0) {
                updates['totalVotes'] = currentTotalVotes - 1;
                updates[`members/${userId}`] = null;
            } else if (newValue === 2) {
                updates['totalMustSee'] = currentTotalMustSee + 1;
                updates[`members/${userId}`] = 2;
            }
        } else if (oldValue === 2) {
            if (newValue === null || newValue === 0) {
                updates['totalVotes'] = currentTotalVotes - 1;
                updates['totalMustSee'] = currentTotalMustSee - 1;
                updates[`members/${userId}`] = null;
            } else if (newValue === 1) {
                updates['totalMustSee'] = currentTotalMustSee - 1;
                updates[`members/${userId}`] = 1;
            }
        }
    }

    // Apply the updates to the specific artistKey's data
    await update(artistDataRef, updates);

    // Fetch the updated heatmap data to calculate the new max values
    const updatedHeatmapSnapshot = await get(heatmapRef);
    const updatedHeatmapData = updatedHeatmapSnapshot.val() || {};

    // Calculate the maxTotalVotes and maxTotalMustSee across the group
    const maxTotalVotes = Math.max(
        ...Object.values(updatedHeatmapData).map(data => (data.totalVotes !== undefined ? data.totalVotes : 0))
    );

    const maxTotalMustSee = Math.max(
        ...Object.values(updatedHeatmapData).map(data => (data.totalMustSee !== undefined ? data.totalMustSee : 0))
    );

    const globalUpdates = {
        maxTotalVotes,
        maxTotalMustSee,
    };

    // Apply the global updates in a single write operation
    await update(heatmapRef, globalUpdates);
}

export function useDebouncedUpdatesGroup(groupId) {
    const pendingUpdates = useRef({})

    const commitChanges = useCallback(async () => {
        if (Object.keys(pendingUpdates.current).length === 0) {
            return
        }

        const updates = {}
        Object.entries(pendingUpdates.current).forEach(([blockId, value]) => {
            const blockPath = `/groups/${groupId}/selections/${encodeKey(blockId)}`
            updates[blockPath] = value ? true : null
        })

        try {
            await update(ref(database), updates)
        } catch (error) {
            console.error('Failed to batch update database:', error)
        }
        pendingUpdates.current = {}
    }, [groupId])

    const debouncedCommitChanges = useCallback(lodashDebounce(commitChanges, 1500), [commitChanges])

    const handleGroupBlockClick = (key, newCount) => {
        pendingUpdates.current[key] = newCount
        debouncedCommitChanges()
    }

    return { handleGroupBlockClick }
}