import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { auth } from './config/firebase-config'
import { Tooltip } from 'react-tooltip'
import './App.css'
import './components/Nav/Nav.css'
import './components/General/Modal.css'
import './components/General/Loading.css'

// Firebase
import { fetchAllFestivalsGeneralData } from './services/firebase/databaseService'

// Context
import { FestivalProvider } from './context/FestivalContext'
import { FavoriteFestivalsProvider } from './context/FavoriteFestivalContext'

// Components
import Nav from './components/Nav/Nav'
import Announcements from './components/Announcements/Announcements'
import Festivals from './components/Festivals/Festivals'
import Profile from './components/Profile/Profile'
import FestivalRoute from './components/Festivals/FestivalRoute'
import MobileNav from './components/Nav/MobileNav'
import Admin from './components/Admin/Admin'
import Past from './components/Past/Past'
import ScrollToTop from './components/General/ScrollToTop'

function App() {
    const [currentUser, setCurrentUser] = useState(null)
    const [festivalData, setFestivalData] = useState({})
    const [isAuthLoading, setIsAuthLoading] = useState(true)
    const [isFestivalDataLoading, setIsFestivalDataLoading] = useState(true)
    const [isContentVisible, setIsContentVisible] = useState(false)
    const [profilePicture, setProfilePicture] = useState(null)

    const [isNavHidden, setIsNavHidden] = useState(false)
    const [isNavOpen, setIsNavOpen] = useState(false)

    // Fetch festival data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const festivalsData = await fetchAllFestivalsGeneralData()
                setFestivalData(festivalsData)
            } catch (error) {
                console.error("Error fetching festival data:", error)
            } finally {
                setIsFestivalDataLoading(false)
            }
        }

        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setIsAuthLoading(false)
        })

        fetchData()

        return () => unsubscribe()
    }, [])

    useEffect(() => {
        if (!isAuthLoading && !isFestivalDataLoading) {
            setTimeout(() => {
                setIsContentVisible(true)
            }, 100)
        }
    }, [isAuthLoading, isFestivalDataLoading])

    const [isNavHiddenByUser, setIsNavHiddenByUser] = useState(false)
    const [isAtBottom, setIsAtBottom] = useState(false)
    const [lastScrollY, setLastScrollY] = useState(0)
    useEffect(() => {
        const handleScroll = (event) => {
            if (isNavHiddenByUser || event.deltaY === 0) return; // Ignore horizontal scrolls
    
            const currentScrollY = window.scrollY;
            const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;
    
            if (currentScrollY >= maxScrollY - 1) {
                setIsNavHidden(true);
                setIsAtBottom(true);
            } else if (currentScrollY < lastScrollY && isAtBottom) {
                setIsNavHidden(false);
                setIsAtBottom(false);
            }
    
            setLastScrollY(currentScrollY);
        };
    
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, isAtBottom, isNavHiddenByUser]);
    

    // Define when to scroll to top
    const shouldScroll = (prevPath, currentPath) => {
        if (prevPath === '/' && currentPath !== '/') {
            return true;
        }
        if (prevPath === '/blasts' && currentPath !== '/blasts') {
            return true;
        }
        if (prevPath === '/pastfestivals' && currentPath !== '/pastfestivals') {
            return true;
        }

        return false
    }
    

    if (isAuthLoading || isFestivalDataLoading) {
        return (
            <div className="loading-full">
                <div className="logo-circle" />
                <div className="loading-text">Loading FestiPlannr...</div>
            </div>
        )
    }
    
    return (
        <Router>
            <ScrollToTop shouldScroll={shouldScroll} />
            <FavoriteFestivalsProvider>
                <FestivalProvider festivalData={festivalData} currentUser={currentUser} >
                    <MobileNav 
                        isNavHidden={isNavHidden}
                        setIsNavHidden={setIsNavHidden}
                        isNavHiddenByUser={isNavHiddenByUser}
                        setIsNavHiddenByUser={setIsNavHiddenByUser}
                        isNavOpen={isNavOpen}
                        setIsNavOpen={setIsNavOpen}
                        currentUser={currentUser}
                        festivalData={festivalData}
                        profilePicture={profilePicture}
                    />
                    <div className={`App ${isContentVisible ? 'fade-in-site' : ''}`}>
                        <Nav 
                            currentUser={currentUser} 
                            festivalData={festivalData}
                            profilePicture={profilePicture}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                        />
                        <Routes>
                            <Route 
                                path="/" 
                                element={
                                    <Festivals 
                                        currentUser={currentUser}
                                        festivalData={festivalData}
                                    />
                                } 
                            />
                            <Route 
                                path="/pastfestivals" 
                                element={
                                    <Past 
                                        currentUser={currentUser}
                                        festivalData={festivalData}
                                    />
                                } 
                            />
                            <Route 
                                path="/blasts" 
                                element={
                                    <Announcements 
                                        currentUser={currentUser}
                                    />
                                } 
                            />
                            <Route 
                                path="/admin" 
                                element={
                                    <Admin 
                                        currentUser={currentUser}
                                        festivalData={festivalData}
                                    />
                                } 
                            />
                            <Route 
                                path="/:festivalId/*"
                                element={
                                    <FestivalRoute 
                                        currentUser={currentUser} 
                                        festivalData={festivalData}
                                        setIsNavHidden={setIsNavHidden}
                                    />
                                }
                            />
                            <Route
                                path="/u/:userDisplayName"
                                element={
                                    <Profile 
                                        currentUser={currentUser}
                                        festivalData={festivalData}
                                        setProfilePicture={setProfilePicture}
                                    />
                                }
                            />
                        </Routes>
                    </div>
                    <Tooltip 
                        id="tt" 
                        style={{ backgroundColor: "#272D4C", color: "#fff", zIndex: 100000 }}
                    />
                </FestivalProvider>
            </FavoriteFestivalsProvider>
        </Router>
    )
}

export default App