import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const NavTitle = React.memo(({ currentUser, profilePicture, handleNavClick }) => {
    const navigate = useNavigate()
    const handleTitleClick = useCallback(() => { 
        handleNavClick(true)
        navigate('/')
    }, [navigate])

    const handleProfileClick = useCallback(() => {
        handleNavClick(true)
        if (currentUser) { navigate(`/u/${currentUser.displayName}`) }
    }, [currentUser, navigate])

    return (
        <div className="nav-logo-container">
            <div className="nav-logo" onClick={handleTitleClick}>
                <div className="nav-logo-circle" />
                <div className="nav-logo-text">FestiPlannr</div>
            </div>
            {currentUser && (
                <div 
                    className="nav-propic" 
                    onClick={handleProfileClick} 
                >
                    {profilePicture ? (
                        <img 
                            src={profilePicture} 
                            alt="Profile" 
                            className="nav-propic-img" 
                        />
                    ) : currentUser.photoURL && (
                        <img 
                            src={currentUser.photoURL} 
                            alt="Profile" 
                            className="nav-propic-img" 
                        />
                    )}
                </div>
            )}
        </div>
    )
})

export default NavTitle