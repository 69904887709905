import React, { useState } from 'react'
import SignupLoginModal from './SignupLoginModal'

const NavAuth = React.memo(({ currentUser }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('signup') // 'signup' or 'login'
    const [email, setEmail] = useState('')

    const openModal = (content) => {
        setModalContent(content)
        setIsModalOpen(true)
    }
    const closeModal = () => setIsModalOpen(false)

    if (currentUser) {
        return null
    }

    return (
        <React.Fragment>
            <div className="nav-bottom">
                <div className="nav-signup-wrapper">
                    <div className="nav-signup-top">
                        <p className="opacity80">Sign up to create and share your own schedules, join groups, view festival heatmaps, and more!</p>
                        <input
                            type="email" 
                            placeholder="Email" 
                            className="input-default" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    openModal('signup');
                                }
                            }}
                        />
                        <button className="btn-primary" onClick={() => openModal('signup')}>Sign up</button>
                    </div>
                    <div className="nav-signup-bottom">
                        <p className="body-s opacity50">Already have an account?</p>
                        <div className="btn-text-blue" onClick={() => openModal('login')}>Log in</div>
                    </div>
                </div>
            </div>
            <SignupLoginModal 
                isOpen={isModalOpen} 
                onRequestClose={closeModal} 
                content={modalContent} 
                setModalContent={setModalContent} 
                preFillEmail={email}
            />
        </React.Fragment>
    )
})

export default NavAuth
