import React, { useState, useEffect } from 'react'
import { IconLink, IconHeartFilled, IconShare2, IconArrowNarrowLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useFestival } from '../../context/FestivalContext'
import { useParams } from 'react-router-dom'
import RequestModal from '../General/RequestModal'
import SignupLoginModal from '../Nav/SignupLoginModal'
import ImageModal2 from '../General/ImageModal2'

const UserScheduleHeader = ({ 
    currentUser, 
    currentFestival,
    saveStatus, 
    creatorInfo, 
    isCreator,
    lineupData,
    blockSelections
}) => {
    const windowWidth = window.innerWidth
    const { userScheduleId } = useFestival()
    const { scheduleId } = useParams()
    const [copyStatus, setCopyStatus] = useState('Copy')
    const [isScrolledPast, setIsScrolledPast] = useState(false)
    const [showFixedStatus, setShowFixedStatus] = useState(false)
    const [isProfilePicLoading, setIsProfilePicLoading] = useState(true)

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('signup') // 'signup' or 'login'
    const openModal = (content) => {
        setModalContent(content)
        setIsModalOpen(true)
    }
    const closeModal = () => setIsModalOpen(false)

    const [isImageModalOpen, setIsImageModalOpen] = useState(false)

    const navigate = useNavigate()
    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }
    
    const handleCopyLink = () => {
        const link = `https://www.festiplannr.com/${currentFestival.id}/s/${userScheduleId}`
        
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link).then(() => {
                setCopyStatus("Copied!")
                setTimeout(() => setCopyStatus('Copy'), 1000)
            }).catch((err) => {
                console.error('Failed to copy: ', err)
            })
        } else {
            // Fallback for older browsers or failure
            const textArea = document.createElement('textarea')
            textArea.value = link
            textArea.style.position = 'fixed'
            textArea.style.left = '-9999px'
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                document.execCommand('copy')
                setCopyStatus("Copied!")
                setTimeout(() => setCopyStatus('Copy'), 1000)
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err)
            }
            document.body.removeChild(textArea)
        }
    }

    const handleImageLoad = () => {
        setIsProfilePicLoading(false)
    }

    useEffect(() => {
        const handleScroll = () => {
            const headerElement = document.querySelector('#save-container')

            if (headerElement) {
                const headerBottom = headerElement.getBoundingClientRect().bottom
                setIsScrolledPast(headerBottom < 0)
            }
        }

        window.addEventListener('scroll', handleScroll, true)
        return () => window.removeEventListener('scroll', handleScroll, true)
    }, [])

    useEffect(() => {
        if (saveStatus === "Saving...") {
            setShowFixedStatus(true)
        } else if (saveStatus === "Saved") {
            const timer = setTimeout(() => {
                setShowFixedStatus(false)
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [saveStatus])

    let headerRow = <div></div>
    if (!isCreator || !currentUser) {
        if (scheduleId === "new") {
            headerRow = (
                <div className="mh-title-container">
                    <div className="mh-title-user-info">
                        <div className="mh-title">My schedule</div>
                    </div>
                    <div className={`mh-status-display status-view-only`}>
                        Unsaved
                    </div>
                    <p>
                        <span className="btn-text-link" onClick={() => openModal('signup')}>Sign up</span>
                        <span className="opacity80">&nbsp;to save your schedule!</span>
                    </p>
                </div>
            )
        } else {
            headerRow = (
                <div className="mh-title-container">
                    <div 
                        className="mh-title-user-info"
                        onClick={(e) => handleNavigate(e, `/u/${creatorInfo.displayName}`)}
                        data-tooltip-id="tt"
                        data-tooltip-content={`Go to ${creatorInfo.displayName}'s profile`}
                        style={{ cursor: "pointer" }}
                    >
                        <img 
                            className="mh-title-picture" 
                            src={creatorInfo.picture} 
                            onLoad={handleImageLoad}
                            onError={handleImageLoad}
                            style={{ display: isProfilePicLoading ? 'none' : 'block' }} 
                        />
                        {isProfilePicLoading && <div className="mh-title-picture img-loading-placeholder"></div>}
                        <div className="mh-title">{creatorInfo.displayName ? creatorInfo.displayName : "—"}'s schedule</div>
                    </div>
                    <div className={`mh-status-display status-view-only`}>
                        View only
                    </div>
                </div>
            )
        }
    } else {
        let shareButton = (
            <>
                {!currentFestival.hasSetTimes && (
                    <div className="mh-title-share">
                        <button 
                            className="btn-primary-sm btn-share-link"
                            onClick={handleCopyLink}
                            data-tooltip-id="tt"
                            data-tooltip-content={copyStatus === "Copied!" ? "🎉 Link copied!" : ""}
                            data-tooltip-delay-hide={500}
                        >
                            <IconLink size={20} strokeWidth={1.5}/>
                            <p>Share</p>
                        </button>
                    </div>
                )}
                {currentFestival.hasSetTimes && (
                    <div className="mh-title-share">
                        <button 
                            className="btn-primary-sm" 
                            onClick={() => setIsImageModalOpen(true)}
                        >
                            <IconShare2 size={20} strokeWidth={1.5}/>
                            <p>Export</p>
                        </button>
                    </div>
                )}
            </>
        )
        headerRow = (
            <div className="mh-title-container">
                <div className="mh-title-main">
                    <div className="mh-title-user-info">
                        <img 
                            className="mh-title-picture" 
                            src={currentUser.photoURL} 
                            onLoad={handleImageLoad}
                            onError={handleImageLoad}
                            style={{ display: isProfilePicLoading ? 'none' : 'block' }} 
                        />
                        {isProfilePicLoading && <div className="mh-title-picture loading-placeholder"></div>}
                        <div className="mh-title mh-mobile-title">My schedule</div>
                    </div>
                    <div id="save-container" className={`mh-status-display ${saveStatus === "Saving..." ? "mh-status-display-saving" : ""}`}>
                        <div className={`mh-status-dot ${saveStatus === "Saving..." ? "mh-status-dot-saving" : ""}`}></div>
                        {saveStatus}
                    </div>
                    <div className={`mh-status-display mh-status-display-fixed ${showFixedStatus && isScrolledPast ? 'visible' : ''} ${saveStatus === "Saving..." ? "mh-status-display-saving-fixed" : ""}`}>
                        <div className={`mh-status-dot ${saveStatus === "Saving..." ? "mh-status-dot-saving" : ""}`}></div>
                        {saveStatus}
                    </div>
                </div>
                {shareButton}
            </div>
        )
    }

    let clickOrTap = "click"
    if (window.innerWidth <= 1000) clickOrTap = "tap"

    if (currentFestival) return (
        <React.Fragment>
            <div className="main-header">
                <div className="mh-header-wrapper">
                    <div className="mh-festival-name">
                        <p>Schedule</p>
                        <div className="separator-dot" />
                        <p>{currentFestival.name}</p>
                    </div>
                    <div className="report-issue-wrapper">
                        {windowWidth <= 1000 && (
                            <div className="btn-with-icon" onClick={(e) => handleNavigate(e, "/")}>
                                <IconArrowNarrowLeft size={20} strokeWidth={1.5} />
                                <p>Back to Festivals</p>
                            </div>
                        )}
                        <div className="btn-text-link" onClick={openRequestModal}>Report an issue</div>
                    </div>
                </div>
                {headerRow}
                <div className="mh-legend-container">
                    <div className="mh-legend-item">
                        <div className="mh-legend-box-1" />
                        <p className="mh-legend-text">1 {clickOrTap} = Interested</p>
                    </div>
                    <div className="mh-legend-item">
                        <div className="mh-legend-box-2">
                            <IconHeartFilled size={12} />
                        </div>
                        <p className="mh-legend-text">2 {clickOrTap}s = Must see</p>
                    </div>
                </div>
            </div>
            <RequestModal 
                isOpen={isRequestModalOpen} 
                onRequestClose={closeRequestModal} 
                requestType={"Something's fucky"}
                currentUser={currentUser}
                origin={`User schedule, ${currentFestival.id}, ${scheduleId}, isCreator?: ${isCreator}`}
            />
            <SignupLoginModal 
                isOpen={isModalOpen} 
                onRequestClose={closeModal} 
                content={modalContent} 
                setModalContent={setModalContent} 
            />
            {currentFestival.hasSetTimes && (
                <ImageModal2
                    isOpen={isImageModalOpen} 
                    onRequestClose={() => setIsImageModalOpen(false)} 
                    lineupData={lineupData}
                    currentFestival={currentFestival}
                    blockSelections={blockSelections}
                    currentUser={currentUser}
                />
            )}
        </React.Fragment>
    )
}

export default UserScheduleHeader