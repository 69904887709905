import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = ({ shouldScroll }) => {
    const { pathname } = useLocation()
    const previousPathname = useRef()

    useEffect(() => {
        if (shouldScroll(previousPathname.current, pathname)) {
            window.scrollTo(0, 0)
        }
        previousPathname.current = pathname
    }, [pathname, shouldScroll])

    return null
}

export default ScrollToTop
