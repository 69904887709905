import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { IconX } from '@tabler/icons-react'
import './Modal.css'

const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm }) => {
    const [isClosing, setIsClosing] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setIsClosing(false)
        }
    }, [isOpen])

    const handleClose = () => {
        setIsClosing(true)
        setTimeout(onRequestClose, 300)
    }

    const handleConfirm = () => {
        setIsClosing(true)
        setTimeout(() => {
            onConfirm()
            onRequestClose()
        }, 300)
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className={`modal-wrapper ${isClosing ? 'modal-wrapper-close' : ''}`}
            overlayClassName={`modal-overlay ${isClosing ? 'modal-overlay-close' : ''}`}
        >
            <div className="modal-confirmation">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Confirm clear selections</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={handleClose} />
                </div>
                <div className="modal-content">
                    <p>Are you sure you want to clear your selections? This will remove all selections you've made across all days of this festival.</p>
                    <div className="modal-btn-group">
                        <button className="btn-with-icon" onClick={handleClose}>Cancel</button>
                        <button className="btn-primary btn-fixed-width" onClick={handleConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationModal
