import React, { useRef, useState, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import Modal from 'react-modal'
import { IconPlus } from '@tabler/icons-react'
import './NewGroup.css'
import { createGroup } from '../../services/firebase/databaseService'
import RequestModal from '../General/RequestModal'
const { useNavigate } = require('react-router-dom')

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    
    return width
}

Modal.setAppElement('#root')

const NewGroup2 = ({ currentUser, currentFestival }) => {
    const navigate = useNavigate()
    const editorRef = useRef(null)
    const windowWidth = useWindowWidth()

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    const [groupName, setGroupName] = useState("")
    const [groupNameError, setGroupNameError] = useState(null)
    const [isCreating, setIsCreating] = useState(false)

    const [image, setImage]             = useState(null)
    const [imageError, setImageError]   = useState(null)
    const [scale, setScale]             = useState(1)
    const [noClick, setNoClick]         = useState(false)
    const maxSize = 20 * 1024 * 1024 // 20MB
    const allowedFileTypes = [
        'image/jpeg', 
        'image/jpg', 
        'image/png', 
        'image/gif', 
        'image/bmp', 
        'image/webp', 
        'image/heif', 
        'image/heic', 
        'image/tiff'
    ];

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]

        setImage(null)
        setScale(1)

        if (!allowedFileTypes.includes(file.type)) {
            setImageError('Invalid file type')
            setNoClick(false)
            return
        }

        if (file.size > maxSize) {
            setImageError('File size exceeds the maximum limit of 20MB.')
            setNoClick(false)
            return
        }

        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0])
            setNoClick(true)
            setImageError(null)
        }
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick,
        noKeyboard: true,
    })

    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value))
    }

    const handleGroupNameChange = (e) => {
        setGroupName(e.target.value)
    }

    const validateGroupName = (name) => {
        const groupNameRegex = /^[a-zA-Z0-9_ ]{3,20}$/ // 3-20 characters, alphanumeric, underscores, and spaces
        return groupNameRegex.test(name)
    }

    const handleCreateGroup = async () => {
        if (!image || !groupName) return
        setIsCreating(true)
        let valid = true
    
        if (!currentUser) {
            setIsCreating(false)
            alert('You need to be logged in to create a group.')
            return
        }

        if (groupName.length === 0) {
            setGroupNameError('Group name is required')
            valid = false
        }

        if (!validateGroupName(groupName)) {
            setGroupNameError('Username must be 3-20 characters, alphanumeric and underscores only')
            valid = false
        }

        let imageDataURL = null
        if (editorRef.current) {
            const canvas = editorRef.current.getImage()
            const img = canvas.toDataURL()
            imageDataURL = img
        }

        if (!valid) {
            setIsCreating(false)
            return
        }
    
        try {
            const newGroupId = await createGroup(currentUser.uid, currentFestival.id, groupName, imageDataURL)
            setTimeout(() => {
                setIsCreating(false)
                navigate(`/${currentFestival.id}/g/${newGroupId}`)
            }, 100)
        } catch (error) {
            setIsCreating(false)
            console.error('Error creating group:', error)
        }
    }

    const hasErrors = groupNameError || imageError

    let imageDropzoneSize = 200
    if (windowWidth <= 1000) imageDropzoneSize = 120

    if (!currentFestival) return <div>Loading...</div>

    return (
        <React.Fragment>
            <div className="main-wrapper fade-in-content-no-scale">
                <div className="mini-header">
                    <div className="mh-header-wrapper extra-padding-2px">
                        <div className="mh-festival-name">
                            <p>New group</p>
                            <div className="separator-dot" />
                            <p>{currentFestival.name}</p>
                        </div>
                        <div className="report-issue-wrapper">
                            <div className="btn-text-link" onClick={openRequestModal}>Report an issue</div>
                        </div>
                    </div>
                </div>
                <div className="div-center">
                    <div className="form-wrapper">
                        <h2>Create a new group</h2>
                        <p className="new-group-subtext">After creation, just share the link to invite your friends!</p>
                        <div className="new-group-picture-wrapper">
                            <div className="dropzone-wrapper">
                                <div {...getRootProps({ className: `new-group-dropzone ${image ? 'no-hover' : ''}` })}>
                                    <input {...getInputProps()} />
                                    {image ? (
                                        <div className="new-group-picture-container">
                                            <AvatarEditor
                                                ref={editorRef}
                                                image={image}
                                                width={imageDropzoneSize}
                                                height={imageDropzoneSize}
                                                border={0}
                                                borderRadius={8}
                                                color={[255, 255, 255, 0.6]}
                                                scale={scale}
                                                rotate={0}
                                            />
                                        </div>
                                    ) : (
                                        <IconPlus size={48} strokeWidth={0.5} className="icon-plus-dropzone" />
                                    )}
                                </div>
                            </div>
                            {image && 
                                <div className="signup-slider-container">
                                    <p className="body-s">1x</p>
                                    <input
                                        id="scale-slider"
                                        type="range"
                                        value={scale}
                                        min="1"
                                        max="5"
                                        step="0.01"
                                        onChange={handleScaleChange}
                                        style={{ width: '100%' }}
                                    />
                                    <p className="body-s">5x</p>
                                </div>
                            }
                            <div className="txt-btn-photo-action" onClick={open}>
                                <p>{image ? 'Change photo' : 'Add photo'}</p>
                            </div>
                            {imageError && <p className="error-message">{imageError}</p>}
                        </div>
                        <div className="input-group-name">
                            <label className="group-name-label" htmlFor="group-name"><p>Group name</p></label>
                            <input
                                type="text"
                                id="group-name"
                                className={`input-default ${groupNameError ? 'input-error' : ''}`}
                                value={groupName}
                                onChange={handleGroupNameChange}
                                onFocus={() => setGroupNameError(null)}
                                placeholder="Enter group name"
                            />
                            {groupNameError && <p className="error-message">{groupNameError}</p>}
                        </div>
                        <button className="btn-primary" onClick={handleCreateGroup} disabled={isCreating || !image || !groupName || hasErrors}>
                            {isCreating ? 'Creating...' : 'Create group'}
                        </button>
                    </div>
                </div>
            </div>
            <RequestModal 
                isOpen={isRequestModalOpen} 
                onRequestClose={closeRequestModal} 
                requestType={"Something's fucky"}
                currentUser={currentUser}
                origin={`New group, ${currentFestival.id}`}
            />
        </React.Fragment>
    )
}

export default NewGroup2
