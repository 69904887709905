import React, { useEffect, useRef } from 'react'
import ButtonGroupBtn from './ButtonGroupBtn'
import './ButtonGroup.css'

const ButtonGroup = ({ buttons, selectedButtonIndex, onButtonSelect, buttonType }) => {
    const btnGroupRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            const nextIndex = (selectedButtonIndex + 1) % buttons.length;
            onButtonSelect(nextIndex);
        } else if (event.key === 'ArrowLeft') {
            const prevIndex = (selectedButtonIndex - 1 + buttons.length) % buttons.length;
            onButtonSelect(prevIndex);
        }
    };

    useEffect(() => {
        const btnGroupElement = btnGroupRef.current;
        if (btnGroupElement) {
            btnGroupElement.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (btnGroupElement) {
                btnGroupElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [selectedButtonIndex, buttons.length]);

    return (
        <div 
            className="btn-grp-wrapper"
            ref={btnGroupRef}
            tabIndex="0"
        >
            {buttons.map((button, index) => {
                const isLastButton = index === buttons.length - 1;
                let label = button
                if (buttonType === "days") label = button.day

                return (
                    <ButtonGroupBtn
                        key={index}
                        label={label}
                        isActive={index === selectedButtonIndex}
                        onClick={() => onButtonSelect(index)}
                        isLastButton={isLastButton}
                        buttonType={buttonType}
                    >
                        {button}
                    </ButtonGroupBtn>
                )}
            )}
        </div>
    )
}

export default ButtonGroup