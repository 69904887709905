import React, { useState, useEffect } from 'react'
import { ref, onValue, runTransaction, set } from 'firebase/database'
import { database } from '../../config/firebase-config'
import { fetchUserDataById } from '../../services/firebase/databaseService'
import { useNavigate } from 'react-router-dom'
import { IconSpeakerphone, IconPinFilled, IconMessage } from '@tabler/icons-react'
import { formatRelativeTime } from '../../utils/helpers'
import Reaction from '../Festivals/Reaction'
import CommentsSection from './CommentsSection'
import './Announcements.css'

const Announcements = ({ currentUser }) => {
    const [announcements, setAnnouncements] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const updateLastVisit = async () => {
            if (currentUser) {
                const userRef = ref(database, `users/${currentUser.uid}/lastAnnouncementVisit`);
                set(userRef, Date.now());
            }
        };
    
        updateLastVisit();
    }, [currentUser]);

    useEffect(() => {
        const fetchData = async () => {
            const announcementsRef = ref(database, 'announcements')
            onValue(announcementsRef, async (snapshot) => {
                const data = snapshot.val()
                const announcementsList = data ? Object.keys(data).map(key => ({
                    id: key,
                    ...data[key]
                })) : []
    
                // Separate pinned and non-pinned announcements
                const pinnedAnnouncements = announcementsList.filter(a => a.pinned)
                const nonPinnedAnnouncements = announcementsList.filter(a => !a.pinned)
    
                // Sort non-pinned announcements by timestamp
                nonPinnedAnnouncements.sort((a, b) => b.timestamp - a.timestamp)
    
                // Sort pinned announcements by timestamp
                pinnedAnnouncements.sort((a, b) => b.timestamp - a.timestamp)
    
                // Combine pinned announcements first, followed by non-pinned
                const sortedAnnouncements = [...pinnedAnnouncements, ...nonPinnedAnnouncements]
    
                const enrichedAnnouncements = await Promise.all(sortedAnnouncements.map(async (announcement) => {
                    const user = await fetchUserDataById(announcement.createdBy)
                    if (!currentUser) {
                        return {
                            ...announcement,
                            userDisplayName: user?.displayName || 'Unknown User',
                            userPicture: user?.picture.lowRes || ''
                        }
                    } else {
                        const reactions = announcement.reactions || {}
    
                        // Count reactions for each type
                        const reactionCounts = {
                            like: reactions.like ? Object.keys(reactions.like).length : 0,
                            love: reactions.love ? Object.keys(reactions.love).length : 0,
                            surprise: reactions.surprise ? Object.keys(reactions.surprise).length : 0,
                            sad: reactions.sad ? Object.keys(reactions.sad).length : 0,
                        }
        
                        // Calculate the total comment count, including replies
                        let totalComments = 0
                        if (announcement.comments) {
                            totalComments = Object.keys(announcement.comments).reduce((count, commentID) => {
                                const commentData = announcement.comments[commentID]
                                const replyCount = commentData.replies ? Object.keys(commentData.replies).length : 0
                                return count + 1 + replyCount
                            }, 0)
                        }
        
                        // Check if the current user has reacted to this announcement
                        const userReaction = {
                            like: reactions.like && reactions.like[currentUser.uid] === true,
                            love: reactions.love && reactions.love[currentUser.uid] === true,
                            surprise: reactions.surprise && reactions.surprise[currentUser.uid] === true,
                            sad: reactions.sad && reactions.sad[currentUser.uid] === true,
                        }
        
                        return {
                            ...announcement,
                            userDisplayName: user?.displayName || 'Unknown User',
                            userPicture: user?.picture.lowRes || '',
                            reactionCounts,
                            userReaction,
                            totalComments
                        }
                    }
                }))
    
                setAnnouncements(enrichedAnnouncements)
            })
        }
    
        fetchData()
    }, [])

    const handleReactionClick = (reaction, blastID) => {
        setAnnouncements(prevAnnouncements => prevAnnouncements.map(announcement => {
            if (announcement.id === blastID) {
                const isActive = !announcement.userReaction[reaction];
                const newCount = announcement.reactionCounts[reaction] + (isActive ? 1 : -1);
                return {
                    ...announcement,
                    reactionCounts: {
                        ...announcement.reactionCounts,
                        [reaction]: newCount
                    },
                    userReaction: {
                        ...announcement.userReaction,
                        [reaction]: isActive
                    }
                };
            }
            return announcement;
        }));
    
        const reactionRef = ref(database, `announcements/${blastID}/reactions/${reaction}/${currentUser.uid}`);
        runTransaction(reactionRef, currentData => (currentData ? null : true))
            .catch(error => console.error("Error updating reaction:", error));
    }

    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    return (
        <div className="announcements-page-wrapper">
            <p className="page-context-bar opacity50 profile-context-bar">Blasts</p>
            <div className="announcements-wrapper">
                <div className="announcements-page-header-wrapper">
                    <div className="announcements-page-title">
                        <IconSpeakerphone size={32} strokeWidth={1.5} className="announcements-icon" />
                        Blasts
                    </div>
                    <p className="announcements-subtext">General site updates, new features, new data, shts and gigs</p>
                </div>
                <div className="announcements-list">
                    {announcements.map(announcement => {
                        return (
                            <div className={`h-announcement-item ${announcement.priority ? 'priority' : ''}`} key={announcement.id}>
                                <div className="h-announcement-user-wrapper">
                                    {announcement.userPicture && (
                                        <img 
                                            src={announcement.userPicture} 
                                            alt="propic" 
                                            className="img-announcement-created-by" 
                                            onClick={(e) => handleNavigate(e, `/u/${announcement.userDisplayName}`)}
                                            data-tooltip-id="tt"
                                            data-tooltip-content={`View ${announcement.userDisplayName}'s profile`}
                                        />
                                    )}
                                    {announcement.pinned && (
                                        <div className="h-announcement-pinned-wrapper">
                                            <IconPinFilled size={16} strokeWidth={1.5} className="h-announcement-pinned-icon" />
                                            <p className="h-announcement-pinned-text">Pinned</p>
                                        </div>
                                    )}
                                    <div className="h-announcement-user-details">
                                        <p className="h-announcement-username">{announcement.userDisplayName}</p>
                                        <p className="h-announcement-timestamp">{formatRelativeTime(announcement.timestamp)}</p>
                                    </div>
                                </div>
                                <div key={announcement.id} className="h-announcement-item-content">
                                    <div className="announcement-item-header-wrapper">
                                        <p className={`announcement-subject`}>{announcement.subject}</p>
                                    </div>
                                    <div className="announcement-message-wrapper">
                                        {Object.values(announcement.message).map((msg, index) => (
                                            <p key={index} className="announcement-message-section">{msg}</p>
                                        ))}
                                    </div>
                                    {/* <div className="h-announcement-item-footer-wrapper">
                                        <p className="announcement-tags">Tags: {Object.keys(announcement.tags).join(', ')}</p>
                                    </div> */}
                                    {currentUser && (
                                        <div className="blasts-reax-comments-wrapper">
                                            <div className="blasts-reax-wrapper">
                                                <Reaction 
                                                    value={"like"}
                                                    emoji={"👍"}
                                                    count={announcement.reactionCounts.like}
                                                    isActive={announcement.userReaction.like}
                                                    onReactionClick={() => {handleReactionClick("like", announcement.id)}}
                                                />
                                                <Reaction 
                                                    value={"love"}
                                                    emoji={"❤️"}
                                                    count={announcement.reactionCounts.love}
                                                    isActive={announcement.userReaction.love}
                                                    onReactionClick={() => {handleReactionClick("love", announcement.id)}}
                                                />
                                                <Reaction 
                                                    value={"surprise"}
                                                    emoji={"😱"}
                                                    count={announcement.reactionCounts.surprise}
                                                    isActive={announcement.userReaction.surprise}
                                                    onReactionClick={() => {handleReactionClick("surprise", announcement.id)}}
                                                />
                                                <Reaction 
                                                    value={"sad"}
                                                    emoji={"😢"}
                                                    count={announcement.reactionCounts.sad}
                                                    isActive={announcement.userReaction.sad}
                                                    onReactionClick={() => {handleReactionClick("sad", announcement.id)}}
                                                />
                                            </div>
                                            {currentUser && (
                                                 <div className="blasts-comments-wrapper">
                                                    <CommentsSection 
                                                        announcementID={announcement.id}
                                                        currentUser={currentUser}
                                                        totalComments={announcement.totalComments}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Announcements