import React, { forwardRef } from "react"
import { IconDoorExit, IconCalendarSmile, IconArchive, IconCalendarUser, IconAdjustmentsHorizontal, IconFlame, IconUsers, IconSpeakerphone, IconHeart, IconHome, IconConfetti, IconInfoHexagon, IconChevronUp, IconHeartFilled } from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'

import './MobileNavTab.css'

const iconMap = {
    "Festivals": IconConfetti,
    "Blasts": IconSpeakerphone,
    "About": IconInfoHexagon,
    "Favs": IconHeart,
    "Mine": IconCalendarSmile,
    "Heatmap": IconFlame,
    "Groups": IconUsers,
    "Recents": IconCalendarUser,
    "Display": IconAdjustmentsHorizontal,
    "Past": IconArchive
}

const MobileNavTab = forwardRef(({ tabName, toUrl, isExpandable, isExpanded, handleClick, isFestivalPage, hasIndicator }, ref) => {
    let Icon = iconMap[tabName]
    let isRed = false
    if (isFestivalPage) {
        Icon = IconConfetti
        isRed = true
    }

    let isHeatmap = false
    if (tabName === "Heatmap") isHeatmap = true

    if (isExpandable) {
        return (
            <div ref={ref} className={`mn-icon mn-icon-w-indicator ${isExpanded ? 'mn-icon-pressed' : ''}`}>
                <IconChevronUp strokeWidth={4} className={`mn-icon-indicator ${isExpanded ? 'mn-icon-indicator-open' : ''}`} />
                <Icon size={26} strokeWidth={1.5} className={`mn-icon-below-indicator`} onClick={handleClick} />
                <p className="mn-icon-label mn-icon-w-indicator-text">{tabName}</p>
            </div>
        )
    } else {
        return (
            <NavLink
                ref={ref} 
                to={toUrl}
                className={({ isActive }) => `mn-icon ${isActive ? 'mn-icon-active' : ''} ${isHeatmap ? 'mn-icon-heatmap' : ''}`}
                onClick={handleClick}
            >
                <Icon size={26} strokeWidth={1.5} />
                <p className="mn-icon-label">{tabName}</p>
                {hasIndicator && <div className="mn-blasts-indicator" />}
            </NavLink>
        )
    }
})

export default MobileNavTab