import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUIContext } from '../../context/UIContext'
import { IconHeartFilled } from '@tabler/icons-react'
import './AvatarSet.css'

const AvatarSet = ({ membersData, maxDisplay, setSelectedTab, setType, festival }) => {
    const { columnWidthSet, columnWidthNoSet, groupAvatarSize } = useUIContext()

    const navigate = useNavigate()
    const members = Object.values(membersData)

    let columnWidth = 96
    if (festival.hasSetTimes) {
        columnWidth = columnWidthSet
    } else {
        columnWidth = columnWidthNoSet
    }

    const spaceBetweenAvatars = -6
    const totalBorderWidth = 2
    const extrasWidth = 24
    const spaceBetweenExtras = -4
    const totalBlockMarginOutside = 10
    const totalBlockPaddingInside = 6

    let totalBlockSpaceAvailable = columnWidth - totalBlockMarginOutside - totalBlockPaddingInside
    let totalAvatarSize = groupAvatarSize + totalBorderWidth

    if (setType === "block") {
        maxDisplay = Math.floor((totalBlockSpaceAvailable + spaceBetweenAvatars) / (totalAvatarSize + spaceBetweenAvatars))
        
        if (members.length >= (maxDisplay + 1)) {
            maxDisplay = Math.floor((totalBlockSpaceAvailable + spaceBetweenAvatars - extrasWidth) / (totalAvatarSize + spaceBetweenAvatars))
        }
    }
    
    let remainingCount = members.length - maxDisplay
    const displayMembers = members.slice(0, maxDisplay)
    const remainingMembers = members.slice(maxDisplay)

    const [allLoaded, setAllLoaded] = useState(false)
    const [loadedCount, setLoadedCount] = useState(0)

    useEffect(() => {
        if (loadedCount === displayMembers.length) setAllLoaded(true)
    }, [loadedCount, displayMembers.length])

    const handleImageLoad = () => {
        setLoadedCount((prevCount) => prevCount + 1)
    }

    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    const remainingMembersDisplayNames = remainingMembers.map(member => member.displayName).join('<br />')

    if (setType === "block") {
        return (
            <div className={`avatar-set ${allLoaded ? "fade-in" : ""}`} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}>
                {displayMembers.map((member, index) => (
                    member.picture && member.picture.lowRes && (
                        <div className="avatar-block-single" key={member.memberId} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                            <img
                                className="avatar-block"
                                src={member.picture.lowRes}
                                alt={member.displayName}
                                onLoad={() => handleImageLoad()}
                                style={{ 
                                    zIndex: maxDisplay - index, 
                                    width: groupAvatarSize, 
                                    height: groupAvatarSize,
                                    marginLeft: spaceBetweenAvatars
                                }}
                                data-tooltip-id="tt"
                                data-tooltip-content={member.displayName}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                            />
                            <IconHeartFilled 
                                className={`avatar-block-heart ${member.vote === 2 ? 'must-see' : ''}`}
                                style={{
                                    left: `${(groupAvatarSize / 2) - 4  - 6}px`,
                                }}
                            />
                        </div>
                    )
                ))}
                {remainingCount > 0 && (
                    <div 
                        className="avatar-block avatar-extra"
                        style={{
                            width: extrasWidth,
                            marginLeft: spaceBetweenExtras
                        }}
                        data-tooltip-id="tt"
                        data-tooltip-html={remainingMembersDisplayNames}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <span className="avatar-extra-plus">+</span>{remainingCount}
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={`avatar-set ${allLoaded ? "fade-in" : ""}`} >
            {displayMembers.map((member, index) => (
                member.picture && member.picture.lowRes && (
                    <img
                        key={member.memberId}
                        className="avatar"
                        src={member.picture.lowRes}
                        alt={member.displayName}
                        onClick={(e) => handleNavigate(e, `/u/${member.displayName}`)}
                        onLoad={() => handleImageLoad()}
                        style={{ zIndex: maxDisplay - index }}
                        title={`View ${member.displayName}'s profile`}
                        data-tooltip-id="tt"
                        data-tooltip-content={member.displayName}
                    />
                )
            ))}
            {remainingCount > 0 && (
                <div 
                    className="avatar extra" 
                    onClick={() => setSelectedTab(1)}
                    data-tooltip-id="tt"
                    data-tooltip-content="View all members"
                    data-tooltip-delay-show={500}
                >
                    +{remainingCount}
                </div>
            )}
        </div>
    )
}

export default AvatarSet
