import React from 'react'

const ButtonGroupBtn = ({ label, onClick, isActive, isLastButton, buttonType }) => {
    // Not the best way to do this rn, but set button width to 24px if "days"
    if (buttonType === 'days') {
        const abbreviatedLabel = label.slice(0, 3)

        return (
            <div 
                className={`btn-grp-btn ${isActive ? 'active' : ''} ${isLastButton ? '' : 'not-last-btn'}`}
                style={{ width: '16px' }}
                onClick={onClick}
            >
                {abbreviatedLabel}
            </div>
        )
    }

    // Otherwise do not manually set width
    return (
        <div 
            className={`btn-grp-btn ${isActive ? 'active' : ''} ${isLastButton ? '' : 'not-last-btn'}`}
            onClick={onClick}
        >
            {label}
        </div>
    )
}

export default ButtonGroupBtn