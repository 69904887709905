import React, { useState, useEffect, useRef } from 'react'
import { useUIContext } from '../../context/UIContext'

// Helpers
import { useDebouncedUpdatesGroup, useImmediateUpdates, encodeKey } from '../../utils/scheduleHelpers'
import { hexToRgbA } from '../../utils/helpers'

// Constants
import { STAGE_COLORS, ELEMENTS24_STAGE_COLORS } from '../../utils/constants'

// Components
import Timeline from './Timeline'
import Stage from './Stage'

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    
    return width
}

function SetTimeSchedule({ 
    scheduleType,
    currentUser,
    festival, 
    lineupData, 
    isCreator, 
    recs,
    selectedDayIndex, 
    incrementSelectedDayIndex,
    setSaveStatus, 
    scheduleId, 
    blockSelections, 
    localBlockSelections,
    setLocalBlockSelections,
    setBlockSelections,
    heatmapType, 
    festivalHeatmapData,
    heatmapColor,
    groupData,
    membersData,
    groupId,
    groupSelections,
    setGroupSelections
}) {
    const {
        columnWidthSet,
        heightScaleSet,
        blockFontSizeSet
    } = useUIContext()

    const windowWidth = useWindowWidth()
    
    const stageHeadersRef = useRef(null)
    const mainStagesRef = useRef(null)

    // const { handleBlockClick } = useDebouncedUpdates(scheduleId, festival.id, currentUser.uid, setSaveStatus)
    const { handleBlockClick } = useImmediateUpdates(scheduleId, festival.id, currentUser?.uid, setSaveStatus);
    const { handleGroupBlockClick } = useDebouncedUpdatesGroup(groupId)

    // --------------------------- 🖱️ Handle block clicks! ---------------------------
    const handleBlockClickInternal = (day, stageName, artistName, start) => {
        let key = `${stageName.toUpperCase()}-${start.toUpperCase()}-${day.toUpperCase()}-${artistName.toUpperCase()}`
        const encodedKey = encodeKey(key)
    
        if (currentUser) {
            if (isCreator) setSaveStatus('Saving...')
    
            setBlockSelections(prev => {
                const oldCount = prev[encodedKey] || 0
                const newCount = oldCount + 1 > 2 ? 0 : oldCount + 1
                handleBlockClick(encodedKey, newCount, oldCount)
                return { ...prev, [encodedKey]: newCount }
            })
        } else {
            setLocalBlockSelections(prev => {
                const oldCount = prev[encodedKey] || 0
                const newCount = oldCount + 1 > 2 ? 0 : oldCount + 1
                return { ...prev, [encodedKey]: newCount }
            })
        }
    }

    const handleGroupBlockClickInternal = (day, stageName, artistName, start) => {
        let key = `${stageName.toUpperCase()}-${start.toUpperCase()}-${day.toUpperCase()}-${artistName.toUpperCase()}`

        if (currentUser && currentUser.uid in membersData) {
            setGroupSelections(prev => {
                const newValue = (!prev[encodeKey(key)] || false)
                handleGroupBlockClick(encodeKey(key), newValue)
                return { ...prev, [encodeKey(key)]: newValue }
            })
        }
    }   

    const initialWindowHeight = useRef(window.innerHeight)
    let stageHeaderHeight = 80
    if (windowWidth <= 1000) stageHeaderHeight = 56
    let timelineHeight = (initialWindowHeight.current - stageHeaderHeight) * heightScaleSet

    // Find stages that have artists for the selected day and keep the original index
    const filteredLineupData = lineupData
        .map(([stageName, artists], index) => [stageName, artists, index])
        .filter(([stageName, artists]) => 
            artists.some(artist => artist.Day.toUpperCase() === festival.daysAndTimes[selectedDayIndex].day.toUpperCase())
        )

    // --------------------------- Stage blinking effect ---------------------------
    useEffect(() => {
        // Add this new effect for blinking decorations
        const applyBlinkingEffect = () => {
            const decoElements = document.querySelectorAll('.stage-header-decoration .deco-2')
            decoElements.forEach((element, i) => {
                const [ , , originalIndex] = filteredLineupData[i] // Use originalIndex from filteredLineupData
                const colorToUse = STAGE_COLORS[originalIndex] // Now using originalIndex
    
                const duration = 2 + Math.random() * 3 // Random duration between 2 and 5 seconds
                const delay = Math.random() * 5 // Random delay up to 5 seconds
                element.style.animationDuration = `${duration}s`
                element.style.animationDelay = `${delay}s`
                element.style.animationPlayState = 'running'
    
                // Set the shadow colors using the correct colors
                const dimColor = hexToRgbA(colorToUse, 0.2) // Dimmer version
                const brightColor = hexToRgbA(colorToUse, 0.85) // Brighter version
                element.style.setProperty('--shadow-color-dim', dimColor)
                element.style.setProperty('--shadow-color-bright', brightColor)
            })
        }
        applyBlinkingEffect()
    
        // Reapply the effect when stages change
        return () => {
            const decoElements = document.querySelectorAll('.stage-header-decoration .deco-2')
            decoElements.forEach(element => {
                element.style.animationPlayState = 'paused'
            })
        }
    }, [lineupData]) 

    return (
        <React.Fragment>
            <div className="set-time-schedule">
                <div 
                    className={`sticky-header-day`}
                    onClick={incrementSelectedDayIndex}
                >
                    {festival.daysAndTimes[selectedDayIndex].day.substring(0, 3)}
                </div>
                <Timeline 
                    timelineHeight={timelineHeight} 
                    start={festival.daysAndTimes[selectedDayIndex].start}
                    end={festival.daysAndTimes[selectedDayIndex].end}
                    numStages={lineupData.length}
                />
                <div className="main-stages-wrapper" ref={mainStagesRef}>
                    <div className={`stage-headers-wrapper`} ref={stageHeadersRef}>
                        {filteredLineupData.map(([stageName, artists, originalIndex]) => {
                            const deco1Left = columnWidthSet / 3.5
                            const deco2Left = columnWidthSet / 4.5
                            const deco3Left = columnWidthSet / 4.5
                            const deco1Width = columnWidthSet - (2 * deco1Left)
                            const deco2Width = columnWidthSet - (2 * deco2Left)
                            const deco3Width = columnWidthSet - (2 * deco3Left)
                            
                            const stageHeaderTextLRPadding = 4
                            const stageHeaderTextWidth = columnWidthSet - (2 * stageHeaderTextLRPadding)

                            let colorToUse = (festival.id === "elements24") ? ELEMENTS24_STAGE_COLORS[originalIndex] : STAGE_COLORS[originalIndex]

                            return (
                                <div key={stageName} className="stage-single-header" style={{ width: `${columnWidthSet}px` }}>
                                    <div className="stage-header-text" style={{ 
                                        width: `${stageHeaderTextWidth}px`,
                                        paddingLeft: `${stageHeaderTextLRPadding}px`,
                                        paddingRight: `${stageHeaderTextLRPadding}px`,
                                        fontSize: `${Math.min(Math.max(blockFontSizeSet - 1, 8), 12)}px`,
                                        lineHeight: `${Math.min(Math.max(blockFontSizeSet + 1, 10), 14)}px`
                                    }}>{stageName}</div>
                                    <div className="stage-header-decoration">
                                        <div className="deco-1" style={{ 
                                            boxShadow: `0 6px 30px 4px rgba(255,255,255,0.2)`,
                                            left: `${deco1Left}px`,
                                            width: `${deco1Width}px`,
                                        }}></div>
                                        <div className="deco-2" style={{ 
                                            boxShadow: `0 15px 40px 3px ${hexToRgbA(colorToUse, 0.7)}`,
                                            left: `${deco2Left}px`,
                                            width: `${deco2Width}px`
                                        }}></div>
                                        <div className="deco-3" style={{ 
                                            boxShadow: `0 -10px 22px 8px rgba(0,0,0,0.4)`,
                                            backgroundColor: `${colorToUse}`,
                                            left: `${deco3Left}px`,
                                            width: `${deco3Width}px`
                                        }}></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="stages-wrapper">
                        {filteredLineupData.map(([stageName, artists, originalIndex]) => {
                            let colorToUse = (festival.id === "elements24") ? ELEMENTS24_STAGE_COLORS[originalIndex] : STAGE_COLORS[originalIndex]

                            return (
                                <Stage 
                                    // General
                                    key={stageName}
                                    scheduleType={scheduleType}
                                    scheduleId={scheduleId}
                                    currentUser={currentUser}
                                    isCreator={isCreator}
                                    festival={festival}
                                    stageName={stageName} 
                                    selectedDayIndex={selectedDayIndex}
                                    artists={artists}
                                    timelineHeight={timelineHeight}
                                    stageColor={colorToUse}
                                    onMyScheduleBlockClick={handleBlockClickInternal}
                                    blockSelections={blockSelections}
                                    localBlockSelections={localBlockSelections}
                                    festivalHeatmapData={festivalHeatmapData}
                                    heatmapType={heatmapType}
                                    heatmapColor={heatmapColor}
                                    groupData={groupData}
                                    membersData={membersData}
                                    handleGroupBlockClick={handleGroupBlockClickInternal}
                                    groupSelections={groupSelections}
                                    // recs={recs}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SetTimeSchedule