import React from 'react'

// Helpers
import { useDebouncedUpdates, useDebouncedUpdatesGroup, useImmediateUpdates, encodeKey } from '../../utils/scheduleHelpers'

// Components
import Block2 from './Block2'

function BlockSchedule({ 
    scheduleType,
    currentUser,
    festival, 
    lineupData,
    isCreator, 
    selectedDayIndex, 
    setSaveStatus, 
    scheduleId, 
    blockSelections, 
    setBlockSelections,
    localBlockSelections,
    setLocalBlockSelections,
    heatmapType,
    festivalHeatmapData,
    heatmapColor,
    groupData,
    membersData,
    groupId,
    groupSelections,
    setGroupSelections
}) {
    let lineupType = festival.hasDay ? "day" : "artists"

    const { handleBlockClick } = useImmediateUpdates(scheduleId, festival.id, currentUser?.uid, setSaveStatus)
    const { handleGroupBlockClick } = useDebouncedUpdatesGroup(groupId)

    const daysOfWeek = festival.daysAndTimes.map(dayObj => dayObj.day)
    const selectedDay = daysOfWeek[selectedDayIndex]

    // --------------------------- 🖱️ Handle block clicks! ---------------------------
    const handleBlockClickInternal = (artistName, day) => {
        let key = day ? `${day.toUpperCase()}-${artistName.toUpperCase()}` : `${artistName.toUpperCase()}`
        const encodedKey = encodeKey(key)

        if (currentUser) {
            if (isCreator) setSaveStatus('Saving...')

            setBlockSelections(prev => {
                const oldCount = prev[encodedKey] || 0
                const newCount = oldCount + 1 > 2 ? 0 : oldCount + 1
                handleBlockClick(encodedKey, newCount, oldCount)
                return { ...prev, [encodedKey]: newCount }
            })
        } else {
            setLocalBlockSelections(prev => {
                const newCount = (prev[encodeKey(key)] || 0) + 1 > 2 ? 0 : (prev[encodeKey(key)] || 0) + 1
                return { ...prev, [encodeKey(key)]: newCount }
            })
        }
    }

    const handleGroupBlockClickInternal = (artistName, day) => {
        let key = day ? `${day.toUpperCase()}-${artistName.toUpperCase()}` : `${artistName.toUpperCase()}`

        if (currentUser && currentUser.uid in membersData) {
            setGroupSelections(prev => {
                const newValue = (!prev[encodeKey(key)] || false)
                handleGroupBlockClick(encodeKey(key), newValue)
                return { ...prev, [encodeKey(key)]: newValue }
            })
        }
    }

    // ----------------------------- Group artists by tier -----------------------------
    const groupedArtists = {}
    if (lineupData) {
        lineupData.forEach(artist => {
            if (lineupType === "day" && artist.Day !== selectedDay) return

            if (!groupedArtists[artist.Tier]) groupedArtists[artist.Tier] = []
            groupedArtists[artist.Tier].push(artist)
        })
    }

    const sortedTiers = Object.keys(groupedArtists).sort((a, b) => b - a)

    // ----------------------------- Define blocks for each tier -----------------------------
    const renderBlocksForTier = (tier) => {
        return groupedArtists[tier].map((artist, index) => {
            // Set block key based on lineup type
            let blockKey = ""
            if (lineupType === "day") {
                blockKey = `${artist.Day.toUpperCase()}-${artist.Artist.toUpperCase()}`
            } else {
                blockKey = `${artist.Artist.toUpperCase()}`
            }

            // For "userSchedule" scheduleType
            let clickCount = blockSelections[encodeKey(blockKey)] || 0
            if (!currentUser && scheduleId === "new") clickCount = localBlockSelections[encodeKey(blockKey)] || 0

            // For "festivalHeatmap" or "group" scheduleType
            let blockHeatmapData
            if (scheduleType === "festivalHeatmap" && festivalHeatmapData) {
                blockHeatmapData = festivalHeatmapData[encodeKey(blockKey)] || null
            } else if (scheduleType === "group" && groupData?.heatmap) {
                blockHeatmapData = groupData.heatmap[encodeKey(blockKey)] || null
            }

            return (
                <Block2
                    // General
                    key={blockKey}
                    blockKey={blockKey}
                    scheduleType={scheduleType}
                    festival={festival}
                    artist={artist}
                    // User schedule
                    clickCount={clickCount}
                    onMyScheduleBlockClick={() => handleBlockClickInternal(artist.Artist, artist.Day)}
                    isCreator={isCreator}
                    // Heatmap — Festival or Group
                    isHeatmap={scheduleType === "festivalHeatmap" || scheduleType === "group"}
                    heatmapType={heatmapType}
                    heatmapColor={heatmapColor}
                    heatmapData={blockHeatmapData}
                    // Group heatmap data
                    maxTotalVotes={groupData?.heatmap ? groupData.heatmap.maxTotalVotes : 1}
                    maxTotalMustSee={groupData?.heatmap ? groupData.heatmap.maxTotalMustSee : 1}
                    membersData={membersData}
                    handleGroupBlockClick={() => handleGroupBlockClickInternal(artist.Artist, artist.Day)}
                    groupSelected={groupSelections[encodeKey(blockKey)] === true}
                />
            )
        })
    }

    const allBlockSections = sortedTiers.map((tier) => {
        let tierText = `${festival.numTiers - tier + 1} / ${festival.numTiers}`
        if (festival.name === "Elements '24") tierText = `1 / 1`

        return (
            <div key={tier} className="block-schedule-section">
                <div className="block-schedule-section-header-wrapper">
                    {/* <div className="block-schedule-section-header">Artists</div> */}
                    <div className="block-schedule-section-header-tier">
                        {/* <div className="block-schedule-section-header">|</div> */}
                        <div className="block-schedule-section-header tier-number">Tier {tierText}</div>
                    </div>
                </div>
                <div className="block-schedule">
                    {renderBlocksForTier(tier)}
                </div>
            </div>
        )
    })

    return (
        <div className="block-schedule-sections-wrapper">
            <div className="block-schedule-helper-wrapper">
                {scheduleType === "userSchedule" && currentUser && <p>Once set times are released, your selections below will automatically be transferred to the set time schedule!</p>}
                {/* {festival.numTiers > 1 && <p>Note: The number by each section of artists is an attempt to split the artists in the same way they're presented by the event organizer.</p>} */}
            </div>
            <div className="block-schedule-all-sections">
                {allBlockSections}
            </div>
            {!lineupData && <div>Loading...</div>}
        </div>
    )
}

export default BlockSchedule
