import React, { useState, useEffect, useRef } from 'react'
import { ref, onValue, push, set } from 'firebase/database'
import { database } from '../../config/firebase-config'
import Comment from './Comment'
import { IconMessage, IconArrowUp } from '@tabler/icons-react'
import './CommentsSection.css'
import { fetchUserDisplayDataById } from '../../services/firebase/databaseService'

const CommentsSection = ({ announcementID, currentUser, totalComments }) => {
    const [comments, setComments] = useState([])
    const [newComment, setNewComment] = useState("")
    const [isCommentsVisible, setIsCommentsVisible] = useState(false)

    const textareaRef = useRef(null)
    const COMMENT_CHAR_LIMIT = 500

    useEffect(() => {
        if (isCommentsVisible) {
            const commentsRef = ref(database, `announcements/${announcementID}/comments`)
            onValue(commentsRef, async (snapshot) => {
                const data = snapshot.val() || {}
                const enrichedComments = await Promise.all(Object.keys(data).map(async (commentID) => {
                    const commentData = data[commentID]
                    const user = await fetchUserDisplayDataById(commentData.userID)
                    
                    const replies = commentData.replies
                        ? await Promise.all(
                            Object.keys(commentData.replies)
                                .map(async (replyID) => {
                                    const replyData = commentData.replies[replyID]
                                    const replyUser = await fetchUserDisplayDataById(replyData.userID)
                                    return {
                                        ...replyData,
                                        id: replyID,
                                        userDisplayName: replyUser?.displayName || 'Unknown User',
                                        userPicture: replyUser?.picture || '',
                                        upvoteCount: replyData.upvotes ? Object.keys(replyData.upvotes).length : 0,
                                        userHasUpvoted: replyData.upvotes && currentUser.uid in replyData.upvotes
                                    }
                                })
                        ).then((replies) => replies.sort((a, b) => b.timestamp - a.timestamp))
                        : []
    
                    return {
                        ...commentData,
                        id: commentID,
                        userDisplayName: user?.displayName || 'Unknown User',
                        userPicture: user?.picture || '',
                        upvoteCount: commentData.upvotes ? Object.keys(commentData.upvotes).length : 0,
                        userHasUpvoted: commentData.upvotes && currentUser.uid in commentData.upvotes,
                        replies
                    }
                })).then(comments => comments.sort((a, b) => b.timestamp - a.timestamp))
                setComments(enrichedComments)
            })
        }
    }, [isCommentsVisible, announcementID, currentUser, fetchUserDisplayDataById])
    
    useEffect(() => {
        if (totalComments === 0) setIsCommentsVisible(false)
    }, [totalComments])

    const handleCommentSubmit = () => {
        if (newComment === "") return
        if (newComment.trim()) {
            const commentsRef = ref(database, `announcements/${announcementID}/comments`)
            const newCommentRef = push(commentsRef)
            set(newCommentRef, {
                userID: currentUser.uid,
                comment: newComment,
                timestamp: Date.now()
            })
            setNewComment("")
            textareaRef.current.blur()
            setIsCommentsVisible(true)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            handleCommentSubmit()
        }
    }

    const handleFocusTextarea = () => {
        textareaRef.current?.focus()
    }

    let commentsToggleText = `Show ${totalComments}`
    if (isCommentsVisible) commentsToggleText = "Hide"
    let commentsCountText = totalComments === 1 ? "comment" : "comments"

    let commentIndicatorRow = (
        <div className="comments-toggle no-comments" onClick={handleFocusTextarea}>
            <IconMessage size={16} strokeWidth={1.5} />
            <p>Be the first to comment!</p>
        </div>
    )
    if (totalComments > 0) commentIndicatorRow = (
        <div className="comments-toggle" onClick={() => setIsCommentsVisible(!isCommentsVisible)}>
            <IconMessage size={16} strokeWidth={1.5} />
            <p>{commentsToggleText} {commentsCountText}</p>
        </div>
    )

    return (
        <div className="comments-section">
            {commentIndicatorRow}
            <div className={`comments-wrapper ${isCommentsVisible ? 'open' : 'closed'}`}>
                <div className="comment-items-wrapper">
                    {comments.map(comment => (
                        <Comment
                            key={comment.id}
                            comment={comment}
                            announcementID={announcementID}
                            currentUser={currentUser}
                        />
                    ))}
                </div>
            </div>
            <div className="new-comment-wrapper">
                <textarea 
                    ref={textareaRef}
                    className="input-default textarea-default textarea-new-comment"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Write a comment..."
                    maxLength={COMMENT_CHAR_LIMIT}
                    onKeyDown={handleKeyDown}
                />
                <div 
                    className={`icon-comment-send-wrapper ${newComment.length > 0 ? 'active' : ''}`}
                    onClick={handleCommentSubmit}
                >
                    <IconArrowUp 
                        size={20} 
                        strokeWidth={2} 
                        className="icon-comment-send"
                    />
                </div>
            </div>
        </div>
    )
}

export default CommentsSection
