import React from 'react'
import { IconHeartFilled, IconArrowNarrowRight, IconHeart } from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'
import { formatDateRange } from '../../utils/helpers'
import { useFavoriteFestivals } from '../../context/FavoriteFestivalContext'

const NavShortcuts = React.memo(({ festivalData, currentUser }) => {
    const { favorites, addFavorite, removeFavorite } = useFavoriteFestivals()

    if (!festivalData) {
        return <div className="nav-bottom"><h4>Shortcuts</h4></div>
    }

    const handleHeartClick = async (festivalId) => {
        const isFavorite = favorites.includes(festivalId)
        if (isFavorite) {
            await removeFavorite(currentUser.uid, festivalId)
        } else {
            await addFavorite(currentUser.uid, festivalId)
        }
    }

    const upcomingFestivals = Object.values(festivalData).filter(festival => !festival.passed);

    const sortedFavorites = favorites
        .map(festivalId => ({ id: festivalId, ...festivalData[festivalId] }))
        .sort((a, b) => new Date(a.start) - new Date(b.start))

    const nonFavorites = upcomingFestivals
        // .filter(festival => !favorites.includes(festival.id))
        .sort((a, b) => new Date(a.start) - new Date(b.start));

    return (
        <div className="nav-bottom-shortcuts">
            <h4 className="nav-shortcuts-header-text">Shortcuts</h4>
            <div className="nav-section">
                <h5 className="nav-shortcuts-header-text">Favorites</h5>
                <div className="nav-favorites-rows">
                    {sortedFavorites.length > 0
                        ? sortedFavorites.map(festival => {
                            const formattedDateRange = formatDateRange(festival.start, festival.end)
                            const isFavorite = favorites.includes(festival.id)

                            return (
                                <NavLink
                                    key={festival.id}
                                    to={`/${festival.id}`}
                                    className="nav-favorites-link"
                                >
                                    <div className="nav-favorite-content">
                                        <div className="icon-favorite-container" onClick={(e) => { e.preventDefault(); handleHeartClick(festival.id) }}>
                                            {isFavorite ? (
                                                <IconHeartFilled size={16} strokeWidth={1.5} className="nav-icon-heart-filled" />
                                            ) : (
                                                <IconHeart size={16} strokeWidth={1.5} className="nav-icon-heart" />
                                            )}
                                        </div>
                                        <div className="nav-shortcut-icontext">
                                            <div className="nav-festival-details-wrapper">
                                                <p className="nav-festival-details-name">{festival.name}</p>
                                                <p className="nav-festival-details-date">{formattedDateRange}</p>
                                            </div>
                                            <IconArrowNarrowRight size={20} strokeWidth={1.5} className="nav-arrow-right"/>
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        })
                        : <p className="nav-favorites-empty opacity50"><i>No favorites added</i></p>
                    }
                </div>
            </div>
            <div className="nav-section">
                <h5 className="nav-shorctuts-section nav-shortcuts-header-text">Upcoming festivals</h5>
                <div className="nav-favorites-rows">
                    {nonFavorites.map(festival => {
                        const formattedDateRange = formatDateRange(festival.start, festival.end)
                        const isFavorite = favorites.includes(festival.id)

                        return (
                            <NavLink
                                key={festival.id}
                                to={`/${festival.id}`}
                                className="nav-favorites-link"
                            >
                                <div className="nav-favorite-content">
                                    <div className="icon-favorite-container" onClick={(e) => { e.preventDefault(); handleHeartClick(festival.id) }}>
                                        {isFavorite ? (
                                            <IconHeartFilled size={16} strokeWidth={1.5} className="nav-icon-heart-filled" />
                                        ) : (
                                            <IconHeart size={16} strokeWidth={1.5} className="nav-icon-heart" />
                                        )}
                                    </div>
                                    <div className="nav-shortcut-icontext">
                                        <div className="nav-festival-details-wrapper">
                                            <p className="nav-festival-details-name">{festival.name}</p>
                                            <p className="nav-festival-details-date">{formattedDateRange}</p>
                                        </div>
                                        <IconArrowNarrowRight size={20} strokeWidth={1.5} className="nav-arrow-right"/>
                                    </div>
                                </div>
                            </NavLink>
                        )
                    })}
                </div>
            </div>
        </div>
    )
})

export default NavShortcuts
