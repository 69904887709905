import React, { createContext, useState, useContext, useCallback, useEffect } from 'react'
import { getOrCreateScheduleForFestival } from '../services/firebase/databaseService'
import { useLocation } from 'react-router-dom'

const FestivalContext = createContext()

export const useFestival = () => useContext(FestivalContext)

export const FestivalProvider = ({ children, currentUser, festivalData }) => {
    const [currentFestival, setCurrentFestival] = useState(null)
    const [userScheduleId, setUserScheduleId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const validFestivalIds = Object.keys(festivalData)

    const location = useLocation()
    const pathParts = location.pathname.split('/')
    const maybeFestivalId = pathParts[1]

    const setFestival = useCallback(async (festivalId) => {
        if (!validFestivalIds.includes(festivalId) || festivalId === currentFestival?.id) {
            return
        }

        setIsLoading(true)
        if (festivalData[festivalId]) {
            setCurrentFestival(festivalData[festivalId])
            
            if (currentUser) {
                try {
                    const scheduleId = await getOrCreateScheduleForFestival(currentUser.uid, festivalId)
                    setUserScheduleId(scheduleId)
                } catch (error) {
                    console.error("Error getting/creating user schedule:", error)
                }
            }
        } else {
            console.error(`Festival data not available for id: ${festivalId}`)
            setCurrentFestival(null)
            setUserScheduleId(null)
        }
        setIsLoading(false)
    }, [currentUser, currentFestival])

    const clearFestival = useCallback(() => {
        setCurrentFestival(null)
        setUserScheduleId(null)
    }, [])

    useEffect(() => {
        if (validFestivalIds.includes(maybeFestivalId)) {
            setFestival(maybeFestivalId)
        } else {
            // Clear the currentFestival when navigating away from a festival page
            setCurrentFestival(null)
            setUserScheduleId(null)
        }
    }, [maybeFestivalId, setFestival])

    const value = {
        currentFestival,
        userScheduleId,
        isLoading,
        setFestival,
        clearFestival
    }

    return (
        <FestivalContext.Provider value={value}>
            {children}
        </FestivalContext.Provider>
    )
}