import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import { IconX } from '@tabler/icons-react'
import './Modal.css'
import '../Nav/SignupLoginModal.css'

Modal.setAppElement('#root')

const RequestModal = ({ isOpen, onRequestClose, requestType, currentUser, origin }) => {
    const [messageType, setMessageType] = useState(requestType)
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [isClosing, setIsClosing] = useState(false)
    const [buttonText, setButtonText] = useState('Submit')

    useEffect(() => {
        if (isOpen) {
            setIsClosing(false)
        } else {
            resetForm()
        }
    }, [isOpen])

    const handleClose = () => {
        setIsClosing(true)
        setTimeout(onRequestClose, 300)
    }

    const resetForm = () => {
        setMessageType(requestType)
        setEmail('')
        setSubject('')
        setMessage('')
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setButtonText('Submitting...')
        const formData = { messageType, email, subject, message, currentUser, origin }

        try {
            const response = await fetch('https://createtrellocard-5npw5akl7a-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })

            if (response.ok) {
                setButtonText('🎉 Submitted!')
                setTimeout(() => {
                    setMessageType(requestType)
                    setEmail('')
                    setSubject('')
                    setMessage('')
                    handleClose()
                }, 1000)
            } else {
                setButtonText('Submit')
                throw new Error('Failed to submit feedback')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className={`modal-wrapper ${isClosing ? 'modal-wrapper-close' : ''}`}
            overlayClassName={`modal-overlay ${isClosing ? 'modal-overlay-close' : ''}`}
        >
            <div className="modal-request">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Send me a message</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={handleClose} />
                </div>
                <form onSubmit={handleSubmit} className="modal-content">
                    <div className="intro-wrapper">
                        <p className="intro-text">Hey! Thanks for checking this site out — if you have any requests, questions, or feedback, I'd love to hear it. Submitting this will send me a notif and I'll try to address it / get back to you as soon as I can. — James</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="messageType" className="input-label">Message type <span className="input-required">*</span></label>
                        <select
                            id="messageType"
                            className="input-default input-select"
                            value={messageType}
                            onChange={(e) => setMessageType(e.target.value)}
                            required
                            placeholder='Select message type'
                        >
                            <option value="General feedback">General feedback</option>
                            <option value="Feature request">Feature request</option>
                            <option value="Festival request">Festival request</option>
                            <option value="Lineup/artist/time error">Lineup/artist/time error</option>
                            <option value="Something's fucky">Something's fucky</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="input-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="input-default"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="(Optional) I'll send a response here, if applicable"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="input-label">Subject <span className="input-required">*</span></label>
                        <input
                            type="text"
                            id="subject"
                            className="input-default"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Enter subject"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="input-label">Details</label>
                        <textarea
                            id="message"
                            className="input-default textarea-default"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            maxLength={1000}
                            placeholder='(Optional) Enter additional details here'
                        />
                        <div className="textarea-char-count">{message?.length || 0}/{1000}</div>
                    </div>
                    <div className="modal-btn-signup-wrapper">
                        <button type="submit" className="btn-primary">
                            {buttonText}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default RequestModal