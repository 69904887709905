import React from 'react'
import Modal from 'react-modal'
import { IconX } from '@tabler/icons-react'
import './Modal.css'
import '../Nav/SignupLoginModal.css'

Modal.setAppElement('#root')

const HeatmapTypesModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal-wrapper"
            overlayClassName="modal-overlay"
        >
            <div className="modal-heatmap-types">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Learn about heatmap types!</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={onRequestClose} />
                </div>
                <div className="modal-content">
                    <div className="intro-wrapper">
                        <p className="intro-text">There are 3 heatmap types: All, Must see, and Must see %.</p>
                        <div className="heatmap-sample-wrapper">
                            <div className="heatmap-sample" />
                            <div className="heatmap-sample-labels">
                                <div className="heatmap-sample-label">Less interest</div>
                                <div className="heatmap-sample-label">More interest</div>
                            </div>
                        </div>
                    </div>
                    <div className="heatmap-type-wrapper">
                        <p className="heatmap-type-title">All</p>
                        <div className="heatmap-type-description-wrapper">
                            <p className="heatmap-type-description-tagline">See what's poppin'.</p>
                            <p className="heatmap-type-description">This tallies up all votes for an artist, both "Interested" <i>and</i> "Must see," and displays each artist's demand relative to other artists.</p>
                        </div>
                    </div>
                    <div className="heatmap-type-wrapper">
                        <p className="heatmap-type-title">Must see</p>
                        <div className="heatmap-type-description-wrapper">
                            <p className="heatmap-type-description-tagline">Beat the crowds.</p>
                            <p className="heatmap-type-description">Basically the same as "All votes," but only tallies up the "Must see" votes. This should provide a better view of where exactly the crowd will be.</p>
                        </div>
                    </div>
                    <div className="heatmap-type-wrapper">
                        <p className="heatmap-type-title">Must see %</p>
                        <div className="heatmap-type-description-wrapper">
                            <p className="heatmap-type-description-tagline">Discover new artists.</p>
                            <p className="heatmap-type-description">This is calculated by dividing the total number of "Must see"s by the total amount of votes an artist has. This provides insight into <i>how much</i> people want to see specific artists.</p>
                            <p className="heatmap-type-description">A big name might have the most total votes but a lower "Must see %" than a smaller artist with a devoted fanbase.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default HeatmapTypesModal