import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { fetchUserDataByDisplayName, fetchBadgeOrder, updateUserProfile } from '../../services/firebase/databaseService'
import { IconBrandSpotify, IconBrandInstagram, IconBrandSnapchat, IconCalendarSmile, IconPencil, IconLogout, IconHeartFilled, IconPlus } from '@tabler/icons-react'
import { signOut, updateProfile } from 'firebase/auth'
import { auth, storage } from '../../config/firebase-config'
import AvatarEditor from 'react-avatar-editor'
import { uploadString, getDownloadURL, ref as storageRef } from 'firebase/storage'
import { useDropzone } from 'react-dropzone'
import NotFoundPage from '../General/404'
import './Profile.css'
import RequestModal from '../General/RequestModal'

const badgeLabels = {
    "v0": "Here from day 0 <3",
    "OG": "One of the OGs"
}

function Profile({ currentUser, festivalData, setProfilePicture }) {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [userNotFound, setUserNotFound] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const { userDisplayName } = useParams()
    const [userData, setUserData] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [socials, setSocials] = useState({})
    const [bio, setBio] = useState(null)
    const [badgeOrder, setBadgeOrder] = useState([])
    const [editingSocials, setEditingSocials] = useState({})
    const [editingBio, setEditingBio] = useState(null)

    const [image, setImage] = useState(null)
    const [imageChanged, setImageChanged] = useState(false)
    const [noClick, setNoClick] = useState(false)
    const [scale, setScale] = useState(1)
    const [imageError, setImageError] = useState(null)
    const editorRef = useRef(null)

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    const BIO_CHAR_LIMIT = 500
    const maxSize = 20 * 1024 * 1024 // 20MB
    const allowedFileTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/webp',
        'image/heif',
        'image/heic',
        'image/tiff'
    ]

    useEffect(() => {
        async function loadData() {
            setIsLoading(true)
            const user = await fetchUserDataByDisplayName(userDisplayName)
            if (user) {
                setUserData(user)
                setSocials(user.socials || { x: null, instagram: null, snapchat: null, spotify: null })
                setBio(user.bio || null)
                setEditingSocials(user.socials || { x: null, instagram: null, snapchat: null, spotify: null })
                setEditingBio(user.bio || null)
                setImage(user.picture ? user.picture.highRes : null)
                setUserNotFound(false)
            } else {
                setUserNotFound(true)
            }
            const orderArray = await fetchBadgeOrder()
            setBadgeOrder(orderArray)
            setIsLoading(false)
        }
        loadData()
    }, [userDisplayName])
    
    
    const handleSocialChange = (key, value) => {
        setEditingSocials(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value))
    }
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        setImage(null)
        setScale(1)
        if (!allowedFileTypes.includes(file.type)) {
            setImageError('Invalid file type')
            setNoClick(false)
            return
        }
        if (file.size > maxSize) {
            setImageError('File size exceeds the maximum limit of 20MB.')
            setNoClick(false)
            return
        }
        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0])
            setNoClick(true)
            setImageError(null)
            setImageChanged(true)
        }
    }
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick,
        noKeyboard: true,
    })

    const handleSave = async () => {
        setIsSaving(true)
        const cleanSocials = Object.fromEntries(
            Object.entries(editingSocials).map(([key, value]) => [key, value ? value.trim() === '' ? null : value.trim() : null])
        )
        const cleanBio = editingBio ? editingBio.trim() === '' ? null : editingBio.trim() : null
    
        const updates = {
            socials: cleanSocials,
            bio: cleanBio,
        }
    
        if (imageChanged) {
            if (editorRef.current) {
                // Create high-res image (512x512)
                const highResCanvas = document.createElement('canvas')
                highResCanvas.width = 512
                highResCanvas.height = 512
                const highResContext = highResCanvas.getContext('2d')
                highResContext.drawImage(editorRef.current.getImage(), 0, 0, 512, 512)
                const highResDataUrl = highResCanvas.toDataURL()
        
                const highResRef = storageRef(storage, `users/${currentUser.uid}/high_res.jpg`)
                await uploadString(highResRef, highResDataUrl, 'data_url')
                const highResUrl = await getDownloadURL(highResRef)
        
                // Create low-res image (64x64)
                const lowResCanvas = document.createElement('canvas')
                lowResCanvas.width = 64
                lowResCanvas.height = 64
                const lowResContext = lowResCanvas.getContext('2d')
                lowResContext.drawImage(editorRef.current.getImage(), 0, 0, 64, 64)
                const lowResDataUrl = lowResCanvas.toDataURL()
        
                const lowResRef = storageRef(storage, `users/${currentUser.uid}/low_res.jpg`)
                await uploadString(lowResRef, lowResDataUrl, 'data_url')
                const lowResUrl = await getDownloadURL(lowResRef)
        
                updates.picture = { highRes: highResUrl, lowRes: lowResUrl }
        
                await updateProfile(currentUser, {
                    photoURL: highResUrl
                })

                setProfilePicture(highResUrl)
                navigate(`/u/${currentUser.displayName}`, { replace: true })
            }
        }   
    
        await updateUserProfile(currentUser.uid, updates)
        setSocials(cleanSocials)
        setBio(cleanBio)
        setUserData({ ...userData, ...updates })
        setImageChanged(false)
        setImageError(null)
        setScale(1)
        setNoClick(false)
        setIsEditing(false)
        setIsSaving(false)
    }

    const handleCancel = () => {
        if (isSaving) return
        setEditingSocials(socials)
        setEditingBio(bio)
        setImage(userData.picture ? userData.picture.highRes : null)
        setImageChanged(false)
        setImageError(null)
        setScale(1)
        setNoClick(false)
        setIsEditing(false)
    }

    const handleSignOut = async () => {
        try {
            await signOut(auth)
            navigate('/')
        } catch (error) {
            console.error('Error signing out:', error)
        }
    }

    const isCurrentUser = (currentUser && currentUser.displayName === userDisplayName)
    const handleFocus = (e) => e.target.select()

    const isSaveDisabled = !image || (
        editingSocials.spotify === socials.spotify &&
        editingSocials.instagram === socials.instagram &&
        editingSocials.snapchat === socials.snapchat &&
        editingSocials.x === socials.x &&
        editingBio === bio &&
        !imageChanged
    )

    if (isLoading) {
        return (
            <div className="festivals-wrapper">
                <div className="loading-full-percent">
                    <div className="logo-circle" />
                    <div className="loading-text">Loading profile...</div>
                </div>
            </div>
        )
    }

    if (userNotFound) {
        return <NotFoundPage subtext={"This user does not exist."} currentUser={currentUser} />
    }

    let profileInfo = <></>
    if (isEditing) {
        profileInfo = (
            <div className="non-lights-wrapper">
                <div className="profile-edit-photo">
                    <div className="dropzone-wrapper">
                        <div {...getRootProps({ className: `profile-dropzone ${image ? 'no-hover' : ''}` })}>
                            <input {...getInputProps()} />
                            {image ? (
                                <div className="profile-circular-container">
                                    <AvatarEditor
                                        ref={editorRef}
                                        image={image}
                                        width={104}
                                        height={104}
                                        border={0}
                                        borderRadius={104}
                                        color={[255, 255, 255, 0.6]}
                                        scale={scale}
                                        rotate={0}
                                    />
                                </div>
                            ) : (
                                <IconPlus size={48} strokeWidth={0.5} className="icon-plus-dropzone" />
                            )}
                        </div>
                    </div>
                    {imageChanged && 
                        <div className="signup-slider-container">
                            <p className="body-s">1x</p>
                            <input
                                id="scale-slider"
                                type="range"
                                value={scale}
                                min="1"
                                max="5"
                                step="0.01"
                                onChange={handleScaleChange}
                                style={{ width: '100%' }}
                            />
                            <p className="body-s">5x</p>
                        </div>
                    }
                    <div className="txt-btn-photo-action" onClick={open}>
                        <p>{image ? 'Change photo' : 'Add photo'}</p>
                    </div>
                    {imageError && <p className="error-message">{imageError}</p>}
                </div>
                <div className="profile-displayName">{userData.displayName}</div>
                <div className="profile-info">
                    <div className="profile-editing-wrapper">
                        <div className="profile-social-inputs">
                            <div className="profile-social-edit-wrapper">
                                <IconBrandSpotify className="profile-social-editing-icon" size={24} strokeWidth={1.5} />
                                <p className="opacity50">spotify.com/user/</p>
                                <input
                                    type="text"
                                    className="input-default input-social"
                                    value={editingSocials.spotify}
                                    onFocus={handleFocus}
                                    onChange={(e) => handleSocialChange('spotify', e.target.value)}
                                    placeholder="username or ID"
                                />
                            </div>
                            <div className="profile-social-edit-wrapper">
                                <IconBrandInstagram className="profile-social-editing-icon" size={24} strokeWidth={1.5} />
                                <p className="opacity50">instagram.com/</p>
                                <input
                                    type="text"
                                    className="input-default input-social"
                                    value={editingSocials.instagram}
                                    onFocus={handleFocus}
                                    onChange={(e) => handleSocialChange('instagram', e.target.value)}
                                    placeholder="username"
                                />
                            </div>
                            <div className="profile-social-edit-wrapper">
                                <IconBrandSnapchat className="profile-social-editing-icon" size={24} strokeWidth={1.5} />
                                <p className="opacity50">snapchat.com/add/</p>
                                <input
                                    type="text"
                                    className="input-default input-social"
                                    value={editingSocials.snapchat}
                                    onFocus={handleFocus}
                                    onChange={(e) => handleSocialChange('snapchat', e.target.value)}
                                    placeholder="username"
                                />
                            </div>
                        </div>
                        <div className="profile-section profile-bio">
                            <div className="profile-section-header">
                                <div className="profile-header-line-left" />
                                <h5 className="profile-section-title">Bio</h5>
                                <div className="profile-header-line-right" />
                            </div>
                            <div className="textarea-with-char-count">
                                <textarea
                                    className="input-default textarea-default profile-bio-textarea"
                                    placeholder="Enter bio"
                                    value={editingBio}
                                    onChange={(e) => setEditingBio(e.target.value)}
                                    maxLength={BIO_CHAR_LIMIT}
                                />
                                <div className="textarea-char-count">{editingBio?.length || 0}/{BIO_CHAR_LIMIT}</div>
                            </div>
                        </div>
                        <div className="profile-editing-actions">
                            <button className="btn-primary" onClick={handleSave} disabled={isSaveDisabled}>
                                {isSaving ? "Saving..." : "Save"}
                            </button>
                            <button className="btn-with-icon danger-btn" onClick={handleCancel}>
                                <p>Cancel</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        const sortedFavorites = userData.favorites
            ? Object.keys(userData.favorites)
                .map(festivalId => ({ festivalId, ...festivalData[festivalId] }))
                .sort((a, b) => new Date(a.start) - new Date(b.start))
            : []
        profileInfo = (
            <div className="non-lights-wrapper">
                <img src={userData.picture.highRes} alt="Profile" className="profile-picture" />
                <div className="profile-badges-wrapper">
                    {badgeOrder.filter(badge => userData.badges && userData.badges[badge]).map(badge => (
                        <div 
                            key={badge} 
                            className="profile-badge"
                            data-tooltip-id="tt"
                            data-tooltip-content={badgeLabels[badge]}
                        >
                            {badge}
                        </div>
                    ))}
                </div>
                <div className="profile-displayName">{userData.displayName}</div>
                <div className="profile-info">
                    <div className="profile-info-wrapper">
                        <div className="profile-social-icons-wrapper">
                            {socials.spotify && 
                                <a 
                                    className="profile-social-icon-link" 
                                    href={`https://open.spotify.com/user/${socials.spotify}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    data-tooltip-id="tt"
                                    data-tooltip-content="Open Spotify"
                                >
                                    <IconBrandSpotify className="profile-social-icon icon-spotify" size={24} strokeWidth={1.5} />
                                </a>
                            }
                            {socials.instagram && 
                                <a 
                                    className="profile-social-icon-link" 
                                    href={`https://instagram.com/${socials.instagram}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    data-tooltip-id="tt"
                                    data-tooltip-content="Open Instagram"
                                >
                                    <IconBrandInstagram className="profile-social-icon icon-instagram" size={24} strokeWidth={1.5} />
                                </a>
                            }
                            {socials.snapchat && 
                                <a 
                                    className="profile-social-icon-link" 
                                    href={`https://snapchat.com/add/${socials.snapchat}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    data-tooltip-id="tt"
                                    data-tooltip-content="Open Snapchat"
                                >
                                    <IconBrandSnapchat className="profile-social-icon icon-snapchat" size={24} strokeWidth={1.5} />
                                </a>
                            }
                        </div>
                        <div className="profile-section profile-bio">
                            <div className="profile-section-header">
                                <div className="profile-header-line-left" />
                                <h5 className="profile-section-title">Bio</h5>
                                <div className="profile-header-line-right" />
                            </div>
                            {bio 
                                ? <p className="profile-bio-text">{bio}</p> 
                                : <p className="profile-bio-text opacity50">No bio added</p>
                            }
                        </div>
                        {userDisplayName === "famousjenga" && (
                            <div className="send-a-msg-wrapper">
                                <div className="btn-text-link send-a-msg" onClick={openRequestModal}>Send a message</div>
                            </div>
                        )}
                        <div className="profile-section profile-favorites">
                            <IconHeartFilled className="profile-favorites-icon" size={16} strokeWidth={1.5} />
                            <div className="profile-section-header">
                                <div className="profile-header-line-left" />
                                <h5 className="profile-section-title">Favorited festivals</h5>
                                <div className="profile-header-line-right" />
                            </div>
                            <div className="profile-favorited-list">
                            {sortedFavorites.length > 0 
                                ? sortedFavorites.map(festival => (
                                    <div key={festival.festivalId} className="profile-favorited-item">
                                        <p>{festival.name}</p>
                                        <IconCalendarSmile 
                                            size={20} 
                                            strokeWidth={1.5} 
                                            className="icon-calendar-user" 
                                            onClick={() => navigate(`/${festival.festivalId}/s/${userData.schedules[festival.festivalId]}`)} 
                                            data-tooltip-id="tt"
                                            data-tooltip-content="View schedule"
                                        />
                                    </div>
                                ))
                                : <p className="profile-favorites-text opacity50">No favorites added</p>
                            } 
                            </div>
                        </div>
                        {isCurrentUser && (
                            <div className="profile-actions">
                                <button className="btn-with-icon danger-btn" onClick={handleSignOut}>
                                    <IconLogout size={20} strokeWidth={1.5} />   
                                    <p>Sign out</p>
                                </button>
                                <button className="btn-with-icon" onClick={() => setIsEditing(true)}>
                                    <IconPencil size={20} strokeWidth={1.5} />   
                                    <p>Edit profile</p>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="festivals-wrapper">
                <p className="page-context-bar opacity50 profile-context-bar">Profile</p>
                <div className="profile-wrapper">
                    <div className="lights-wrapper">
                        <div className="single-light-wrapper">
                            <div className="light-1-top" style={{ 
                                boxShadow: `0 44px 80px 20px rgba(255,255,255,0.35)`,
                                // backgroundColor: `${STAGE_COLORS[index]}`
                            }} />
                            <div className="light-1-bottom" style={{ 
                                boxShadow: `0 -35px 50px 10px rgba(0,0,0,0.4)`,
                            }} />
                            <div className="light-1-glow" style={{ 
                                boxShadow: `0 2px 5px 1px rgba(255,255,255,0.4)`,
                            }} />
                            {/* {isEditing && <div className="light-editing light-1" />} */}
                        </div>
                        <div className="bottom-lights">
                            <div className="single-light-wrapper">
                                <div className="light-2-top" style={{ 
                                    boxShadow: `0 40px 80px 20px rgba(255,255,255,0.3)`,
                                    // backgroundColor: `${STAGE_COLORS[index]}`
                                }} />
                                <div className="light-2-bottom" style={{ 
                                    boxShadow: `0 -35px 50px 10px rgba(0,0,0,0.4)`,
                                }} />
                                <div className="light-2-glow" style={{ 
                                boxShadow: `0 2px 5px 1px rgba(255,255,255,0.4)`,
                            }} />
                                {/* {isEditing && <div className="light-editing light-2" />} */}
                            </div>
                            <div className="single-light-wrapper">
                                <div className="light-3-top" style={{ 
                                    boxShadow: `0 40px 80px 20px rgba(255,255,255,0.3)`,
                                    // backgroundColor: `${STAGE_COLORS[index]}`
                                }} />
                                <div className="light-3-bottom" style={{ 
                                    boxShadow: `0 -35px 50px 10px rgba(0,0,0,0.4)`,
                                }} />
                                <div className="light-3-glow" style={{ 
                                boxShadow: `0 2px 5px 1px rgba(255,255,255,0.4)`,
                            }} />
                                {/* {isEditing && <div className="light-editing light-3" />} */}
                            </div>
                        </div>
                    </div>
                    {profileInfo}
                </div>
            </div>
            <RequestModal 
                isOpen={isRequestModalOpen} 
                onRequestClose={closeRequestModal} 
                requestType={"General feedback"}
                currentUser={currentUser}
                origin={"Profile"}
            />
        </React.Fragment>
    )
}

export default Profile
