import React, { useState } from 'react'
import './404.css'
import RequestModal from './RequestModal'

const NotFoundPage = ({ subtext, currentUser }) => {
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    return (
        <React.Fragment>
            <div className="main-wrapper">
                <div className="four-wrapper">
                    <h1>404: Page Not Found</h1>
                    <p className="four-subtext">{subtext}</p>
                    <p className="four-subtext">It's possible a page refresh may fix this, otherwise:</p>
                    <div className="btn-text-link" onClick={openRequestModal}>Report an issue</div>
                </div>
            </div>
            <RequestModal 
                isOpen={isRequestModalOpen} 
                onRequestClose={closeRequestModal} 
                requestType={"Something's fucky"}
                currentUser={currentUser}
                origin={`404`}
            />
        </React.Fragment>
    )
}

export default NotFoundPage