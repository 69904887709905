import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth, database } from '../config/firebase-config'
import { ref, onValue, set, remove } from 'firebase/database'

const FavoriteFestivalsContext = createContext()

export const useFavoriteFestivals = () => useContext(FavoriteFestivalsContext)

export const FavoriteFestivalsProvider = ({ children }) => {
    const [favorites, setFavorites] = useState([])

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const favoritesRef = ref(database, `users/${user.uid}/favorites`)
                onValue(favoritesRef, (snapshot) => {
                    const favoritesData = snapshot.val() || {}
                    setFavorites(Object.keys(favoritesData))
                })
            }
        })

        return () => unsubscribe()
    }, [])

    const addFavorite = async (userId, festivalId) => {
        const favoritesRef = ref(database, `users/${userId}/favorites/${festivalId}`)
        await set(favoritesRef, true)
    }

    const removeFavorite = async (userId, festivalId) => {
        const favoritesRef = ref(database, `users/${userId}/favorites/${festivalId}`)
        await remove(favoritesRef)
    }

    return (
        <FavoriteFestivalsContext.Provider value={{ favorites, addFavorite, removeFavorite }}>
            {children}
        </FavoriteFestivalsContext.Provider>
    )
}
