import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ref, set, runTransaction, push } from 'firebase/database'
import { database } from '../../config/firebase-config'
import { IconArrowUp, IconTrash } from '@tabler/icons-react'
import { formatRelativeTime } from '../../utils/helpers'
import Reaction from '../Festivals/Reaction'

const Comment = ({ comment, announcementID, currentUser }) => {
    const navigate = useNavigate()

    const [showReplies, setShowReplies] = useState(false)
    const [newReply, setNewReply] = useState("")
    const [isReplying, setIsReplying] = useState(false)

    const replyTextareaRef = useRef(null)

    const handleReplySubmit = () => {
        if (newReply === "") return
        if (newReply.trim()) {
            const repliesRef = ref(database, `announcements/${announcementID}/comments/${comment.id}/replies`)
            const newReplyRef = push(repliesRef)
            set(newReplyRef, {
                userID: currentUser.uid,
                reply: newReply,
                timestamp: Date.now()
            })            
            setNewReply("")
            setIsReplying(false)
            setShowReplies(true)
        }
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            handleReplySubmit()
        }
    }

    const handleReplyClick = () => {
        setIsReplying(!isReplying)
        if (!isReplying) {
            const hiddenInput = document.createElement('input')
            hiddenInput.setAttribute('type', 'text')
            hiddenInput.style.position = 'absolute'
            hiddenInput.style.opacity = '0'
            hiddenInput.style.height = '0'
            hiddenInput.style.width = '0'
            hiddenInput.style.zIndex = '-1'
            document.body.appendChild(hiddenInput)
    
            hiddenInput.focus()
    
            setTimeout(() => {
                replyTextareaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                replyTextareaRef.current?.focus()
                document.body.removeChild(hiddenInput)
            }, 10) // Adjust timing as needed
        }
    }

    const handleUpvote = () => {
        const upvoteRef = ref(database, `announcements/${announcementID}/comments/${comment.id}/upvotes/${currentUser.uid}`)
        runTransaction(upvoteRef, (currentData) => {
            return currentData ? null : true
        })
    }
    const handleReplyUpvote = (replyID) => {
        const upvoteRef = ref(database, `announcements/${announcementID}/comments/${comment.id}/replies/${replyID}/upvotes/${currentUser.uid}`)
        runTransaction(upvoteRef, (currentData) => {
            return currentData ? null : true
        })
    }

    const handleDelete = () => {
        const commentRef = ref(database, `announcements/${announcementID}/comments/${comment.id}`)
        set(commentRef, null)
    }
    const handleReplyDelete = (replyID) => {
        const replyRef = ref(database, `announcements/${announcementID}/comments/${comment.id}/replies/${replyID}`)
        set(replyRef, null)
    }

    let replyCountText = comment.replies.length === 1 ? "reply" : "replies"

    return (
        <div className="comment-item">
            <div className="comment-top">
                <div className="comment-info-wrapper">
                    <img 
                        src={comment.userPicture} 
                        alt="user" 
                        className="comment-user-pic" 
                        onClick={() => navigate(`/u/${comment.userDisplayName}`)}
                    />
                    <div className="comment-content">
                        <div className="comment-user-and-time">
                            <div 
                                className="comment-user"
                                onClick={() => navigate(`/u/${comment.userDisplayName}`)}
                            >
                                {comment.userDisplayName}

                            </div>
                            <div className="comment-timestamp">{formatRelativeTime(comment.timestamp)}</div>
                        </div>
                        <p className="comment-text">{comment.comment}</p>
                    </div>
                </div>
                <div className="comment-actions-wrapper">
                    <div className="comment-upvote">
                        <Reaction 
                            value={"like"}
                            emoji={"👍"}
                            count={comment.upvoteCount}
                            isActive={comment.userHasUpvoted}
                            onReactionClick={handleUpvote}
                        />
                    </div>
                    {currentUser.uid === comment.userID && (
                        <div className="comment-delete" onClick={handleDelete}>
                            <IconTrash size={16} strokeWidth={2} />
                        </div>
                    )}
                </div>
            </div>
            <div className="comment-replies-wrapper">
                <div onClick={handleReplyClick} className={`comment-reply-button ${isReplying ? 'reply-cancel' : ''}`}>
                    {isReplying ? "Cancel" : "Reply"}
                </div>
                {isReplying && (
                    <div className="new-reply-wrapper">
                        <textarea 
                            ref={replyTextareaRef}
                            className="input-default textarea-default textarea-new-reply"
                            value={newReply}
                            onChange={(e) => setNewReply(e.target.value)}
                            placeholder="Write a reply..."
                            onKeyDown={handleKeyDown}
                            onClick={() => {
                                requestAnimationFrame(() => {
                                    replyTextareaRef.current?.focus()
                                })
                            }}
                        />
                        <div 
                            className={`icon-comment-send-wrapper icon-reply-wrapper ${newReply.length > 0 ? 'active' : ''}`}
                            onClick={handleReplySubmit}
                        >
                            <IconArrowUp 
                                size={16} 
                                strokeWidth={2} 
                                className="icon-comment-send"
                            />
                        </div>
                    </div>
                )}
                {comment.replies.length > 0 && (
                    <div className="show-hide-replies-wrapper" onClick={() => setShowReplies(!showReplies)}>
                        <div className="show-hide-line" />
                        <div className="show-hide-replies comment-view-replies-button">
                            {showReplies ? "Hide replies" : `View ${comment.replies.length} ${replyCountText}`}
                        </div>
                        <div className="show-hide-line" />
                    </div>
                )}
                {showReplies && (
                    <div className="replies-wrapper">
                        {(comment.replies || []).map(reply => (
                            <div key={reply.id} className="reply-item">
                                <div className="reply-info-wrapper">
                                    <img 
                                        src={reply.userPicture} 
                                        alt="user" 
                                        className="reply-user-pic"
                                        onClick={() => navigate(`/u/${reply.userDisplayName}`)}
                                    />
                                    <div className="reply-content">
                                        <div className="reply-user-and-time">
                                            <div 
                                                className="reply-user"
                                                onClick={() => navigate(`/u/${reply.userDisplayName}`)}
                                            >
                                                {reply.userDisplayName}
                                            </div>
                                            <div className="reply-timestamp">{formatRelativeTime(reply.timestamp)}</div>
                                        </div>
                                        <p className="reply-text">{reply.reply}</p>
                                    </div>
                                </div>
                                <div className="reply-actions-wrapper">
                                    <div className="reply-upvote">
                                        <Reaction 
                                            value={"like"}
                                            emoji={"👍"}
                                            count={reply.upvoteCount || 0}
                                            isActive={reply.userHasUpvoted}
                                            onReactionClick={() => handleReplyUpvote(reply.id)}
                                        />
                                    </div>
                                    {currentUser.uid === reply.userID && (
                                        <div className="comment-delete" onClick={() => handleReplyDelete(reply.id)}>
                                            <IconTrash size={16} strokeWidth={2} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Comment
