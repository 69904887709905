import React from "react"
import { IconPlus, IconArrowBadgeUpFilled, IconArrowBigUpFilled } from "@tabler/icons-react"
import "./Reaction.css"

function Reaction({ value, emoji, count, isActive, onReactionClick }) {
    return (
        <div 
            className={`react-container ${isActive ? 'react-active' : ''}`}
            onClick={onReactionClick}
        >
            <div className={`react-text react-emoji ${count > 0 ? 'active' : ''}`}>{emoji}</div>
            {count > 0 && <div className="react-text react-count">{count}</div>}
            {/* <div className={`react-text ${count === 0 ? 'react-count-zero' : 'react-count'}`}>{count}</div> */}
        </div>
    )
}

export default Reaction