import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { IconPlus, IconX } from '@tabler/icons-react'
import { updateGroupData, uploadGroupPicture } from '../../services/firebase/databaseService'
import '../General/Modal.css'
import './EditGroupModal.css'

const EditGroupModal = ({ isOpen, onRequestClose, groupData, groupId, currentUser }) => {
    const editorRef = useRef(null)
    const [groupName, setGroupName] = useState(groupData.name)
    const [image, setImage] = useState(null)
    const [scale, setScale] = useState(1)
    const [imageError, setImageError] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [groupNameError, setGroupNameError] = useState(null)
    
    const maxSize = 20 * 1024 * 1024 // 20MB
    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/heif', 'image.heic', 'image/tiff']

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]

        setImage(null)
        setScale(1)

        if (!allowedFileTypes.includes(file.type)) {
            setImageError('Invalid file type')
            return
        }

        if (file.size > maxSize) {
            setImageError('File size exceeds the maximum limit of 20MB.')
            return
        }

        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0])
            setImageError(null)
        }
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: image !== null,
        noKeyboard: true,
    })

    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value))
    }

    const handleGroupNameChange = (e) => {
        setGroupName(e.target.value)
    }

    const validateGroupName = (name) => {
        const groupNameRegex = /^[a-zA-Z0-9_ ]{3,20}$/ // 3-20 characters, alphanumeric, underscores, and spaces
        return groupNameRegex.test(name)
    }

    const handleSave = async () => {
        if (groupName.length === 0) {
            setGroupNameError('Group name is required')
            return
        }
        if (!validateGroupName(groupName)) {
            setGroupNameError('Group name must be 3-20 characters, alphanumeric, and underscores only')
            return
        }

        setIsSaving(true)
        let updates = { name: groupName }

        if (image && editorRef.current) {
            const canvas = editorRef.current.getImage()
            const imgDataURL = canvas.toDataURL()

            const pictureUrl = await uploadGroupPicture(imgDataURL, groupId)
            updates.picture = pictureUrl
        }

        try {
            await updateGroupData(groupId, updates)
            onRequestClose()
        } catch (error) {
            console.error('Error updating group:', error)
        } finally {
            setIsSaving(false)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal-wrapper"
            overlayClassName="modal-overlay"
        >
            <div className="modal-edit-group">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Edit group details</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={onRequestClose} />
                </div>
                <div className="modal-content">
                    <div className="edit-group-picture-wrapper">
                        <div className="dropzone-wrapper">
                            <div {...getRootProps({ className: `edit-group-dropzone ${image ? 'no-hover' : ''}` })}>
                                <input {...getInputProps()} />
                                {image ? (
                                    <div className="edit-group-picture-container">
                                        <AvatarEditor
                                            ref={editorRef}
                                            image={image}
                                            width={160}
                                            height={160}
                                            border={0}
                                            borderRadius={8}
                                            color={[255, 255, 255, 0.6]}
                                            scale={scale}
                                            rotate={0}
                                        />
                                    </div>
                                ) : (
                                    <img src={groupData.picture} alt="Group" className="edit-group-img" />
                                )}
                            </div>
                        </div>
                        {image && 
                            <div className="signup-slider-container">
                                <p className="body-s">1x</p>
                                <input
                                    id="scale-slider"
                                    type="range"
                                    value={scale}
                                    min="1"
                                    max="5"
                                    step="0.01"
                                    onChange={handleScaleChange}
                                    style={{ width: '100%' }}
                                />
                                <p className="body-s">5x</p>
                            </div>
                        }
                        <div className="txt-btn-photo-action" onClick={open}>
                            <p>Change photo</p>
                        </div>
                        {imageError && <p className="error-message">{imageError}</p>}
                    </div>
                    <div className="form-group">
                        <label className="input-label" htmlFor="group-name">Group name</label>
                        <input
                            type="text"
                            id="group-name"
                            value={groupName}
                            onChange={handleGroupNameChange}
                            className={`input-default ${groupNameError ? 'input-error' : ''}`}
                        />
                        {groupNameError && <p className="error-message">{groupNameError}</p>}
                    </div>
                    <div className="modal-btn-signup-wrapper">
                        <button className="btn-primary" onClick={handleSave} disabled={isSaving || !groupName}>
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditGroupModal
