import React, { useState } from 'react'
import { IconUsers, IconLink, IconArrowNarrowLeft, IconPencil } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import Tabs from '../Tabs/Tabs'
import AvatarSet from '../AvatarSet/AvatarSet'
import RequestModal from '../General/RequestModal'
import EditGroupModal from '../Groups/EditGroupModal'

const GroupHeader = ({ currentFestival, currentUser, groupData, groupId, membersData, selectedTab, setSelectedTab }) => {
    const windowWidth = window.innerWidth
    const navigate = useNavigate()

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false)
    const openEditGroupModal = () => setIsEditGroupModalOpen(true)
    const closeEditGroupModal = () => setIsEditGroupModalOpen(false)

    const [copyStatus, setCopyStatus] = useState("Copy")
    const handleCopyLink = () => {
        const link = `https://www.festiplannr.com/${currentFestival.id}/g/${groupId}/join`
        
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link).then(() => {
                setCopyStatus("Copied!")
                setTimeout(() => setCopyStatus('Copy'), 1000)
            }).catch((err) => {
                console.error('Failed to copy: ', err)
            })
        } else {
            // Fallback for older browsers or failure
            const textArea = document.createElement('textarea')
            textArea.value = link
            textArea.style.position = 'fixed'
            textArea.style.left = '-9999px'
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                document.execCommand('copy')
                setCopyStatus("Copied!")
                setTimeout(() => setCopyStatus('Copy'), 1000)
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err)
            }
            document.body.removeChild(textArea)
        }
    }

    if (currentFestival && groupData) {
        return (
            <React.Fragment>
                <div className="group-main-header">
                    {windowWidth <= 1000 && (
                        <div className="report-issue-wrapper">
                            <div className="btn-with-icon" onClick={() => navigate("/")}>
                                <IconArrowNarrowLeft size={20} strokeWidth={1.5} />
                                <p>Back to Festivals</p>
                            </div>
                            <div className="btn-text-link" onClick={openRequestModal}>Report an issue</div>
                        </div>
                    )}
                    <div className="group-info-wrapper">
                        <img className="img-group" src={groupData.picture} alt={groupData.name} />
                        <div className="group-details-text">
                            <div className="mh-header-wrapper extra-padding-2px">
                                <div className="mh-festival-name">
                                    <p>Group</p>
                                    <div className="separator-dot" />
                                    <p>{currentFestival.name}</p>
                                </div>
                                {windowWidth > 1000 && (
                                    <div className="report-issue-wrapper">
                                        <div className="btn-text-link" onClick={openRequestModal}>Report an issue</div>
                                    </div>
                                )}
                            </div>
                            <div className="group-name-wrapper">
                                <div className="group-name">{groupData.name}</div>
                            </div>
                            <div className="group-num-members-wrapper">
                                <AvatarSet membersData={membersData} maxDisplay={12} setSelectedTab={setSelectedTab} festival={currentFestival} />
                                <div className="group-actions-wrapper">
                                    <button 
                                        className="btn-primary-sm btn-share-link btn-group-share"
                                        onClick={handleCopyLink}
                                        data-tooltip-id="tt"
                                        data-tooltip-content={copyStatus === "Copied!" ? "🎉 Link copied!" : ""}
                                        data-tooltip-delay-hide={500}
                                    >
                                        <IconLink size={20} strokeWidth={1.5}/>
                                        <p>Share</p>
                                    </button>
                                    {groupData.admins && groupData.admins[currentUser.uid] && (
                                        <div className="btn-with-icon" style={{ marginLeft: "8px" }} onClick={openEditGroupModal}>
                                            <IconPencil size={20} strokeWidth={1.5} />
                                            Edit
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tabs 
                        tabs={['Heatmap', `Members (${groupData.memberCount})`]} 
                        selectedTab={selectedTab} 
                        setSelectedTab={setSelectedTab} 
                    />
                </div>
                <RequestModal 
                    isOpen={isRequestModalOpen} 
                    onRequestClose={closeRequestModal} 
                    requestType={"Something's fucky"}
                    currentUser={currentUser}
                    origin={`Group, ${currentFestival.id}, ${groupId}`}
                />
                <EditGroupModal 
                    isOpen={isEditGroupModalOpen} 
                    onRequestClose={closeEditGroupModal} 
                    groupData={groupData} 
                    groupId={groupId}
                    currentUser={currentUser}
                />
            </React.Fragment>
        )
    } else {
        return (
            <div className="group-main-header">
                <div className="group-info-wrapper">
                    <div className="img-group" />
                    <div className="group-details-text">
                        <div className="mini-header-contents">
                            <p>Group</p>
                        </div>
                        <div className="group-name">Loading group...</div>
                        <div className="group-num-members-wrapper">
                            <IconUsers size={20} strokeWidth={1.5} />
                            <p>— joined</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GroupHeader