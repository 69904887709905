import React from 'react'
import './Tag.css'

function LineupTag({ text, status, complete, inNav }) {
    let className = "lineup-tag"
    if (complete) {
        if (text === "Lineup") {
            className += " complete-1"
        } else if (text === "By day") {
            className += " complete-2"
        } else if (text === "Set times") {
            className += " complete-3"
        }
    } else {
        className += (status ? " true" : " false")
    }

    return (
        <div className={className}>
            {text}
        </div>
    )
}

export default LineupTag