import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchAllUsers, fetchAdminFestivalData } from '../../services/firebase/adminFunctions'
import { Line, Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js'
import './Admin.css'
import Tabs from '../Tabs/Tabs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

const Admin = ({ currentUser }) => {
    const [users, setUsers] = useState([])
    const [unknownUserCount, setUnknownUserCount] = useState(0)
    const [festivals, setFestivals] = useState([])
    const [selectedTab, setSelectedTab] = useState(0) // 0: Users, 1: Festivals
    const navigate = useNavigate()

    useEffect(() => {
        const handleRedirect = async () => {
            if (!currentUser || currentUser.displayName !== "famousjenga") {
                navigate("/", { replace: true })
                return
            }
        }
    
        handleRedirect()
    }, [currentUser, navigate])

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const { users: usersData, unknownUserCount } = await fetchAllUsers()
                setUsers(usersData)
                setUnknownUserCount(unknownUserCount)
            } catch (error) {
                console.error('Error fetching users:', error)
            }
        }

        const loadFestivals = async () => {
            try {
                const festivalsData = await fetchAdminFestivalData()
                setFestivals(festivalsData)
            } catch (error) {
                console.error('Error fetching festivals:', error)
            }
        }

        loadUsers()
        loadFestivals()
    }, [])

    const processUserData = () => {
        const dateCounts = {}
        const totalCounts = []
        const startDate = new Date("2024-05-01")
    
        users.forEach(user => {
            let userDate
            if (!isNaN(user.createdAt)) {
                userDate = new Date(Number(user.createdAt))
            } else {
                userDate = new Date(user.createdAt)
            }
    
            if (userDate > startDate) {
                const formattedDate = new Date(userDate.toLocaleString()).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })
                dateCounts[formattedDate] = (dateCounts[formattedDate] || 0) + 1
            }
        })
    
        const sortedDates = Object.keys(dateCounts).sort((a, b) => new Date(a) - new Date(b))
    
        let cumulativeCount = 0
        sortedDates.forEach(date => {
            cumulativeCount += dateCounts[date]
            totalCounts.push({ date, count: cumulativeCount })
        })
    
        return { dateCounts, totalCounts, sortedDates }
    }
    
    const { dateCounts, totalCounts, sortedDates } = processUserData()
    const totalUsersOverTimeData = {
        labels: totalCounts.map(data => data.date),
        datasets: [
            {
                label: 'Total users',
                data: totalCounts.map(data => data.count),
                fill: false,
                borderColor: 'white',
                tension: 0.1
            }
        ]
    }
    const totalUsersPerDayData = {
        labels: sortedDates,
        datasets: [
            {
                label: 'New users',
                data: sortedDates.map(date => dateCounts[date]),
                backgroundColor: 'white'
            }
        ]
    }    

    const calculateWeeklyGrowth = (totalCounts) => {
        const pacificTimeOptions = { timeZone: "America/Los_Angeles" };
        const startDate = new Date("2024-07-31T00:00:00-07:00"); // Ensure start date is explicitly in Pacific Time
        const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    
        const weeklyGrowth = [];
        let currentDate = startDate;
    
        // Add the initial data point at the start date with 0% growth
        weeklyGrowth.push({
            date: currentDate.toLocaleDateString("en-US", pacificTimeOptions),
            growth: 0
        });
    
        // Find the count at the start date
        const startDateDataPoint = totalCounts.find(({ date }) =>
            new Date(new Date(date).toLocaleString("en-US", pacificTimeOptions)) >= currentDate
        );
        let lastWeekCount = startDateDataPoint ? startDateDataPoint.count : 0;
    
        // Iterate through each week starting from the start date
        while (currentDate <= new Date(new Date().toLocaleString("en-US", pacificTimeOptions))) {
            const nextWeekDate = new Date(currentDate.getTime() + weekInMilliseconds);
    
            // Find the data point closest to the next week's date
            let closestDataPoint = totalCounts.find(({ date }) =>
                new Date(new Date(date).toLocaleString("en-US", pacificTimeOptions)) >= nextWeekDate
            );
    
            // If no exact match, use the last known count
            if (!closestDataPoint) {
                closestDataPoint = totalCounts[totalCounts.length - 1];
            }
    
            if (closestDataPoint) {
                const growthPercentage = lastWeekCount === 0 ? 0 : ((closestDataPoint.count - lastWeekCount) / lastWeekCount) * 100;
                weeklyGrowth.push({
                    date: nextWeekDate.toLocaleDateString("en-US", pacificTimeOptions),
                    growth: growthPercentage
                });
                lastWeekCount = closestDataPoint.count;
            }
    
            // Move to the next week
            currentDate = nextWeekDate;
        }
    
        return weeklyGrowth;
    };    
    
    const weeklyGrowthData = calculateWeeklyGrowth(totalCounts);
    const weeklyGrowthChartData = {
        labels: weeklyGrowthData.map(data => data.date),
        datasets: [
            {
                label: 'Weekly growth %',
                data: weeklyGrowthData.map(data => data.growth),
                fill: false,
                borderColor: 'white'
            }
        ]
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {grid: {color: 'rgba(255, 255, 255, 0.1)'}},
            y: {grid: {color: 'rgba(255, 255, 255, 0.1)'}}
        },
        maintainAspectRatio: false
    }

    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    return (
        <div className="main-wrapper">
            <div className="ap-page-wrapper">
                <Tabs 
                    tabs={['Users', 'Festivals']} 
                    selectedTab={selectedTab} 
                    setSelectedTab={setSelectedTab} 
                />
                {selectedTab === 0 && (
                    <div className="ap-users-stats-wrapper">
                        <div className="ap-unknown-users-header">
                            <p className="ap-unknown-users">Users without display names: {unknownUserCount}</p>
                        </div>
                        <div className="ap-users-graphs">
                            <div className="ap-users-graph">
                                <h4 className="ap-users-graph-title">Total users</h4>
                                <Line data={totalUsersOverTimeData} options={chartOptions} />
                            </div>
                            <div className="ap-users-graph">
                                <h4 className="ap-users-graph-title">New users / day</h4>
                                <Bar data={totalUsersPerDayData} options={chartOptions} />
                            </div>
                            <div className="ap-users-graph">
                                <h4 className="ap-users-graph-title">Weekly growth %</h4>
                                <Line data={weeklyGrowthChartData} options={chartOptions} />
                            </div>
                        </div>
                        <div className="ap-users-list-wrapper">
                            <div className="ap-users-list-header">
                                <p className="ap-users-list-header-text ap-user-pic-name-header">User</p>
                                <p className="ap-users-list-header-text ap-user-timestamp">Joined</p>
                                <p className="ap-users-list-header-text ap-user-favs">Favs</p>
                                <p className="ap-users-list-header-text ap-user-groups">Groups</p>
                                <p className="ap-users-list-header-text ap-user-group-details">Details</p>
                                <p className="ap-users-list-header-text ap-user-schedules">Schedules</p>
                                <p className="ap-users-list-header-text ap-user-recents">Recents</p>
                                <p className="ap-users-list-header-text ap-user-email">Email</p>
                                <p className="ap-users-list-header-text ap-user-id">ID</p>
                            </div>
                            {users.map(user => {
                                let numUserFavorites = user.favorites ? Object.keys(user.favorites).length : 0
                                let numUserGroups = user.groups ? Object.keys(user.groups).length : 0
                                let numUserRecents = user.totalRecents ? user.totalRecents : 0

                                return (
                                    <div key={user.uid} className="ap-user-row">
                                        <div className="ap-user-pic-name">
                                            <img src={user.picture?.lowRes} className="ap-user-pic" alt="User pic"/>
                                            <p className="ap-user-name">{user.displayName}</p>
                                        </div>
                                        <p className="ap-user-timestamp">{new Date(user.createdAt).toLocaleString("en-US", { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</p>
                                        <p className={`ap-user-favs ${numUserFavorites === 0 ? 'ap-empty' : ''}`}>{numUserFavorites}</p>
                                        <p className={`ap-user-groups ${numUserGroups === 0 ? 'ap-empty' : ''}`}>{numUserGroups}</p>
                                        <div className="ap-user-group-details">
                                            {user.groupsDetails && (
                                                <div className="ap-ugd-wrapper">
                                                    {user.groupsDetails.map(group => (
                                                        <div className="ap-ugd" key={group.groupId}>
                                                            <div className="ap-ugd-flex-sb">
                                                                <div className="ap-ugd-flex">
                                                                    <div 
                                                                        className="ap-ugd-groupname"
                                                                        onClick={(e) => handleNavigate(e, `/${group.festivalId}/g/${group.groupId}`)}
                                                                    >
                                                                        "{group.groupName}"

                                                                    </div>
                                                                    <div className="ap-ugd-membercount">&#x28;{group.memberNames.length}&#x29;</div>
                                                                </div>
                                                                <div className="ap-ugd-festival">{group.festivalId}</div>
                                                            </div>
                                                            <div className="ap-ugd-members">{group.memberNames.join(', ')}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="ap-user-schedules"> {/* New column */}
                                            {user.scheduleDetails && user.scheduleDetails.length > 0 && (
                                                <div className="ap-user-schedules-wrapper">
                                                    {user.scheduleDetails.map(({ festivalId, selectionCounts }) => (
                                                        <div key={festivalId} className="ap-user-schedule-details">
                                                            <div className="ap-user-schedule-festival">{festivalId}:</div>
                                                            <div className="ap-user-schedule-selections">
                                                                <span>{selectionCounts.total} &#x28;{selectionCounts.count1}, {selectionCounts.count2}&#x29;</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <p className={`ap-user-recents ${numUserRecents === 0 ? 'ap-empty' : ''}`}>{numUserRecents}</p>
                                        <p className="ap-user-email">{user.email}</p>
                                        <p className="ap-user-id">{user.uid}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                {selectedTab === 1 && (
                    <div className="ap-festivals-list-wrapper">
                        <div className="ap-festivals-list-header">
                            <p className="ap-festivals-list-header-text ap-festival-name">Festival</p>
                            <p className="ap-festivals-list-header-text ap-festival-groups">Groups</p>
                            <p className="ap-festivals-list-header-text ap-festival-schedules">Schedules</p>
                            <p className="ap-festivals-list-header-text ap-festival-nonzero">Non-zero</p>
                            <p className="ap-festivals-list-header-text ap-festival-favs">Favorites</p>
                        </div>
                        {festivals.map(festival => {
                            return (
                                <div key={festival.id} className="ap-festival-row">
                                    <p className="ap-festival-name">{festival.name}</p>
                                    <p className={`ap-festival-groups ${festival.numGroups === 0 ? 'ap-empty' : ''}`}>{festival.numGroups}</p>
                                    <p className={`ap-festival-schedules ${festival.numSchedules === 0 ? 'ap-empty' : ''}`}>{festival.numSchedules}</p>
                                    <p className={`ap-festival-nonzero ${festival.numSchedulesWithSelections === 0 ? 'ap-empty' : ''}`}>{festival.numSchedulesWithSelections}</p>
                                    <p className={`ap-festival-favs ${festival.numFavorites === 0 ? 'ap-empty' : ''}`}>{festival.numFavorites}</p>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Admin
