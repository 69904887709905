import React, { useRef, useEffect } from 'react'
import { useUIContext } from '../../context/UIContext'
import { STAGE_COLORS, ELEMENTS24_STAGE_COLORS } from '../../utils/constants'
import { encodeKey } from '../../utils/scheduleHelpers'
import { hexToRgbA } from '../../utils/helpers'
import Block2 from '../Schedule/Block2'
import Timeline from '../Schedule/Timeline'
import { ReactP5Wrapper } from '@p5-wrapper/react'
import { textToSeed } from './imageModalHelpers'
import { IconHeartFilled } from '@tabler/icons-react'
import { 
    IOS_TIME_HEIGHT,
    IMG_PADDING,
    IOS_WIDGET_HEIGHT,
    SEED_WRAPPER_PADDING_BOTTOM,
    SEED_PHRASE_FONT_SIZE,
    SEED_WRAPPER_HEIGHT,
    HEADER_PADDING_BOTTOM,
    HEADER_MARGIN_BOTTOM,
    HEADER_FONT_SIZE,
    HEADER_LINE_HEIGHT,
    HEADER_HEIGHT,
    DECORATOR_SIZE,
    DECORATOR_TOP_DISTANCE,
    DECORATOR_LR_MARGIN,
    DECORATOR_GAP,
    DECORATOR_WIDTH
 } from './ImageConstants'

const SetTimeImage = ({ 
    shouldLoadP5, 
    index, 
    dayData, 
    customizationOptions, 
    currentFestival, 
    colorStyle, 
    scheduleRefs, 
    setP5Ready,
    blockSelections,
    selectedStages
}) => {
    const { columnWidthSet, blockFontSizeSet } = useUIContext()
    const canvasRef = useRef(null)

    const sketch = (p) => {
        const seed = textToSeed(customizationOptions.visualizationSeed, currentFestival.id)
        let symmetry, angle, curveSegments, numCurves, strokeWeight, curveTightness
        let calculatedPoints = []
        let renderStage = 0
        const totalRenderStages = 4

        const initializeVariables = () => {
            symmetry = 5 + (seed % 32)
            angle = 360 / symmetry
            curveSegments = 5 + (seed % 64)
            numCurves = 10 + (seed % 32)
            strokeWeight = 1 + p.random(1, 3)
            let seedTightnessStyle = seed % 2
            if (seedTightnessStyle === 0) curveTightness = p.random(0, 1 + seed % 5)
            if (seedTightnessStyle === 1) curveTightness = p.random(-(1 + seed % 5), 0)
        }

        const precalculatePoints = () => {
            if (seed % 5 === 0) {
                for (let i = 0; i < numCurves; i++) {
                    let startAngle = p.random(0, seed % 360)
                    let startRadius = seed % 400 + p.random(5, 300)
                    let startX = p.cos(startAngle) * startRadius
                    let startY = p.sin(startAngle) * startRadius

                    let curvePoints = [[startX, startY]]

                    const biasStrength = (10 * (seed % 150) + p.random(0, 50) / 200) / startAngle
                    const minLength = 4
                    const maxLength = 48 + (seed % 4000)/curveSegments

                    for (let k = 0; k < curveSegments; k++) { 
                        let nextX = p.random(minLength, maxLength)
                        let nextY = p.random(minLength, maxLength)

                        let xDirection = 1
                        let yDirection = 1
                        let randXDirection = p.random(0, 1)
                        let randYDirection = p.random(0, 1)
                        if (randXDirection > biasStrength) xDirection = -1
                        if (randYDirection > biasStrength) yDirection = -1
                        
                        let x = startX + nextX * xDirection
                        let y = startY + nextY * yDirection
            
                        curvePoints.push([x, y])
                        startX = x
                        startY = y
                    }

                    calculatedPoints.push(curvePoints)
                }
            }
            if (seed % 5 === 1) {
                for (let i = 0; i < numCurves * 5; i++) {
                    curveSegments = 8 + (seed % 4)
                    let startAngle = p.random(0, seed % 360)
                    let startRadius = i * ((seed % 150) + p.random(1, 25))
                    let startX = p.cos(startAngle) * startRadius
                    let startY = p.sin(startAngle) * startRadius

                    let curvePoints = [[startX, startY]]

                    const biasStrength = (10 * (seed % 150) + p.random(0, 50) / 200) / startAngle
                    const minLength = 4
                    const maxLength = 64

                    for (let k = 0; k < curveSegments; k++) { 
                        let nextX = p.random(minLength, maxLength)
                        let nextY = p.random(minLength, maxLength)

                        let xDirection = 1
                        let yDirection = 1
                        let randXDirection = p.random(0, 1)
                        let randYDirection = p.random(0, 1)
                        if (randXDirection > biasStrength) xDirection = -1
                        if (randYDirection > biasStrength) yDirection = -1
                        
                        let x = startX + nextX * xDirection
                        let y = startY + nextY * yDirection
            
                        curvePoints.push([x, y])
                        startX = x
                        startY = y
                    }

                    calculatedPoints.push(curvePoints)
                }
            }
            if (seed % 5 === 2) {
                for (let i = 0; i < numCurves * 12; i++) {
                    curveSegments = p.random(1, 4)
                    let startAngle = p.random(0, seed % 360)
                    let startRadius = i * (seed % (50 + i))
                    let startX = p.cos(startAngle) * startRadius
                    let startY = p.sin(startAngle) * startRadius

                    let curvePoints = [[startX, startY]]

                    // const biasStrength = (10 * (seed % 150) + p.random(0, 50) / 200) / startAngle
                    const biasStrength = 1
                    const minLength = 4
                    const maxLength = 120

                    for (let k = 0; k < curveSegments; k++) { 
                        let nextX = p.random(minLength, maxLength)
                        let nextY = p.random(minLength, maxLength)

                        let xDirection = 1
                        let yDirection = 1
                        let randXDirection = p.random(0, 1)
                        let randYDirection = p.random(0, 1)
                        if (randXDirection > biasStrength) xDirection = -1
                        if (randYDirection > biasStrength) yDirection = -1
                        
                        let x = startX + nextX * xDirection
                        let y = startY + nextY * yDirection
            
                        curvePoints.push([x, y])
                        startX = x
                        startY = y
                    }

                    calculatedPoints.push(curvePoints)
                }
            }
            if (seed % 5 === 3) {
                for (let i = 0; i < numCurves * 6; i++) {
                    curveSegments = p.random(1, 32)
                    curveTightness = 1
                    let startAngle = p.random(0, seed % 360)
                    let startRadius = i * (seed % (50 + i))
                    let startX = p.cos(startAngle) * startRadius
                    let startY = p.sin(startAngle) * startRadius

                    let curvePoints = [[startX, startY]]

                    // const biasStrength = (10 * (seed % 150) + p.random(0, 50) / 200) / startAngle
                    const biasStrength = 1
                    const minLength = 4
                    const maxLength = 300

                    for (let k = 0; k < curveSegments; k++) { 
                        let nextX = p.random(minLength, maxLength)
                        let nextY = nextX

                        let xDirection = 1
                        let yDirection = 1
                        let randXDirection = p.random(0, 1)
                        let randYDirection = p.random(0, 1)
                        if (randXDirection > biasStrength) xDirection = -1
                        if (randYDirection > biasStrength) yDirection = -1
                        
                        let x = startX + nextX * xDirection
                        let y = startY + nextY * yDirection
            
                        curvePoints.push([x, y])
                        startX = x
                        startY = y
                    }

                    calculatedPoints.push(curvePoints)
                }
            }
            if (seed % 5 === 4) {
                for (let i = 0; i < numCurves; i++) {
                    curveSegments = p.random(seed % 10, 10 + seed % 20)
                    curveTightness = -1
                    let startAngle = p.random(0, seed % 360)
                    let startRadius = p.random(0, seed % 200) + (seed % (50 + i))
                    let startX = p.cos(startAngle) * startRadius
                    let startY = p.sin(startAngle) * startRadius

                    let curvePoints = [[startX, startY]]

                    const biasStrength = (seed % 4) / 16
                    const minLength = 4
                    const maxLength = 250 + (seed % 450)

                    for (let k = 0; k < curveSegments; k++) { 
                        let nextX = p.random(minLength, maxLength)
                        let nextY = nextX + p.random(0, k * (seed % 20))

                        let xDirection = 1
                        let yDirection = 1
                        let randXDirection = p.random(0, 1)
                        let randYDirection = p.random(0, 1)
                        if (randXDirection > biasStrength) xDirection = -1
                        if (randYDirection > biasStrength) yDirection = -1
                        
                        let x = startX + nextX * xDirection
                        let y = startY + nextY * yDirection
            
                        curvePoints.push([x, y])
                        startX = x
                        startY = y
                    }

                    calculatedPoints.push(curvePoints)
                }
            }
        }

        p.setup = () => {
            if (!shouldLoadP5 || !canvasRef.current) return
            p.createCanvas(customizationOptions.dimensions.width * 3, customizationOptions.dimensions.height * 3)
            p.pixelDensity(1)
            p.noLoop()
            p.angleMode(p.DEGREES)

            if (customizationOptions.useSolidBackground) {
                p.clear()
                drawBackground()
                setP5Ready(true)
                return
            } else {
                initializeVariables()
                precalculatePoints()
                renderNextStage()
            }
        }

        const renderNextStage = () => {
            if (renderStage >= totalRenderStages) {
                setP5Ready(true)
                return
            }

            p.clear()
            drawBackground()
            
            const curvesToRender = Math.ceil(calculatedPoints.length * ((renderStage + 1) / totalRenderStages))
            drawKaleidoscope(curvesToRender)
            
            renderStage++
            requestAnimationFrame(renderNextStage)
        }

        const drawBackground = () => {
            const bgColor = p.color(colorStyle.bg)
            const bgDarker = p.color(p.red(bgColor) * 0.5, p.green(bgColor) * 0.5, p.blue(bgColor) * 0.5)
            const gradient = p.drawingContext.createLinearGradient(0, 0, 0, p.height)
            gradient.addColorStop(0.2, bgColor)
            gradient.addColorStop(1, bgDarker)

            p.drawingContext.fillStyle = gradient
            p.rect(0, 0, p.width, p.height)
        }

        const drawKaleidoscope = (curvesToRender) => {
            p.push()
            p.translate(p.width / 2, p.height / 5)

            for (let i = 0; i < curvesToRender; i++) {
                const curvePoints = calculatedPoints[i]
                
                p.strokeWeight(strokeWeight)
                p.curveTightness(curveTightness)

                let opacity = 35
                if (colorStyle.name === "FestiPlannr") opacity = 25
                p.stroke(p.color(colorStyle.main + Math.floor(opacity).toString(16)))

                for (let j = 0; j < symmetry; j++) {
                    p.rotate(angle)
                    p.push()
                    p.beginShape()
                    p.vertex(curvePoints[0][0], curvePoints[0][1])
                    curvePoints.forEach((point, index) => {
                        p.curveVertex(point[0], point[1])
        
                        p.fill(255, p.random(100, 120))
                        p.noStroke()
                        let circleSize = p.random(2, 4)
                        p.ellipse(point[0], point[1], circleSize, circleSize)
    
                        p.noFill()
                        p.stroke(p.color(colorStyle.main + Math.floor(opacity).toString(16)))
                    })
                    p.vertex(curvePoints[curvePoints.length - 1][0], curvePoints[curvePoints.length - 1][1])
                    p.endShape()
                    p.pop()
                }
            }

            p.pop()
        }
    }

    const numSelectedStages = dayData.lineup
        .filter(stage => selectedStages.includes(stage.stageName) && stage.artists.length > 0)
        .length
    const highResWidth = 3 * customizationOptions.dimensions.width
    const highResHeight = 3 * customizationOptions.dimensions.height
    let iOSExtrasHeight = 3 * IOS_TIME_HEIGHT
    if (!customizationOptions.userHasWidgets) iOSExtrasHeight -= 3 * IOS_WIDGET_HEIGHT
    const TwoxPadding = 2 * IMG_PADDING
    const contentWidth = highResWidth - TwoxPadding
    const scheduleWidth = highResWidth - TwoxPadding - 29

    const stageWidth = scheduleWidth / numSelectedStages
    let timelineHeight = (highResHeight - iOSExtrasHeight - HEADER_HEIGHT - 184)
    if (customizationOptions.showSeedPhrase) {
        timelineHeight -= SEED_WRAPPER_HEIGHT
    }

    const renderStageHeaders = () => {
        return dayData.lineup
            .filter(stage => selectedStages.includes(stage.stageName))
            .map((stage, index) => {
                const decoLeft = 16
                const decoWidth = stageWidth - (2 * decoLeft)
                let colorToUse = STAGE_COLORS[index]
    
                return (
                    <div 
                        key={stage.stageName} 
                        className="x-stage-single-header" 
                        style={{ 
                            width: `${stageWidth}px` 
                        }}
                    >
                        <div 
                            className="x-stage-header-text" 
                            style={{ 
                                width: `${stageWidth - 16}px`,
                                fontSize: `22px`,
                                lineHeight: `22px`
                            }}
                        >{stage.stageName}</div>
                        <div className="stage-header-decoration">
                            <div 
                                className="deco-3" 
                                style={{ 
                                    backgroundColor: `${colorStyle.name === "FestiPlannr" ? colorToUse : colorStyle.main}`,
                                    left: `${decoLeft}px`,
                                    width: `${decoWidth}px`
                                }}
                            />
                        </div>
                    </div>
                )
            })
    }

    const renderStages = () => {
        return dayData.lineup
            .filter(stage => selectedStages.includes(stage.stageName))
            .map((stage, stageIndex) => {
                const stageColor = STAGE_COLORS[stageIndex]
    
                return (
                    <div 
                        key={stage.stageName} 
                        className="single-stage-wrapper" 
                        style={{ 
                            width: `${stageWidth}px` 
                        }}
                    >
                        <div className="stage-blocks" style={{ width: `${columnWidthSet - 10}px` }}>
                            {stage.artists.map((artist, artistIndex) => {
                                const blockKey = `${stage.stageName.toUpperCase()}-${artist.Start.toUpperCase()}-${artist.Day.toUpperCase()}-${artist.Artist.toUpperCase()}`
                                const clickCount = blockSelections[encodeKey(blockKey)] || 0
    
                                return (
                                    <Block2
                                        key={blockKey}
                                        scheduleType="userSchedule"
                                        festival={currentFestival}
                                        isSetTime={true}
                                        stageColor={stageColor}
                                        selectedDayIndex={index}
                                        artist={artist}
                                        timelineHeight={timelineHeight}
                                        clickCount={clickCount}
                                        isCreator={false}
                                        isExport={true}
                                        stageWidth={stageWidth}
                                        colorStyle={colorStyle}
                                        exportDayIndex={index}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            })
    }

    return (
        <div
            ref={el => (scheduleRefs.current[index] = el)}
            className="x-position-wrapper"
            style={{
                width: `${highResWidth}px`,
                height: `${highResHeight}px`,
            }}
        >
            <div 
                className="x-style-time-wrapper"
                style={{
                    top: `${DECORATOR_TOP_DISTANCE}px`,
                    left: `${DECORATOR_LR_MARGIN}px`,
                    width: DECORATOR_WIDTH,
                    gap: `${DECORATOR_GAP}px`
                }}
            >
                <div 
                    className="x-st-left" 
                    style={{
                        background: colorStyle.bgDividerL
                    }}
                />
                <div 
                    className="x-st-logo" 
                    style={{
                        height: `${DECORATOR_SIZE}px`,
                        width: `${DECORATOR_SIZE}px`,
                        background: colorStyle.bgLogo
                    }}
                />
                <div 
                    className="x-st-right" 
                    style={{
                        background: colorStyle.bgDividerR
                    }}
                />
            </div>
            <div 
                className="x-fp-wrapper"
                style={{
                    color: colorStyle.textMain
                }}
            >
                <IconHeartFilled className="x-fp-icon" size={22} strokeWidth={1.5} />
                <div className="x-fp-text">
                    <span className="opacity60">from </span>
                    <span style={{
                        color: colorStyle.textMain,
                    }}>festiplannr</span>
                    <span className="opacity60">.com</span>
                </div>
            </div>
            <div ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}>
                <ReactP5Wrapper sketch={sketch} className="x-p5-wrapper" />
            </div>
            <div 
                className="x-container"
                style={{
                    top: `${iOSExtrasHeight}px`,
                    padding: `${IMG_PADDING}px`
                }}
            >
                {customizationOptions.showSeedPhrase && customizationOptions.visualizationSeed && (
                    <div 
                        className="x-sched-seed-wrapper"
                        style={{ 
                            width: `100%`,
                            paddingBottom: `${SEED_WRAPPER_PADDING_BOTTOM}px`
                        }}
                    >
                        <div 
                            className="x-header-seed"
                            style={{
                                color: "white",
                                fontSize: `${SEED_PHRASE_FONT_SIZE}px`,
                                lineHeight: `${SEED_PHRASE_FONT_SIZE}px`
                            }}
                        >
                            "{customizationOptions.visualizationSeed}"
                        </div>
                    </div>
                )}
                <div 
                    className="x-sched-header-wrapper"
                    style={{ 
                        width: `${contentWidth}px`,
                        borderBottom: `1px solid ${hexToRgbA(colorStyle.textMain, 0.6)}`,
                        paddingBottom: `${HEADER_PADDING_BOTTOM}px`,
                        marginBottom: `${HEADER_MARGIN_BOTTOM}px`,
                    }}
                >
                    <div className="x-header-text">
                        <div 
                            className={`x-shadow x-day`}
                            style={{
                                color: colorStyle.textMain,
                                fontSize: `${HEADER_FONT_SIZE}px`,
                                lineHeight: `${HEADER_LINE_HEIGHT}px`,
                                letterSpacing: "2px"
                            }}
                        >
                            {dayData.day}
                        </div>
                        <div 
                            className="x-shadow x-header-divider" 
                            style={{
                                backgroundColor: colorStyle.textMain
                            }}
                        />
                        <div 
                            className="x-shadow x-festival"
                            style={{
                                color: "white",
                                fontSize: `${HEADER_FONT_SIZE}px`,
                                lineHeight: `${HEADER_LINE_HEIGHT}px`
                            }}
                        >
                            {currentFestival.name}
                        </div>
                    </div>
                    {customizationOptions.contactInfo && (
                        <div className="x-header-contact">
                            <div 
                                className="x-header-contact-default"
                                style={{
                                    color: "white",
                                    fontSize: `${HEADER_FONT_SIZE}px`,
                                    lineHeight: `${HEADER_LINE_HEIGHT}px`
                                }}
                            >
                                if found, pls contact:
                            </div>
                            <div 
                                className="x-header-contact-info"
                                style={{
                                    color: colorStyle.textMain,
                                    fontSize: `${HEADER_FONT_SIZE}px`,
                                    lineHeight: `${HEADER_LINE_HEIGHT}px`
                                }}
                            >
                                {customizationOptions.contactInfo}
                            </div>
                        </div>
                    )}
                </div>
                <div className="x-set-time-schedule">
                    <Timeline 
                        timelineHeight={timelineHeight} 
                        start={currentFestival.daysAndTimes[index].start}
                        end={currentFestival.daysAndTimes[index].end}
                        numStages={dayData.lineup.length}
                        isExport={true}
                        exportWidth={highResWidth - TwoxPadding}
                        colorStyle={colorStyle}
                    />
                    <div className="x-main-stages-wrapper">
                        <div className="stage-headers-wrapper">
                            {renderStageHeaders()}
                        </div>
                        <div className="stages-wrapper">
                            {renderStages()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetTimeImage