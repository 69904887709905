import React, { useState, useEffect } from 'react'

function DefaultFestival() {
    return (
        <div className="">
            lol sup bruhhhh DefaultFestival time!
        </div>
    )
}

export default DefaultFestival