import React from 'react'
import { NavLink } from 'react-router-dom'
import { IconConfetti, IconHome, IconArchive, IconBolt, IconArrowNarrowLeft, IconCalendarSmile, IconFlame, IconSpeakerphone, IconX, IconUsers } from '@tabler/icons-react'

const iconMap = {
    "Festivals": IconConfetti,
    "About": IconBolt,
    "Blasts": IconSpeakerphone,
    "Back to Festivals": IconArrowNarrowLeft,
    "My schedule": IconCalendarSmile,
    "Festival heatmap": IconFlame,
    "Past festivals": IconArchive
}

function NavRow({ rowType, pageTitle, toURL, picture, onRemove, memberCount, onClick, hasIndicator, showPopover, dismissPopover }) {
    const Icon = iconMap[pageTitle]

    const renderRowVisual = () => {
        if (rowType === 'group' && picture) {
            return <img src={picture} alt="Group" className="nav-row-picture" />
        }
        if (rowType === 'recent' && picture) {
            return <img src={picture} alt="Recent" className="nav-row-recent-picture" />
        }
        if (Icon) {
            return <Icon className="nav-row-icon" size={24} strokeWidth={1.5} />
        }
        return null
    }

    const handleRemoveRecent = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        onRemove()
    }

    if (showPopover) console.log("YES PLSEas")

    return (
        <NavLink
            to={toURL}
            className={({ isActive }) => `nav-row ${rowType === 'recent' ? 'nav-row-recent' : ''} ${isActive ? 'nav-row-active' : ''}`}
            onClick={onClick}
        >
            <div className="nav-row-main">
                {renderRowVisual()}
                <p>{pageTitle}</p>
            </div>
            {hasIndicator && <div className="nav-row-indicator" />}
            {rowType === 'recent' && (
                <IconX 
                    size={20} 
                    className="nav-row-remove-icon" 
                    onClick={handleRemoveRecent}
                />
            )}
            {rowType === 'group' && memberCount && (
                <div className="nav-row-member-wrapper">
                    <IconUsers size={16} strokeWidth={1.5} className="nav-row-member-icon" />
                    <div className="nav-row-member-count">{memberCount}</div>
                </div>
            )}
            {/* {showPopover && (
                <div className="nav-row-popover">
                    <div className="nav-row-popover-content">
                        <div className="nav-row-popover-description">Define your schedule here!</div>
                        <div className="nav-row-popover-action" onClick={dismissPopover}>View</div>
                    </div>
                </div>
            )} */}
        </NavLink>
    )
}

export default NavRow
