export const fetchFestivalSchedule = async (filename) => {
    try {
        const response = await fetch(`https://us-central1-festiplannr.cloudfunctions.net/getFestivalSchedule?fileName=${filename}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.ok) {
            const data = await response.text()
            return (data)
        } else {
            console.error('Failed to fetch schedule:', response.statusText)
        }
    } catch (error) {
        console.error('Error:', error)
    }
}
