import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'
import { 
    DEFAULT_BLOCK_FONT_SIZE_SET,
    DEFAULT_BLOCK_FONT_SIZE_NO_SET,
    DEFAULT_COLUMN_WIDTH_SET,
    DEFAULT_COLUMN_WIDTH_SET_MOBILE,
    DEFAULT_COLUMN_WIDTH_NO_SET,
    DEFAULT_NUM_BLOCKS_MOBILE
 } from '../utils/constants'

const UIContext = createContext()

export const useUIContext = () => useContext(UIContext)

export const UIProvider = ({ children }) => {
    const [showDisplayOptions, setShowDisplayOptions] = useLocalStorageState("showDisplayOptions", true)

    const [blockFontSizeSet, setBlockFontSizeSet] = useLocalStorageState("blockFontSizeSet", DEFAULT_BLOCK_FONT_SIZE_SET)
    const [columnWidthSet, setColumnWidthSet] = useLocalStorageState("columnWidthSet", window.innerWidth <= 1000 ? DEFAULT_COLUMN_WIDTH_SET_MOBILE : DEFAULT_COLUMN_WIDTH_SET)
    const [heightScaleSet, setHeightScaleSet] = useLocalStorageState("heightScaleSet", 1)

    const [blockFontSizeNoSet, setBlockFontSizeNoSet] = useLocalStorageState("blockFontSizeNoSet", DEFAULT_BLOCK_FONT_SIZE_NO_SET)
    const [columnWidthNoSet, setColumnWidthNoSet] = useLocalStorageState("columnWidthNoSet", DEFAULT_COLUMN_WIDTH_NO_SET)
    const [heightScaleNoSet, setHeightScaleNoSet] = useLocalStorageState("heightScaleNoSet", 1)

    const [showEndTime, setShowEndTime] = useLocalStorageState("showEndTime", true)
    const [groupAvatarSize, setGroupAvatarSize] = useLocalStorageState("groupAvatarSize", 18)
    const [showGroupAvatars, setShowGroupAvatars] = useLocalStorageState("showGroupAvatars", true)

    const [numBlocksMobile, setNumBlocksMobile]  = useState(DEFAULT_NUM_BLOCKS_MOBILE)

    const value = {
        blockFontSizeSet,
        blockFontSizeNoSet,
        setBlockFontSizeSet,
        setBlockFontSizeNoSet,
        columnWidthSet,
        columnWidthNoSet,
        setColumnWidthSet,
        setColumnWidthNoSet,
        heightScaleSet,
        heightScaleNoSet,
        setHeightScaleSet,
        setHeightScaleNoSet,
        showDisplayOptions,
        setShowDisplayOptions,
        groupAvatarSize,
        setGroupAvatarSize,
        showGroupAvatars,
        setShowGroupAvatars,
        showEndTime,
        setShowEndTime,
        numBlocksMobile,
        setNumBlocksMobile
    }

    return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
