import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './Members.css'
import { IconCopy, IconCalendarSmile } from '@tabler/icons-react'
import { deleteGroup } from '../../services/firebase/databaseService'

const Members = ({ currentUser, currentFestival, groupData, membersData, groupId }) => {
    const navigate = useNavigate()

    const [copyStatus, setCopyStatus] = useState('Copy')
    
    if (!currentFestival || !groupData) return <div className="members-wrapper">Loading...</div>

    const sortedMembers = Object.values(membersData).sort((a, b) => {
        const isAdminA = groupData.admins[a.memberId] || false
        const isAdminB = groupData.admins[b.memberId] || false
        return isAdminB - isAdminA
    })

    const handleCopyLink = () => {
        const link = `https://www.festiplannr.com/${currentFestival.id}/g/${groupId}/join`
        
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link).then(() => {
                setCopyStatus("Copied!")
                setTimeout(() => setCopyStatus('Copy'), 1000)
            }).catch((err) => {
                console.error('Failed to copy: ', err)
            })
        } else {
            // Fallback for older browsers or failure
            const textArea = document.createElement('textarea')
            textArea.value = link
            textArea.style.position = 'fixed'
            textArea.style.left = '-9999px'
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                document.execCommand('copy')
                setCopyStatus("Copied!")
                setTimeout(() => setCopyStatus('Copy'), 1000)
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err)
            }
            document.body.removeChild(textArea)
        }
    }

    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    const handleDeleteGroup = async () => {
        try {
            await deleteGroup(currentUser.uid, currentFestival.id, groupId)
            navigate(`/${currentFestival.id}`)
        } catch (error) {
            console.error('Failed to delete group:', error)
        }
    }

    const isAdmin = groupData.admins[currentUser.uid] || false

    return (
        <div className="members-wrapper">
            <div className="members-list-header">Members</div>
            <p className="opacity50 members-list-subtext">All members can share the link to invite new members.</p>
            <div className="members-list">
                {sortedMembers.map(member => (
                    <div key={member.memberId} className="member-item">
                        <div 
                            className="member-details" 
                            title={`View ${member.displayName}'s profile`}
                            onClick={(e) => handleNavigate(e, `/u/${member.displayName}`)}
                            data-tooltip-id="tt"
                            data-tooltip-content={`Go to ${member.displayName}'s profile`}
                        >
                            <img src={member.picture?.lowRes} alt={member.displayName} className="member-picture" />
                            <p className="member-name">{member.displayName}</p>
                            {groupData.admins[member.memberId] && <div className="admin-badge">Admin</div>}
                        </div>
                        <IconCalendarSmile
                            className="member-calendar-icon" 
                            size={20} 
                            onClick={(e) => handleNavigate(e, `/${currentFestival.id}/s/${member.scheduleId}`)}
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id="tt"
                            data-tooltip-content={`View ${member.displayName}'s schedule`}
                        />
                    </div>
                ))}
            </div>
            {isAdmin && (
                <div className="members-danger-zone">
                    <div className="members-list-header">Admin actions</div>
                    <button className="btn-primary danger-btn btn-delete-group" onClick={handleDeleteGroup}>Delete group</button>
                </div>
            )}
        </div>
    )
}

export default Members
