import React, { useState, useCallback, useEffect, useRef } from 'react'
import { IconMenu2, IconConfetti, IconMinus, IconPlus, IconArrowBarRight, IconArrowRightBar, IconArrowBarToRight, IconDoorExit, IconChevronDown, IconCalendarSmile, IconCalendarUser, IconAdjustmentsHorizontal, IconFlame, IconUsers, IconSpeakerphone, IconHeart, IconHome, IconInfoHexagon, IconChevronUp, IconHeartFilled, IconArrowNarrowRight, IconDoor, IconX } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useFestival } from '../../context/FestivalContext'
import { useUIContext } from '../../context/UIContext'
import { useFavoriteFestivals } from '../../context/FavoriteFestivalContext'
import { NavLink } from 'react-router-dom'
import SignupLoginModal from './SignupLoginModal'
import './MobileNav.css'
import { formatFestivalDates, formatDateRange } from '../../utils/helpers'
import { DEFAULT_BLOCK_FONT_SIZE_SET, DEFAULT_BLOCK_FONT_SIZE_NO_SET, DEFAULT_COLUMN_WIDTH_SET, DEFAULT_COLUMN_WIDTH_SET_MOBILE, DEFAULT_COLUMN_WIDTH_NO_SET, DEFAULT_NUM_BLOCKS_MOBILE } from '../../utils/constants'
import { ref, onValue, get, remove, query, orderByChild, limitToLast, set } from 'firebase/database'
import { database } from '../../config/firebase-config'
import MobileNavTab from './MobileNavTab'
import Nav from './Nav'

const MobileNav = ({ currentUser, isNavOpen, setIsNavOpen, isNavHidden, setIsNavHidden, isNavHiddenByUser, setIsNavHiddenByUser, festivalData, profilePicture }) => {
    const navigate = useNavigate()
    const { currentFestival, userScheduleId } = useFestival()
    const { favorites, addFavorite, removeFavorite } = useFavoriteFestivals()
    const {
        blockFontSizeSet,
        blockFontSizeNoSet,
        setBlockFontSizeSet,
        setBlockFontSizeNoSet,
        columnWidthSet,
        columnWidthNoSet,
        setColumnWidthSet,
        setColumnWidthNoSet,
        heightScaleSet,
        heightScaleNoSet,
        setHeightScaleSet,
        setHeightScaleNoSet,
        showDisplayOptions,
        setShowDisplayOptions,
        groupAvatarSize,
        setGroupAvatarSize,
        showGroupAvatars,
        setShowGroupAvatars,
        showEndTime,
        setShowEndTime,
        numBlocksMobile,
        setNumBlocksMobile
    } = useUIContext()

    const groupsTabRef = useRef(null)
    const recentsTabRef = useRef(null)
    const displayOptionsTabRef = useRef(null)

    // ----------------------- RESET DISPLAY OPTIONS -----------------------
    const resetCustomAttributes = (event) => {
        event.preventDefault()
        event.stopPropagation()
        
        if (currentFestival.hasSetTimes) {
            setBlockFontSizeSet(DEFAULT_BLOCK_FONT_SIZE_SET)
            setColumnWidthSet(DEFAULT_COLUMN_WIDTH_SET_MOBILE)
            setHeightScaleSet(1)
        } else {
            setNumBlocksMobile(DEFAULT_NUM_BLOCKS_MOBILE)
            setHeightScaleNoSet(1)
        }

        setGroupAvatarSize(18)
    }
    let minSizeReached, maxSizeReached = false
    if (currentFestival && currentFestival.hasSetTimes) {
        minSizeReached = blockFontSizeSet === 5
        maxSizeReached = blockFontSizeSet === 20
    } else {
        minSizeReached = blockFontSizeNoSet === 5
        maxSizeReached = blockFontSizeNoSet === 20
    }
    const incrementBlockFontSize = () => {
        if (!maxSizeReached) {
            if (currentFestival && currentFestival.hasSetTimes) {
                setBlockFontSizeSet(blockFontSizeSet + 1)
            } else {
                setBlockFontSizeNoSet(blockFontSizeNoSet + 1)
            }
        }
    }
    const decrementBlockFontSize = () => {
        if (!minSizeReached) {
            if (currentFestival && currentFestival.hasSetTimes) {
                setBlockFontSizeSet(blockFontSizeSet - 1)
            } else {
                setBlockFontSizeNoSet(blockFontSizeNoSet - 1)
            }
        }
    }

    let minBlockNumberReached, maxBlockNumberReached = false
    if (currentFestival) {
        minBlockNumberReached = numBlocksMobile === 1
        maxBlockNumberReached = numBlocksMobile === 8
    }
    const incrementBlockNumber = () => {
        if (!maxBlockNumberReached) setNumBlocksMobile(numBlocksMobile + 1)
    }
    const decrementBlockNumber = () => {
        if (!minBlockNumberReached) setNumBlocksMobile(numBlocksMobile - 1)
    }

    let displayOptionsHeadingText = "General (No set times)"
    if (currentFestival && currentFestival.hasSetTimes) displayOptionsHeadingText = "General (Set time schedules)"

    const handleRemoveRecent = async (creatorId) => {
        const recentRef = ref(database, `users/${currentUser.uid}/recents/${currentFestival.id}/${creatorId}`)
        await remove(recentRef)
    }
    const handleClearAllRecents = async () => {
        const recentsRef = ref(database, `users/${currentUser.uid}/recents/${currentFestival.id}`)
        await remove(recentsRef)
    }

    const handleTitleClick = useCallback(() => { navigate('/') }, [navigate])

    const [userGroups, setUserGroups] = useState([])
    const [userRecents, setUserRecents] = useState([])

    const [modalContent, setModalContent] = useState('signup') // 'signup' or 'login'
    const [isModalOpen, setIsModalOpen] = useState(false)
    const openModal = (content) => {
        setModalContent(content)
        setIsModalOpen(true)
    }
    const closeModal = () => setIsModalOpen(false)

    const [isFavoritesOpen, setIsFavoritesOpen] = useState(false)
    const [isGroupsOpen, setIsGroupsOpen] = useState(false)
    const [isRecentsOpen, setIsRecentsOpen] = useState(false)
    const [isDisplayOptionsOpen, setIsDisplayOptionsOpen] = useState(false)

    useEffect(() => {
        setIsFavoritesOpen(false)
        setUserGroups([])
        setUserRecents([])
        
        if (currentUser && currentFestival) {
            const groupsRef = ref(database, `users/${currentUser.uid}/groups/${currentFestival.id}`)

            // Listen for changes to user's groups
            const unsubscribeGroups = onValue(groupsRef, (snapshot) => {
                const groupsData = snapshot.val() || {}
                const groupIds = Object.keys(groupsData)

                // Set up listeners for each group to handle live updates
                const groupListeners = groupIds.map(groupId => {
                    const groupRef = ref(database, `groups/${groupId}`)
                    return onValue(groupRef, (groupSnapshot) => {
                        setUserGroups(prevGroups => {
                            const updatedGroups = prevGroups.filter(group => group.id !== groupId)
                            return [...updatedGroups, { id: groupId, ...groupSnapshot.val() }]
                        })
                    })
                })

                // Cleanup listeners on unmount
                return () => {
                    groupListeners.forEach(unsubscribe => unsubscribe())
                }
            })

            const recentsRef = ref(database, `users/${currentUser.uid}/recents/${currentFestival.id}`)
            const unsubscribeRecents = onValue(recentsRef, (snapshot) => {
                const recentsData = snapshot.val() || {}
                const recentEntries = Object.keys(recentsData).map(creatorId => ({
                    creatorId,
                    ...recentsData[creatorId]
                }))
                recentEntries.sort((a, b) => b.timestamp - a.timestamp)
                setUserRecents(recentEntries)
            })

            return () => {
                unsubscribeGroups()
                unsubscribeRecents()
            }
        }
    }, [currentUser, currentFestival])

    const handleHeartClick = async (festivalId) => {
        const isFavorite = favorites.includes(festivalId)
        if (isFavorite) {
            await removeFavorite(currentUser.uid, festivalId)
        } else {
            await addFavorite(currentUser.uid, festivalId)
        }
    }

    const sortedFavorites = favorites
        .map(festivalId => ({ id: festivalId, ...festivalData[festivalId] }))
        .sort((a, b) => new Date(a.start) - new Date(b.start))

    const handleFavoritesClick = () => {
        setIsFavoritesOpen(!isFavoritesOpen)
    }
    const handleGroupsClick = () => {
        setIsGroupsOpen(!isGroupsOpen)
        setIsRecentsOpen(false)
        setIsDisplayOptionsOpen(false)

        let groupsContainerWidth = 214
        if (groupsTabRef.current) {
            const rect = groupsTabRef.current.getBoundingClientRect();
            const container = document.querySelector('.mn-expanded-groups');
            let leftPosition = rect.left + rect.width / 2 - groupsContainerWidth / 2;
            leftPosition = Math.max(8, Math.min(leftPosition, window.innerWidth - container.offsetWidth - 8));
            container.style.left = `${leftPosition}px`;
        }
    }
    const handleRecentsClick = () => {
        setIsRecentsOpen(!isRecentsOpen)
        setIsGroupsOpen(false)
        setIsDisplayOptionsOpen(false)

        let recentsContainerWidth = 214
        if (recentsTabRef.current) {
            const rect = recentsTabRef.current.getBoundingClientRect();
            const container = document.querySelector('.mn-expanded-recents');
            let leftPosition = rect.left + rect.width / 2 - recentsContainerWidth / 2;
            leftPosition = Math.max(8, Math.min(leftPosition, window.innerWidth - container.offsetWidth - 8));
            container.style.left = `${leftPosition}px`;
        }
    }
    const handleDisplayOptionsClick = () => {
        setIsDisplayOptionsOpen(!isDisplayOptionsOpen)
        setIsGroupsOpen(false)
        setIsRecentsOpen(false)

        let displayOptionsContainerWidth = 266
        if (displayOptionsTabRef.current) {
            const rect = displayOptionsTabRef.current.getBoundingClientRect();
            const container = document.querySelector('.mn-expanded-display-options');
            let leftPosition = rect.left + rect.width / 2 - displayOptionsContainerWidth / 2;
            leftPosition = Math.max(8, Math.min(leftPosition, window.innerWidth - container.offsetWidth - 8));
            container.style.left = `${leftPosition}px`;
        }
    }

    const handleClickHomeTab = () => {
        setIsFavoritesOpen(false)
    }
    const handleClickFestivalTab = () => {
        setIsFavoritesOpen(false)
        setIsGroupsOpen(false)
        setIsRecentsOpen(false)
        setIsDisplayOptionsOpen(false)
    }

    const handleNavToggle = () => {
        if (isNavHiddenByUser) {
            // If the Nav was explicitly closed by the user and they are reopening it
            setIsNavHiddenByUser(false);
            setIsNavHidden(false);
        } else {
            // If the Nav was automatically closed or manually opened, handle normally
            setIsNavHiddenByUser(true);
            setIsNavHidden(true);
        }
    }

    const [hasNewAnnouncements, setHasNewAnnouncements] = useState(false);

    useEffect(() => {
        const checkNewAnnouncements = () => {
            if (currentUser) {
                const lastVisitRef = ref(database, `users/${currentUser.uid}/lastAnnouncementVisit`);
                
                // Listen for real-time updates to lastAnnouncementVisit
                const unsubscribeLastVisit = onValue(lastVisitRef, (snapshot) => {
                    const lastVisit = snapshot.val();
                    
                    // Create a query for the announcements ordered by 'timestamp' and limited to the last 1
                    const announcementsQuery = query(ref(database, 'announcements'), orderByChild('timestamp'), limitToLast(1));
                    get(announcementsQuery).then((latestAnnouncementSnapshot) => {
                        const latestAnnouncement = latestAnnouncementSnapshot.val();
            
                        if (latestAnnouncement) {
                            const latestTimestamp = Object.values(latestAnnouncement)[0].timestamp;
                            if (latestTimestamp > lastVisit) {
                                setHasNewAnnouncements(true);
                            } else {
                                setHasNewAnnouncements(false); // Remove indicator if no new announcements
                            }
                        }
                    });
                });

                return () => {
                    unsubscribeLastVisit();
                };
            }
        };

        checkNewAnnouncements();
    }, [currentUser]);


    if (currentFestival) {
        if (currentUser) {
            return (
                <React.Fragment>
                    <div className={`mobile-nav-wrapper ${isNavHidden ? '' : 'show-m-nav'}`}>
                        <div className="mn-contents mn-contents-v">
                            <div className="mn-icons">
                                <MobileNavTab 
                                    tabName="Mine"
                                    toUrl={`/${currentFestival.id}/s/${userScheduleId}`}
                                    handleClick={handleClickFestivalTab}
                                />
                                <MobileNavTab 
                                    tabName="Heatmap"
                                    toUrl={`/${currentFestival.id}/heatmap`}
                                    handleClick={handleClickFestivalTab}
                                />
                                <MobileNavTab
                                    tabName="Groups"
                                    isExpandable={true}
                                    isExpanded={isGroupsOpen}
                                    handleClick={handleGroupsClick}
                                    ref={groupsTabRef}
                                />
                                <MobileNavTab
                                    tabName="Recents"
                                    isExpandable={true}
                                    isExpanded={isRecentsOpen}
                                    handleClick={handleRecentsClick}
                                    ref={recentsTabRef}
                                />
                                <MobileNavTab
                                    tabName="Display"
                                    isExpandable={true}
                                    isExpanded={isDisplayOptionsOpen}
                                    handleClick={handleDisplayOptionsClick}
                                    ref={displayOptionsTabRef}
                                />
                                {/* <MobileNavTab 
                                    tabName="Festivals"
                                    toUrl={`/`}
                                    handleClick={handleClickFestivalTab}
                                    isFestivalPage={true}
                                /> */}
                            </div>
                            <div className="mn-festival-info">
                                <div className="mn-line mn-line-1" />
                                <p className="mn-festival-info-name">{currentFestival.name}</p>
                                <div className="mn-line mn-line-2" />
                            </div>
                        </div>
                    </div>
                    <div 
                        className={`mn-only mn-close-bar-wrapper mn-festi-close-bar-wrapper ${isNavHidden ? 'mn-close-bar-show' : ''}`} 
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsGroupsOpen(false)
                            setIsRecentsOpen(false)
                            setIsDisplayOptionsOpen(false)
                            handleNavToggle()
                        }}
                    >
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                    </div>
                    <div className={`mn-only mn-expanded-container mn-expanded-sched-bar ${isFavoritesOpen ? 'mn-sched-visible' : ''}`}>
                        <h5>Favorites</h5>
                        {Object.keys(sortedFavorites).length === 0 && (
                            <p className="mn-empty-text"><i>Festivals you've favorited will show up here for easier access!</i></p>
                        )}
                        {sortedFavorites.map(festival => {
                            const formattedDateRange = formatDateRange(festival.start, festival.end)
                            
                            return (
                                <div key={`fav-${festival.id}`} className="mn-fav-item" onClick={() => navigate(`/${festival.id}`)}>
                                    <p>{festival.name}</p>
                                    <p>{formattedDateRange}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`mn-only mn-expanded-container mn-expanded-sched-bar mn-expanded-display-options ${isDisplayOptionsOpen ? 'mn-sched-visible' : ''}`}>
                        <div className="display-options-header" onClick={() => setShowDisplayOptions(!showDisplayOptions)}>
                            <div className="display-options-header-wrapper">
                                <IconAdjustmentsHorizontal size={20} strokeWidth={1.5} />
                                <div className="display-options-header-text">Schedule display</div>
                            </div>
                            <div className="display-options-header-actions">
                                <p className="btn-with-icon btn-nav-reset" onClick={(event) => resetCustomAttributes(event)} title="Reset to default">Reset</p>
                            </div>
                        </div>
                        <div className="nav-all-settings-wrapper">
                            <div className="nav-setting-with-reset"> 
                                <div className="nav-setting-wrapper nav-setting-stepper">
                                    <div className="nav-settings-label" htmlFor="font-size">Font size</div>
                                    <IconMinus 
                                        onClick={decrementBlockFontSize}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${minSizeReached ? "disabled" : ""}`}
                                        title="Decrease font size"
                                    />
                                    <p className="nav-font-size-container">{currentFestival.hasSetTimes ? blockFontSizeSet : blockFontSizeNoSet}</p>
                                    <IconPlus 
                                        onClick={incrementBlockFontSize}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${maxSizeReached ? "disabled" : ""}`}
                                        title="Increase font size"
                                    />
                                </div>
                            </div>
                            {currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper">
                                    <div className="nav-settings-label">Block width</div>
                                    <input
                                        id="width-slider"
                                        type="range"
                                        value={columnWidthSet}
                                        min="64"
                                        max="360"
                                        step="1"
                                        onChange={e => {setColumnWidthSet(Number(e.target.value))}
                                        }
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            )}
                            {!currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper nav-setting-stepper">
                                    <div className="nav-settings-label" htmlFor="font-size">Blocks / row</div>
                                    <IconMinus 
                                        onClick={decrementBlockNumber}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${minBlockNumberReached ? "disabled" : ""}`}
                                        title="Decrease font size"
                                    />
                                    <p className="nav-font-size-container">{numBlocksMobile}</p>
                                    <IconPlus 
                                        onClick={incrementBlockNumber}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${maxBlockNumberReached ? "disabled" : ""}`}
                                        title="Increase font size"
                                    />
                                </div>
                            )}
                            {!currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper">
                                    <div className="nav-settings-label">Block height</div>
                                    <input
                                        id="height-slider"
                                        type="range"
                                        value={currentFestival.hasSetTimes ? heightScaleSet : heightScaleNoSet}
                                        min="0.1"
                                        max="2.5"
                                        step="0.01"
                                        onChange={e => {
                                            if (currentFestival.hasSetTimes) {
                                                setHeightScaleSet(Number(e.target.value))
                                            } else {
                                                setHeightScaleNoSet(Number(e.target.value))
                                            }
                                        }}
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            )}
                            {currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper nav-toggle-margin">
                                    <div 
                                        className={`nav-toggle-wrapper ${!currentFestival.hasSetTimes ? 'toggle-disabled' : ''}`} 
                                        onClick={() => { if (currentFestival.hasSetTimes) setShowEndTime(prev => !prev) }}
                                    >
                                        <label className="switch">
                                            <input 
                                                type="checkbox" 
                                                checked={showEndTime} 
                                                onChange={() => setShowEndTime(prev => !prev)} 
                                                disabled={!currentFestival.hasSetTimes}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <div className={`nav-settings-toggle-label`}>Show end times</div>
                                    </div>
                                </div>
                            )}
                            <h5 className="nav-setting-section">Groups</h5>
                            <div className="nav-setting-wrapper">
                                <div className="nav-toggle-wrapper" onClick={() => setShowGroupAvatars(prev => !prev)}>
                                    <label className="switch">
                                        <input 
                                            type="checkbox" 
                                            checked={showGroupAvatars} 
                                            onChange={() => setShowGroupAvatars(prev => !prev)} 
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="nav-settings-toggle-label">Show avatars in group heatmap</div>
                                </div>
                            </div>
                            {showGroupAvatars && (
                                <div className="nav-setting-wrapper">
                                    <div className="nav-settings-label">Avatar size</div>
                                    <input
                                        id="height-slider"
                                        type="range"
                                        value={groupAvatarSize}
                                        min="12"
                                        max="32"
                                        step="1"
                                        onChange={e => setGroupAvatarSize(Number(e.target.value))}
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`mn-only mn-expanded-container mn-expanded-sched-bar mn-expanded-recents ${isRecentsOpen ? 'mn-sched-visible' : ''}`}>
                        <div className="display-options-header mn-recents-header" onClick={() => setShowDisplayOptions(!showDisplayOptions)}>
                            <div className="display-options-header-wrapper">
                                <IconCalendarUser size={20} strokeWidth={1.5} />
                                <div className="display-options-header-text">Recents</div>
                            </div>
                            {userRecents.length !== 0 && (
                                <div className="display-options-header-actions">
                                    <p className="btn-with-icon btn-nav-reset" onClick={handleClearAllRecents}>Clear all</p>
                                </div>
                            )}
                        </div>
                        {userRecents.length === 0 && (
                            <p className="mn-empty-text mn-recents-empty"><i>Recent schedules you've viewed for this festival will appear here!</i></p>
                        )}
                        <div className="mn-recents-wrapper">
                            {userRecents.map(recent => {
                                return (
                                    <NavLink 
                                        to={`/${currentFestival.id}/s/${recent.scheduleId}`}
                                        className={({ isActive }) => `mn-recent-item ${isActive ? 'mn-recent-item-active' : ''}`}
                                        key={recent.creatorId}
                                        onClick={handleRecentsClick}
                                    >
                                        <div className="mn-recent-info">
                                            <img src={recent.picture} className="mn-recent-img" />
                                            <p>{recent.displayName}</p>
                                        </div>
                                        <IconX 
                                            size={20} 
                                            strokeWidth={1.5} 
                                            className="mn-recent-remove" 
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                handleRemoveRecent(recent.creatorId)
                                            }} 
                                        />
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                    <div className={`mn-only mn-expanded-container mn-expanded-sched-bar mn-expanded-groups ${isGroupsOpen ? 'mn-sched-visible' : ''}`}>
                        <div className="display-options-header mn-recents-header" onClick={() => setShowDisplayOptions(!showDisplayOptions)}>
                            <div className="display-options-header-wrapper">
                                <IconUsers size={20} strokeWidth={1.5} />
                                <div className="display-options-header-text">Groups</div>
                            </div>
                        </div>
                        {userGroups.length === 0 && (
                            <p className="mn-empty-text mn-recents-empty"><i>Your groups for this festival will appear here!</i></p>
                        )}
                        <div className="mn-groups-wrapper">
                            {userGroups.map(group => {
                                
                                return (
                                    <NavLink
                                        to={`/${currentFestival.id}/g/${group.id}`}
                                        className={({ isActive }) => `mn-recent-item ${isActive ? 'mn-recent-item-active' : ''}`}
                                        key={group.id}
                                        onClick={handleGroupsClick}
                                    >
                                        <div className="mn-recent-info">
                                            <img src={group.picture} className="mn-group-img" />
                                            <p>{group.name}</p>
                                        </div>
                                        <div className="mn-group-num-wrapper">
                                            <IconUsers size={16} strokeWidth={1.5} />
                                            <p className="mn-group-num">{group.memberCount}</p>
                                        </div>
                                    </NavLink>
                                )
                            })}
                        </div>
                        <div className="mn-new-group-row">
                            <NavLink
                                to={`/${currentFestival.id}/g/new`}
                                className={({ isActive }) => `mn-recent-item ${isActive ? 'mn-recent-item-active' : ''}`}
                                onClick={handleGroupsClick}
                            >
                                <div className="mn-recent-info mn-new-group">
                                    <IconPlus size={20} strokeWidth={1.5} />
                                    <p>New group</p>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className={`mobile-nav-wrapper ${isNavHidden ? '' : 'show-m-nav'}`}>
                        <div className="mn-contents mn-contents-v">
                            <div className="mn-icons">
                                <MobileNavTab 
                                    tabName="Mine"
                                    toUrl={`/${currentFestival.id}/s/new`}
                                    handleClick={handleClickFestivalTab}
                                />
                                <MobileNavTab
                                    tabName="Display"
                                    isExpandable={true}
                                    isExpanded={isDisplayOptionsOpen}
                                    handleClick={handleDisplayOptionsClick}
                                    ref={displayOptionsTabRef}
                                />
                                <MobileNavTab 
                                    tabName="Festivals"
                                    toUrl={`/`}
                                    handleClick={handleClickFestivalTab}
                                    isFestivalPage={true}
                                />
                                <button className="btn-primary mn-btn-signup" onClick={() => openModal('signup')}>Sign up</button>
                            </div>
                            <div className="mn-festival-info">
                                <div className="mn-line mn-line-1" />
                                <p className="mn-festival-info-name">{currentFestival.name}</p>
                                <div className="mn-line mn-line-2" />
                            </div>
                        </div>
                    </div>
                    <SignupLoginModal 
                        isOpen={isModalOpen} 
                        onRequestClose={closeModal} 
                        content={modalContent} 
                        setModalContent={setModalContent} 
                    />
                    <div 
                        className={`mn-only mn-close-bar-wrapper mn-festi-close-bar-wrapper ${isNavHidden ? 'mn-close-bar-show' : ''}`} 
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsGroupsOpen(false)
                            setIsRecentsOpen(false)
                            setIsDisplayOptionsOpen(false)
                            handleNavToggle()
                        }}
                    >
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                    </div>
                    <div className={`mn-only mn-expanded-container mn-expanded-sched-bar mn-expanded-display-options ${isDisplayOptionsOpen ? 'mn-sched-visible' : ''}`}>
                        <div className="display-options-header" onClick={() => setShowDisplayOptions(!showDisplayOptions)}>
                            <div className="display-options-header-wrapper">
                                <IconAdjustmentsHorizontal size={20} strokeWidth={1.5} />
                                <div className="display-options-header-text">Schedule display</div>
                            </div>
                            <div className="display-options-header-actions">
                                <p className="btn-with-icon btn-nav-reset" onClick={(event) => resetCustomAttributes(event)} title="Reset to default">Reset</p>
                            </div>
                        </div>
                        <div className="nav-all-settings-wrapper">
                            <div className="nav-setting-with-reset"> 
                                <div className="nav-setting-wrapper nav-setting-stepper">
                                    <div className="nav-settings-label" htmlFor="font-size">Font size</div>
                                    <IconMinus 
                                        onClick={decrementBlockFontSize}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${minSizeReached ? "disabled" : ""}`}
                                        title="Decrease font size"
                                    />
                                    <p className="nav-font-size-container">{currentFestival.hasSetTimes ? blockFontSizeSet : blockFontSizeNoSet}</p>
                                    <IconPlus 
                                        onClick={incrementBlockFontSize}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${maxSizeReached ? "disabled" : ""}`}
                                        title="Increase font size"
                                    />
                                </div>
                            </div>
                            {currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper">
                                    <div className="nav-settings-label">Block width</div>
                                    <input
                                        id="width-slider"
                                        type="range"
                                        value={columnWidthSet}
                                        min="64"
                                        max="360"
                                        step="1"
                                        onChange={e => {setColumnWidthSet(Number(e.target.value))}
                                        }
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            )}
                            {!currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper nav-setting-stepper">
                                    <div className="nav-settings-label" htmlFor="font-size">Blocks / row</div>
                                    <IconMinus 
                                        onClick={decrementBlockNumber}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${minBlockNumberReached ? "disabled" : ""}`}
                                        title="Decrease font size"
                                    />
                                    <p className="nav-font-size-container">{numBlocksMobile}</p>
                                    <IconPlus 
                                        onClick={incrementBlockNumber}
                                        size={16}
                                        strokeWidth={1.5}
                                        className={`icon-nav-settings-stepper ${maxBlockNumberReached ? "disabled" : ""}`}
                                        title="Increase font size"
                                    />
                                </div>
                            )}
                            {!currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper">
                                    <div className="nav-settings-label">Block height</div>
                                    <input
                                        id="height-slider"
                                        type="range"
                                        value={currentFestival.hasSetTimes ? heightScaleSet : heightScaleNoSet}
                                        min="0.1"
                                        max="2.5"
                                        step="0.01"
                                        onChange={e => {
                                            if (currentFestival.hasSetTimes) {
                                                setHeightScaleSet(Number(e.target.value))
                                            } else {
                                                setHeightScaleNoSet(Number(e.target.value))
                                            }
                                        }}
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            )}
                            {currentFestival.hasSetTimes && (
                                <div className="nav-setting-wrapper nav-toggle-margin">
                                    <div 
                                        className={`nav-toggle-wrapper ${!currentFestival.hasSetTimes ? 'toggle-disabled' : ''}`} 
                                        onClick={() => { if (currentFestival.hasSetTimes) setShowEndTime(prev => !prev) }}
                                    >
                                        <label className="switch">
                                            <input 
                                                type="checkbox" 
                                                checked={showEndTime} 
                                                onChange={() => setShowEndTime(prev => !prev)} 
                                                disabled={!currentFestival.hasSetTimes}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <div className={`nav-settings-toggle-label`}>Show end times</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    } else {
        if (currentUser) {
            return (
                <React.Fragment>
                    <div className={`mobile-nav-wrapper ${isNavHidden ? '' : 'show-m-nav'}`}>
                        <div className="mn-contents">
                            <div className="mn-icons">
                                <MobileNavTab 
                                    tabName="Festivals"
                                    toUrl="/"
                                    handleClick={handleClickHomeTab}
                                />
                                <MobileNavTab 
                                    tabName="Past"
                                    toUrl="/pastfestivals"
                                    handleClick={handleClickHomeTab}
                                />
                                <MobileNavTab 
                                    tabName="Blasts"
                                    toUrl="/blasts"
                                    hasIndicator={hasNewAnnouncements}
                                    handleClick={handleClickHomeTab}
                                />
                            </div>
                            <div className="mn-icons mn-icons-wide-gap">
                                <MobileNavTab
                                    tabName="Favs"
                                    isExpandable={true}
                                    isExpanded={isFavoritesOpen}
                                    handleClick={handleFavoritesClick}
                                />
                                <img 
                                    src={currentUser.photoURL} 
                                    className="mn-propic" 
                                    onClick={() => {
                                        navigate(`/u/${currentUser.displayName}`)
                                        setIsFavoritesOpen(false)
                                    }}
                                />
                            </div>
                            <div className={`mn-expanded-container ${isFavoritesOpen ? 'mn-fav-visible' : ''}`}>
                                <h5>Favorites</h5>
                                {Object.keys(sortedFavorites).length === 0 && (
                                    <p className="mn-empty-text"><i>Festivals you've favorited will show up here for easier access!</i></p>
                                )}
                                <div className="mn-favs-wrapper">
                                    {sortedFavorites.map(festival => {
                                        const formattedDateRange = formatDateRange(festival.start, festival.end)
                                        
                                        return (
                                            <div key={`fav-${festival.name}`} className="mn-fav-item" onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                navigate(`/${festival.id}`)
                                            }}>
                                                <div className="mn-fav-details">
                                                    <img className="mn-fav-img" src={festival.imageUrls.low} />
                                                    <div className="mn-fav-text-wrapper">
                                                        <p className="mn-fav-name">{festival.name}</p>
                                                        <p className="mn-fav-dates">{formattedDateRange}</p>
                                                    </div>
                                                </div>
                                                <IconHeartFilled size={20} strokeWidth={1.5} className="mn-fav-icon" onClick={(e) => { 
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    handleHeartClick(festival.id) 
                                                }} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        className={`mn-only mn-close-bar-wrapper ${isNavHidden ? 'mn-close-bar-show' : ''}`} 
                        onClick={() => {
                            handleNavToggle()
                        }}
                    >
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className={`mobile-nav-wrapper ${isNavHidden ? '' : 'show-m-nav'}`}>
                        <div className="mn-contents">
                            <div className="mn-icons">
                                <MobileNavTab 
                                    tabName="Festivals"
                                    toUrl="/"
                                    handleClick={handleClickHomeTab}
                                />
                                <MobileNavTab 
                                    tabName="Past"
                                    toUrl="/pastfestivals"
                                    handleClick={handleClickHomeTab}
                                />
                                <MobileNavTab 
                                    tabName="Blasts"
                                    toUrl="/blasts"
                                    handleClick={handleClickHomeTab}
                                />
                            </div>
                            <div className="mn-icons">
                                <button className="btn-primary mn-btn-signup" onClick={() => openModal('signup')}>Sign up</button>
                            </div>
                        </div>
                    </div>
                    <SignupLoginModal 
                        isOpen={isModalOpen} 
                        onRequestClose={closeModal} 
                        content={modalContent} 
                        setModalContent={setModalContent} 
                    />
                    <div 
                        className={`mn-only mn-close-bar-wrapper ${isNavHidden ? 'mn-close-bar-show' : ''}`} 
                        onClick={() => {
                            handleNavToggle()
                        }}
                    >
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                        <IconArrowNarrowRight size={18} strokeWidth={1.5} className={`mn-close-bar`} />
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default MobileNav