import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './BottomContainer.css'
import { IconHeartFilled, IconX } from '@tabler/icons-react'

const BottomContainer = ({ artist, members, onClose, heatmapType }) => {
    const navigate = useNavigate()
    const bottomContainerRef = useRef(null)

    const [fadeIn, setFadeIn] = useState(false)
    const closeTimeoutRef = useRef(null)
    const previousArtistRef = useRef(artist)
    const previousMembersRef = useRef(members)

    useEffect(() => {
        setFadeIn(true)
    }, [])
    const handleClose = () => {
        setFadeIn(false)
        closeTimeoutRef.current = setTimeout(() => {
            onClose()
        }, 150)
    }
    useEffect(() => {
        if (previousArtistRef.current !== artist || previousMembersRef.current !== members) {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current)
            }

            // Reset fade-in effect
            setFadeIn(false)
            setTimeout(() => setFadeIn(true), 0)

            // Update the previous artist and members refs
            previousArtistRef.current = artist
            previousMembersRef.current = members
        }
    }, [artist, members])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bottomContainerRef.current && !bottomContainerRef.current.contains(event.target)) { handleClose() }
        }
        const handleKeydown = (event) => {
            if (event.key === 'Escape') { handleClose() }
        }

        document.addEventListener('mouseup', handleClickOutside)
        document.addEventListener('keydown', handleKeydown)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
            document.removeEventListener('keydown', handleKeydown)
        }
    }, [onClose])

    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    const filteredMembers = heatmapType === 1
        ? members.filter(member => member.vote === 2)
        : members
    const sortedMembers = filteredMembers.sort((a, b) => {
        if (b.vote === a.vote) {
            return a.displayName.localeCompare(b.displayName)
        }
        return b.vote - a.vote
    })

    const totalVotes2 = members.filter(member => member.vote === 2).length
    const totalVotes1or2 = members.filter(member => member.vote === 1 || member.vote === 2).length

    return (
        <div className={`bottom-container-wrapper ${fadeIn ? 'fade-in' : ''}`}>
            <div ref={bottomContainerRef} className="bottom-container">
                <div className="bottom-artist-name">{artist}</div>
                <div className="bottom-vote-counts">
                    <div className="bottom-vote-counts-container">
                        <IconHeartFilled size={18} className="bottom-vote-heart" />
                        <div className="bottom-vote-count-result">{totalVotes2}</div>
                    </div>
                    {heatmapType !== 1 && (
                        <>
                            <div className="bottom-vote-separator">|</div>
                            <div className="bottom-vote-counts-container">
                                <div className="bottom-vote-count-label">Total:</div>
                                <div className="bottom-vote-count-result">{totalVotes1or2}</div>
                            </div>
                        </>
                    )}
                </div>
                {totalVotes1or2 > 0 && (
                    <div className="bottom-members">
                        {sortedMembers.map(member => (
                            <div 
                                key={member.memberId} 
                                className="bottom-member"
                                onClick={(e) => handleNavigate(e, `/u/${member.displayName}`)}
                                data-tooltip-id="tt"
                                data-tooltip-content={member.displayName}
                            >
                                <img src={member.picture.lowRes} alt={member.displayName} className={`bottom-member-picture ${member.vote === 2 ? 'vote-2' : ''}`} />
                                {member.vote === 2 && <IconHeartFilled className="bottom-heart" />}
                            </div>
                        ))}
                    </div>
                )}
                <IconX size={16} className="bottom-close" onClick={handleClose} />
            </div>
        </div>
    )
}

export default BottomContainer
