import React, { useState } from 'react'
import { IconClock, IconFlame, IconArrowNarrowLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import HeatmapTypesModal from '../General/HeatmapTypesModal'
import RequestModal from '../General/RequestModal'

const HeatmapHeader = ({ currentFestival, currentUser, festivalHeatmapLastUpdate }) => {
    const windowWidth = window.innerWidth
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
    const openRequestModal = () => setIsRequestModalOpen(true)
    const closeRequestModal = () => setIsRequestModalOpen(false)

    const getTimeSinceLastUpdate = (lastUpdate) => {
        if (!lastUpdate) return "—"
        const now = Date.now();
        const differenceInMs = now - lastUpdate;
        const differenceInMinutes = Math.floor(differenceInMs / 60000); // Convert ms to minutes
        return differenceInMinutes;
    }

    const minutesAgo = getTimeSinceLastUpdate(festivalHeatmapLastUpdate)
    let minutesText = "minutes"
    if (minutesAgo === 1) minutesText = "minute"

    if (currentFestival) {
        return (
            <React.Fragment>
                <div className="main-header">
                    <div className="mh-header-wrapper">
                        <div className="mh-festival-name">
                            <p>Festival heatmap</p>
                            <div className="separator-dot" />
                            <p>{currentFestival.name}</p>
                        </div>
                        <div className="report-issue-wrapper">
                            {windowWidth <= 1000 && (
                                <div className="btn-with-icon" onClick={() => navigate("/")}>
                                    <IconArrowNarrowLeft size={20} strokeWidth={1.5} />
                                    <p>Back to Festivals</p>
                                </div>
                            )}
                            <div className="btn-text-link" onClick={openRequestModal}>Report an issue</div>
                        </div>
                    </div>
                    <div className="mh-title-container">
                        <div className="mh-heatmap-title">
                            <IconFlame size={28} strokeWidth={1.5} />
                            <div className="mh-title">
                                Festival heatmap
                            </div>
                        </div>
                    </div>
                    <div className="mh-explanatory-text">
                        <div className="mh-last-update opacity80">
                            <IconClock size={16} />
                            <p>Last update: {minutesAgo} {minutesText} ago</p>
                        </div>
                        <p className="opacity50">Aggregated, anonymized data from all schedules. Recalculated every 10 minutes.</p>
                        <p><span className="btn-text-link" onClick={openModal}>Learn more</span><span className="opacity50"> about heatmap types</span></p>
                    </div>
                </div>
                <HeatmapTypesModal 
                    isOpen={isModalOpen} 
                    onRequestClose={closeModal} 
                />
                <RequestModal 
                    isOpen={isRequestModalOpen} 
                    onRequestClose={closeRequestModal} 
                    requestType={"Something's fucky"}
                    currentUser={currentUser}
                    origin={`Festival heatmap, ${currentFestival.id}`}
                />
            </React.Fragment>
        )
    }
}

export default HeatmapHeader