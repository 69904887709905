import { useEffect, useState } from 'react'

export const useLocalStorageState = (key, initialState) => {
    const [state, setState] = useState(() => {
        const storedValue = localStorage.getItem(key)
        try {
            const parsed = JSON.parse(storedValue)

            // Handle Set conversion
            if (initialState instanceof Set) {
                return new Set(parsed)
            }
            
            return parsed ?? initialState
        } catch {
            return initialState
        }
    })

    useEffect(() => {
        // Handle Set conversion for storage
        const valueToStore = state instanceof Set ? Array.from(state) : state
        localStorage.setItem(key, JSON.stringify(valueToStore))
    }, [key, state])

    return [state, setState]
}

