// ---------------------------- parseTSVtoObject() ----------------------------
// Converts tsv of schedule data to a JS object
export function parseTSVtoObject(tsvData) {
    // Normalize line endings and trim whitespace
    const normalizedData = tsvData.replace(/\r\n/g, '\n').trim()

    const rows = normalizedData.split('\n')
    const headers = rows.shift().split('\t').map(header => header.trim())

    return rows.map(row => {
        const values = row.split('\t')

        return headers.reduce((object, header, index) => {
            object[header] = values[index]
            return object
        }, {})
    })
}

// --------------------------------- formatTime() ---------------------------------
// Converts a HH:MM AM/PM time string to hh:mm
export function formatTime(timeStr) {
    const [time, period] = timeStr.split(' ')
    const [hours, minutes] = time.split(':')

    if (minutes === "00") return hours // Return just the hour part if minutes are "00"

    return `${hours}:${minutes}` // Return the full time without AM/PM
}

// --------------------------------- parseTime() ---------------------------------
// Creates a Date object from a HH:MM AM/PM time string
function parseTime(timeStr) {
    const parts = timeStr.split(' ')
    const time = parts[0]
    const period = parts[1] // AM or PM
    let [hours, minutes] = time.split(':').map(Number)

    // Convert 12-hour format to 24-hour.
    if (hours === 12) {
        hours = period === 'AM' ? 0 : 12 // Convert "12 AM" to 00:00 and "12 PM" to 12:00
    } else {
        hours = period === 'PM' ? hours + 12 : hours
    }

    const now = new Date()
    const dateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, 0)

    // Handles the case where the event spans past midnight
    if (period === 'AM') dateTime.setDate(dateTime.getDate() + 1)

    return dateTime
}

// --------------------------------- formatDateRange() ---------------------------------
// Creates a formatted string in the form "Mon, Jan 01 – Tue, Jan 02"
export function formatDateRange (start, end) {
    const options = { weekday: 'short', month: 'short', day: '2-digit' }
    const startDate = new Date(start).toLocaleDateString('en-US', options)
    const endDate = new Date(end).toLocaleDateString('en-US', options)
    return `${startDate} – ${endDate}`
}

// -------------------------- getBlockHeightAndPosition() --------------------------
// Calculates block height and position based on timeline height + start/end times
export function getBlockHeightAndPosition(tlStart, tlEnd, tlHeight, blockStart, blockEnd) {
    let timelineStart = new Date()
    timelineStart.setHours(tlStart, 0, 0, 0)
    let timelineEnd = new Date()
    timelineEnd.setHours(tlEnd, 0, 0, 0)
    if (tlEnd <= tlStart) timelineEnd.setDate(timelineEnd.getDate() + 1) // Adjust for ending on the next day

    const startTime = parseTime(blockStart)
    const endTime = parseTime(blockEnd)
    const durationInMinutes = (endTime - startTime) / 60000
    const startOffsetInMinutes = (startTime - timelineStart) / 60000

    // Convert times into a percentage of the total timeline duration
    const timelineDuration = (timelineEnd - timelineStart) / 60000
    const topPercentage = startOffsetInMinutes / timelineDuration
    const heightPercentage = durationInMinutes / timelineDuration

    // Convert percentages into pixels based on the timeline height
    const blockHeight = heightPercentage * tlHeight
    const topPosition = topPercentage * tlHeight

    return ({
        "height": blockHeight,
        "top": topPosition
    })
}

export function hexToRgbA(hex, opacity){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

export function resizeImage (image, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = image
        img.onload = () => {
            let { width, height } = img

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width
                    width = maxWidth
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height
                    height = maxHeight
                }
            }

            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL('image/jpeg', 0.8)) // Adjust quality as needed
        }
        img.onerror = (err) => {
            reject(err)
        }
    })
}

export function formatFestivalDates(start, end) {
    const options = { month: 'short', day: 'numeric', weekday: 'short' };
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startWeekday = startDate.toLocaleDateString('en-US', { weekday: 'short' });
    const startMonth = startDate.toLocaleDateString('en-US', { month: 'short' });
    const endMonth = endDate.toLocaleDateString('en-US', { month: 'short' });
    
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    if (startMonth === endMonth) {
        return `${startWeekday}, ${startMonth} ${startDay} – ${endDay}`;
    } else {
        return `${startWeekday}, ${startMonth} ${startDay} – ${endMonth} ${endDay}`;
    }
}

export const formatRelativeTime = (timestamp) => {
    const now = Date.now()
    const diffInSeconds = Math.floor((now - timestamp) / 1000)
    const minutes = Math.floor(diffInSeconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const weeks = Math.floor(days / 7)
    const years = Math.floor(days / 365)

    if (years > 0) return `${years}y`
    if (weeks > 0) return `${weeks}w`
    if (days > 0) return `${days}d`
    if (hours > 0) return `${hours}h`
    if (minutes > 0) return `${minutes}m`
    if (diffInSeconds >= 10 && diffInSeconds < 60) return `<1m`
    return 'Just now'
}