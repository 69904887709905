import React, { useMemo } from 'react'
import Block2 from './Block2'
import { useUIContext } from '../../context/UIContext'
import { encodeKey } from '../../utils/scheduleHelpers'

const Stage = React.memo(({ 
    scheduleType,
    scheduleId,
    currentUser,
    festival, 
    stageName, 
    selectedDayIndex, 
    artists, 
    timelineHeight, 
    stageColor, 
    onMyScheduleBlockClick, 
    blockSelections, 
    localBlockSelections,
    heatmapType, 
    festivalHeatmapData,
    heatmapColor, 
    groupData,
    membersData,
    handleGroupBlockClick,
    isCreator,
    groupSelections
}) => {
    const { columnWidthSet } = useUIContext()
    
    return (
        <div className="single-stage-wrapper" style={{ width: columnWidthSet }} key={`${stageName}`}>
            <div className="stage-blocks" style={{ width: `${columnWidthSet - 10}px` }}>
                {artists.map((artist, index) => {
                    let artistDay       = artist.Day.toUpperCase()
                    let artistName      = artist.Artist.toUpperCase()
                    let artistStart     = artist.Start.toUpperCase()
                    let selectedDay     = festival.daysAndTimes[selectedDayIndex].day.toUpperCase()

                    if (artistDay === selectedDay) {
                        const blockKey = `${stageName.toUpperCase()}-${artistStart}-${artistDay}-${artistName}`

                        // For "userSchedule" scheduleType
                        let clickCount = blockSelections[encodeKey(blockKey)] || 0
                        if (!currentUser && scheduleId === "new") clickCount = localBlockSelections[encodeKey(blockKey)] || 0

                        // For "festivalHeatmap" or "group" scheduleType
                        let blockHeatmapData
                        if (scheduleType === "festivalHeatmap" && festivalHeatmapData) {
                            blockHeatmapData = festivalHeatmapData[encodeKey(blockKey)] || null
                        } else if (scheduleType === "group" && groupData?.heatmap) {
                            blockHeatmapData = groupData.heatmap[encodeKey(blockKey)] || null
                        }
                        
                        return (
                            <Block2 
                                // General
                                key={blockKey}
                                blockKey={blockKey}
                                scheduleType={scheduleType}
                                festival={festival}
                                isSetTime={true}
                                stageColor={stageColor}
                                selectedDayIndex={selectedDayIndex}
                                artist={artist}
                                timelineHeight={timelineHeight}
                                // User schedule
                                clickCount={clickCount}
                                onMyScheduleBlockClick={() => onMyScheduleBlockClick(artistDay, stageName, artistName, artistStart)}
                                isCreator={isCreator}
                                // Heatmap — Festival or Group
                                isHeatmap={scheduleType === "festivalHeatmap" || scheduleType === "group"}
                                heatmapType={heatmapType}
                                heatmapColor={heatmapColor}
                                heatmapData={blockHeatmapData}
                                // Group heatmap data
                                maxTotalVotes={groupData?.heatmap ? groupData.heatmap.maxTotalVotes : 1}
                                maxTotalMustSee={groupData?.heatmap ? groupData.heatmap.maxTotalMustSee : 1}
                                membersData={membersData}
                                handleGroupBlockClick={() => handleGroupBlockClick(artistDay, stageName, artistName, artistStart)}
                                groupSelected={groupSelections[encodeKey(blockKey)] === true}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
})

export default Stage
